import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { IntegrationConnectionsEnum } from "../models/enums/IntegrationConnectionsEnum";
import { ConnectionStatusEnum } from "../models/enums/connectionStatusEnum";
import { AnnatureConnection } from "../models/annatureConnection";
import { AnnatureRegisterAccount } from "../models/annatureRigisterAccount";
import { AnnatureAccountModel } from "../models/annatureAccountModel";
import { ConnectionResponse } from "../models/integrationConnectionResponse";

@Injectable({
    providedIn: 'root'
  })
  export class IntegrationsService {
    constructor(private api: HttpClient) { }

  public connect(connectionType: IntegrationConnectionsEnum): Observable<string>  {
    return this.api.get(`${environment.api_url}/integrations/${connectionType}/connect`, { responseType: 'text' });
  }

  public connectByKeys(connectionType: IntegrationConnectionsEnum, data: AnnatureConnection ): Observable<ConnectionStatusEnum>  {
    return this.api.post<ConnectionStatusEnum>(`${environment.api_url}/integrations/${connectionType}/connect`, data);
  }

  public registerAnnatureAccount(data: AnnatureRegisterAccount ): Observable<ConnectionResponse>  {
    return this.api.post<ConnectionResponse>(`${environment.api_url}/integrations/annature/register`, data);
  }
  public resendVerificationAnnature(): Observable<string>  {
    return this.api.post<string>(`${environment.api_url}/integrations/annature/account/resend-verification`, null);
  }

  public getAnnatureAccountList(): Observable<AnnatureAccountModel[]> {
    return this.api.get<AnnatureAccountModel[]>(`${environment.api_url}/integrations/annature/accounts`);
  }

  public setAnnatureAccount(model: AnnatureAccountModel): Observable<ConnectionStatusEnum> {
    return this.api.put<ConnectionStatusEnum>(`${environment.api_url}/integrations/annature/account`, model);
  }

  public disconnect(connectionType: IntegrationConnectionsEnum) {
    return this.api.post<void>(`${environment.api_url}/integrations/${connectionType}/disconnect`, null);
  }

  public checkConnection(connectionType: IntegrationConnectionsEnum): Observable<ConnectionResponse> {
    return this.api.get<ConnectionResponse>(`${environment.api_url}/integrations/${connectionType}/check`);
  }

  public processCallback(connectionType: IntegrationConnectionsEnum, state: string, code: string) {
    let params = new HttpParams();
    params = params.append('state', state);
    params = params.append('code', code);

    return this.api.get<boolean>(`${environment.api_url}/integrations/${connectionType}/callback`, { params: params });
  }

}