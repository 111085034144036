<div class="step-content">
  <section class="step-section" [formGroup]="form">
    <h1 class="h1-heading">{{ title }}</h1>

    @if (control) {
      <ul class="list-unstyled" formArrayName="corporateHoldersAuthorisedSignatories">
        @for (holder of corporateHolders; track holder; let index = $index) {
          @if (form.controls.corporateHoldersAuthorisedSignatories.controls[index]) {
            <li>
              <div style="margin-bottom: 4px">Authorised Signatories: {{ holder.name }}</div>
              <app-auth-signatory
                tip="Add Authorised Signatories’ names separated by semicolon"
                [authSignatoriesControl]="form.controls.corporateHoldersAuthorisedSignatories.controls[index]"
                [corporateHolders]="corporateHoldersAll"
                [relationshipId]="holder.relationshipId"
                [details]="holder.details"
                [holderName]="holder.name"
                [isPosted]="holder.isPosted"
                [customErrors]="authSignatoriesCustomErrors"
                [controlLike]="true"
                (onDataSaved)="updateValidation()"
              />
            </li>
          }
        }
      </ul>
    }
  </section>
</div>
