import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { IntegrationsService } from "../../../../services/integrations.service";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { catchError, firstValueFrom, from, map, Observable, of } from "rxjs";
import { IntegrationConnectionsEnum } from "../../../../models/enums/IntegrationConnectionsEnum";
import { ExternalSourceType } from "../../../../models/enums/externalEntityEnums";
import { ConnectionStatusEnum } from '../../../../models/enums/connectionStatusEnum';
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnnatureLoginModalComponent } from './modals/annature-login-modal/annature-login-modal.component';
import { AnnatureRegisterModalComponent } from './modals/annature-register-modal/annature-register-modal.component';
import { LoaderStandaloneComponent } from '../../../components/common/loader-standalone/loader-standalone.component';
import { AnnaturePickAccountModalComponent } from './modals/annature-pick-account-modal/annature-pick-account-modal.component';
import { ConfirmComponent } from '../../../modals/confirm/confirm.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConnectionResponse } from '../../../../models/integrationConnectionResponse';
import { EntityIdentityService } from '../../../../services/entity.identity.service';
import { HasRoleDirective } from '../../../../directives/has-role.directive';

@Component({
  selector: 'app-integrations-page',
  standalone: true,
  imports: [
    ButtonComponent,
    PageTitleComponent,
    LoaderStandaloneComponent,
    HasRoleDirective
  ],
  templateUrl: './integrations-page.component.html',
  styleUrls: ['integrations-page.component.scss', '../../settings-styles.scss']
})
export class IntegrationsPageComponent implements OnInit {

  connectionGroups = [
    {
      title: 'Practice Management',
      connections: [
        {
          title: 'Xero Practice Manager',
          description: 'Connect your Xero Practice Manager to your Bolt account for efficient client data management.',
          connectionStatus: ConnectionStatusEnum.NotConnected,
          connectionType: IntegrationConnectionsEnum.XpmConnection,
          warningMessage: '',
          image: 'assets/images/xero-practice-manager.svg',
          isloading: true,
          buttons: [
            {
              displayStatus: ConnectionStatusEnum.Connected,
              btnTitle: 'Sync',
              action: () => this.sync(ExternalSourceType.Xpm)
            }
          ]
        }
      ]
    },
    {
      title: 'eSigning',
      connections: [
        {
          title: 'Annature',
          description: 'Annature is Australia\'s favorite eSignature solution. Link your Annature account to your Bolt account to start seamlessly sending your documents for quick and secure online signatures.Connect your Annature account to use your esign account(test)',
          connectionStatus: ConnectionStatusEnum.NotConnected,
          connectionType: IntegrationConnectionsEnum.AnnatureConnection,
          warningMessage: 'some text dsfdsfds bls',
          image: 'assets/images/annature-logo.svg',
          isloading: true,
          buttons: [
            {
              displayStatus: ConnectionStatusEnum.NotConnected,
              btnTitle: 'Try Register',
              action: () => this.registerAnnatureAccount()
            },
            {
              displayStatus: ConnectionStatusEnum.WaitForVerification,
              btnTitle: 'Start eSigning',
              action: () => this.checkConnection(IntegrationConnectionsEnum.AnnatureConnection)
            },
            {
              displayStatus: ConnectionStatusEnum.WaitForVerification,
              btnTitle: 'Resend Email',
              action: () => this.annatureResendEmail()
            },
            {
              displayStatus: ConnectionStatusEnum.RequiredAction,
              btnTitle: 'Complete Login',
              action: () => this.completeAnnatureLogin()
            }
          ]
        },
        {
          title: 'DocuSign',
          description: 'Link your Docusign account with your Bolt account and enjoy the convenience of sending documents for online signing.',
          connectionStatus: ConnectionStatusEnum.NotConnected,
          connectionType: IntegrationConnectionsEnum.DocuSignConnection,
          warningMessage: '',
          image: 'assets/images/docusign-logo.svg',
          isloading: true
        }
      ]
    },
  ];

  private integrationsSerivice = inject(IntegrationsService);
  private entityIdentityService = inject(EntityIdentityService);
  private route = inject(ActivatedRoute);
  private toastr = inject(ToastrService);
  private modalService = inject(NgbModal);
  #destroyRef = inject(DestroyRef);


  ConnectionStatusEnum = ConnectionStatusEnum;
  IntegrationConnectionsEnum = IntegrationConnectionsEnum;
  connectionStatus = ConnectionStatusEnum.NotConnected;
  settingsLoading = false;
  loadingBtn = false;

  async ngOnInit() {
    this.settingsLoading = true;
    await this.processParams();
    this.checkConnections();
    this.settingsLoading = false;
  }

  async processParams() {
    const params = await firstValueFrom(this.route.queryParamMap.pipe(
      map((params: ParamMap) => {
        return {
          success: params.get('success'),
          error: params.get('error'),
          state: params.get('state'),
          code: params.get('code'),
          scope: params.get('scope')
        };
      }),
    ));

    if (params.success) {
      this.toastr.success("Connected", "Success");
    }

    if (params.error) {
      this.toastr.error("Failed to connect", "Error");
    }

    if (params.state && params.code && params.scope) {
      await this.processCallback(params.state, params.code);
    }

    window.history.replaceState({}, '', `/settings/integrations`);
  }

  async processCallback(state: string, code: string) {
    try {
      const callBackResult = await firstValueFrom(this.integrationsSerivice.processCallback(IntegrationConnectionsEnum.XpmConnection, state, code));
      if (callBackResult)
        this.toastr.success("Connected", "Success");
    } catch (e) {
      this.toastr.error("Failed to connect", "Error");
    }
  }

  async connect(type: IntegrationConnectionsEnum) {
    switch (type) {
      case IntegrationConnectionsEnum.XpmConnection:
        await this.connectToXPM();
        break;
      case IntegrationConnectionsEnum.AnnatureConnection:
        this.connectToAnnature();
        break;
    }
  }

  sync(type: ExternalSourceType) {
    this.toastr.info(`Syncing ${ExternalSourceType[type]} data...`, 'Syncing');
    this.entityIdentityService.sync(type)
    .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.toastr.info('Finish', 'Syncing');
        },
        error: () => {
          this.toastr.error(`Failed to sync ${ExternalSourceType[type]}`, 'Error');
        }
      });
  }

  checkConnections() {
    this.connectionGroups.forEach(group => {
      group.connections.forEach(connection => {
        this.integrationsSerivice.checkConnection(connection.connectionType)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe({
          next: (response) => {
            connection.connectionStatus = response.status;
            this.showResponseToaster(response);
            connection.isloading = false;
          },
          error: () => {
            connection.connectionStatus = ConnectionStatusEnum.NotConnected
            connection.isloading = false;
          }
        });
      });
    });
  }

  checkConnection(type: IntegrationConnectionsEnum) {
    const connection = this.connectionGroups
      .flatMap(group => group.connections)
      .find(conn => conn.connectionType === type);

    if (connection == undefined) {
      this.toastr.error("Connection not found", "Error");
      return;
    }

    this.integrationsSerivice.checkConnection(connection.connectionType)
    .pipe(takeUntilDestroyed(this.#destroyRef))
    .subscribe({
      next: (response) => {
        connection.connectionStatus = response.status;
        this.showResponseToaster(response);
        connection.isloading = false;
      },
      error: () => {
        connection.connectionStatus = ConnectionStatusEnum.NotConnected
        connection.isloading = false;
      }
    });
    connection.isloading = false;
  }

  annatureResendEmail() {
    this.integrationsSerivice.resendVerificationAnnature()
    .pipe(takeUntilDestroyed(this.#destroyRef))
    .subscribe({
      next: () => {
        this.toastr.success('Email was sent!', 'Success');
      },
      error: () => {
        this.toastr.error('Failed to send email!', 'Error');
      }
    });
  }
  
  async connectToXPM() {
    const redirectionUri = await firstValueFrom(this.integrationsSerivice.connect(IntegrationConnectionsEnum.XpmConnection));
    window.location.href = redirectionUri;
  }

  disconnect(type: IntegrationConnectionsEnum): void {
    this.loadingBtn = true;
    this.isDisconnectApproved(type).subscribe({
      next: (isApproved) => {
        if (!isApproved) {
          return;
        }
        this.integrationsSerivice.disconnect(type).subscribe({
          next: () => {
            this.setstatus(type, ConnectionStatusEnum.NotConnected);
            this.toastr.success('Disconnected', 'Success');
          },
          error: () => {
            this.toastr.error('Failed to disconnect', 'Error');
          }
        });
      },
      error: (err) => {
        console.error('Error while confirming disconnect:', err);
      },
      complete: () => {
        this.loadingBtn = false;
      }
    });
  }

  connectToAnnature() {
    const modalRef = this.modalService.open(AnnatureLoginModalComponent, { size: 'sm', centered: true});
    (modalRef.componentInstance as AnnatureLoginModalComponent).confirmEvent.subscribe((status: ConnectionStatusEnum) => {
      if (status === ConnectionStatusEnum.RequiredAction) {
        this.completeAnnatureLogin();
        return;
      }
      this.setstatus(IntegrationConnectionsEnum.AnnatureConnection, status);
    });
  }

  setstatus(connectionType: IntegrationConnectionsEnum, status: ConnectionStatusEnum) {
    this.connectionGroups.forEach(group => {
      const connection = group.connections.find(conn =>
        conn.connectionType !== undefined &&
        conn.connectionType === connectionType
      );

      if (connection)
        connection.connectionStatus = status;
    });
  }

  registerAnnatureAccount() {
    const modalRef = this.modalService.open(AnnatureRegisterModalComponent, { size: 'sm', centered: true });
    (modalRef.componentInstance as AnnatureLoginModalComponent).confirmEvent.subscribe((status: ConnectionStatusEnum) => {
      this.setstatus(IntegrationConnectionsEnum.AnnatureConnection, status);
    });
  }

  completeAnnatureLogin() {
    const modalPickAccountRef = this.modalService.open(AnnaturePickAccountModalComponent, { size: 'sm', centered: true, backdrop: 'static' });
    (modalPickAccountRef.componentInstance as AnnaturePickAccountModalComponent).confirmEvent.subscribe((status: ConnectionStatusEnum) => {
      if (status === ConnectionStatusEnum.NotConnected) {
        this.disconnect(IntegrationConnectionsEnum.AnnatureConnection);
      }
      this.setstatus(IntegrationConnectionsEnum.AnnatureConnection, status);
    });
  }

  isDisconnectApproved(type: IntegrationConnectionsEnum): Observable<boolean> {
    switch (type) {
      case IntegrationConnectionsEnum.AnnatureConnection: {
        return this.annatureDisconnectMessage();
      }
    }
    return of(true);
  }

  annatureDisconnectMessage(): Observable<boolean> {
    const modalRef = this.modalService.open(ConfirmComponent);
    const componentInstance = modalRef.componentInstance as ConfirmComponent;
  
    componentInstance.title = 'Annature Disconnect';
    componentInstance.message = 'All your envelopes will no longer be active. NEED SOME TEXT HERE...';
    componentInstance.confirmText = 'Disconnect';
    
    componentInstance.confirm = () => of(true);

    return from(modalRef.result).pipe(
      map(result => {
        return result === true;
      }),
      catchError(() => of(false))
    );
  }

  showResponseToaster(response: ConnectionResponse): void {
    switch (response.status) {
      case ConnectionStatusEnum.RequiredAction:
      case ConnectionStatusEnum.WaitForVerification:
        this.toastr.warning(response.message, 'Required Action');
        break;
      case ConnectionStatusEnum.ConnectionFailed:
      case ConnectionStatusEnum.NotConnected:
        if (response.message)
          this.toastr.error(response.message, 'Error');
        break;
    }
  }

  getStatusText(status: ConnectionStatusEnum): string {
    switch (status) {
      case ConnectionStatusEnum.Connected: return "Connected";
      case ConnectionStatusEnum.NotConnected: return "Disconnected";
      case ConnectionStatusEnum.RequiredAction: return "Action Required";
      case ConnectionStatusEnum.ConnectionFailed: return "Connection Failed";
      case ConnectionStatusEnum.WaitForVerification: return "Wait For Verification";
    }
  }

  getStatusClass(status: ConnectionStatusEnum): string {
    switch (status) {
      case ConnectionStatusEnum.Connected: return "connected";
      case ConnectionStatusEnum.NotConnected: return "disconnected";
      case ConnectionStatusEnum.RequiredAction: return "required-action";
      case ConnectionStatusEnum.ConnectionFailed: return "connection-failed";
      case ConnectionStatusEnum.WaitForVerification: return "wait-for-verification";
    }
  }
}
