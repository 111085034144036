import { Guid } from "../../app/helpers/guid.helper";
import { ChangeAuthorisation } from "../changeAuthorisation";
import { Company } from "../company";
import { EntityChangeData } from "../entityChangeData";
import { ReminderRecord } from "../reminderRecord";
import { DocumentSigning } from "./document-signing";

export class DocumentBase {
    documentId: string;
    organisationId?: string;
    company: Company;
    entityId: string | null;
    changes: EntityChangeData[];
    changeAuthorisation?: ChangeAuthorisation
    type: string;
    description?: string;
    name: string;
    documentSigning?: DocumentSigning | null;
    createdOn: Date | undefined;
    modifiedOn: Date | undefined;
    reminders: ReminderRecord[];

    constructor(data: Partial<DocumentBase> = {}) {
        this.documentId = data?.documentId ?? Guid.EmptyGuid;
        this.organisationId = data?.organisationId;
        this.entityId = data.entityId ?? null;
        this.company = data.company ?? {} as Company;
        this.changes = data?.changes ?? [];
        this.changeAuthorisation = data?.changeAuthorisation;
        this.type = data.type ?? '';
        this.description = data?.description;
        this.name = data.name ?? '';
        this.documentSigning = data?.documentSigning ?? null;
        this.createdOn = data.createdOn ? new Date(data.createdOn) : undefined;
        this.modifiedOn = data.modifiedOn ? new Date(data.modifiedOn) : undefined;
        this.reminders = data.reminders ?? [];
    }
}
