@if(disabled || getMenuActions().length != 0) {
<div class="action-container">
   
      <div
        ngbDropdown
        [placement]="['bottom-end', 'bottom-start', 'top-start', 'top-end']"
        class="dropdown d-inline-flex"
        container="body"
      >
        <i class="icon ic-sm ic-three-dots" ngbDropdownToggle></i>

        <div ngbDropdownMenu class="annual-menu dropdown-menu dropdown-menu-right">
          @for (item of getMenuActions(); track $index) {
            <div ngbDropdownItem class="dropdown-item" (click)="item.action()" >
              <a class="menu-action-button">
                <i class="{{item.iconClass}}"></i>
                <span>{{item.name}}</span>
              </a>
            </div>
          }
        </div>
      </div>
</div>
}
