import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap, catchError, EMPTY } from 'rxjs';
import { SigningStatus } from '../../../models/enums/annualStatementEnums';
import { ApproverStatus } from '../../../models/enums/approverStatus';
import { ReminderType, ReminderStatus } from '../../../models/enums/reminderEnums';
import { ReminderRecord } from '../../../models/reminderRecord';
import { ConfirmComponent } from '../../modals/confirm/confirm.component';
import { AdHocSigningReminderComponent } from '../../modals/reminders/ad-hoc-signing-reminder/ad-hoc-signing-reminder.component';
import { AdHocPaymentRemiderComponent } from '../../modals/reminders/ad-hoc-payment-reminder/ad-hoc-payment-reminder.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalFormsService } from '../../../services/modal-forms.service';
import { RemindersService } from '../../../services/reminders.service';
import { ToastrService } from 'ngx-toastr';
import { Company } from '../../../models/company';
import { DocumentSigning } from '../../../models/documentEnteties/document-signing';
import { ChangeAuthorisation } from '../../../models/changeAuthorisation';
import { DividerComponent } from "../common/divider/divider.component";
import { ReminderComponent } from "../common/reminder/reminder.component";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DocumentBase } from '../../../models/documentEnteties/document-base';

@Component({
  selector: 'app-reminders-sidebar',
  standalone: true,
  imports: [DividerComponent, ReminderComponent, NgxSkeletonLoaderModule],
  templateUrl: './reminders-sidebar.component.html',
  styleUrl: './reminders-sidebar.component.scss'
})
export class RemindersSidebarComponent implements OnInit {


  private modalFormsService = inject(ModalFormsService);
  private modalService = inject(NgbModal);
  private remindersService = inject(RemindersService);
  #toastr = inject(ToastrService);
  #destroyRef = inject(DestroyRef);

  @Input() reminders: ReminderRecord[] | undefined;
  @Input() documentBase!: DocumentBase | null;
  @Input() paymentDeadline: string | null | undefined;
  @Input() showPaymentReminder = true;

  signingReminderList: ReminderRecord[] = [];
  paymentReminderList: ReminderRecord[] = [];
  company: Company | undefined;
  changeAuthorisation: ChangeAuthorisation | undefined;
  ReminderType = ReminderType;
  documentSigning: DocumentSigning | undefined | null;
  documentId!: string | undefined;

  ngOnInit(): void {
    this.documentId = this.documentBase?.documentId;
    this.company = this.documentBase?.company;
    this.documentSigning = this.documentBase?.documentSigning;
    this.changeAuthorisation = this.documentBase?.changeAuthorisation;
    this.splitReminders();
  }

  splitReminders(): void {
    this.signingReminderList = this.getReminderByType(ReminderType.Signing);
    this.paymentReminderList = this.getReminderByType(ReminderType.Payment);
  }

  protected showSigningReminderPopup(): void {
    const componentInstance = this.modalFormsService
      .openModal(AdHocSigningReminderComponent).componentInstance as AdHocSigningReminderComponent;
    componentInstance.companyProfile = this.company!;
    componentInstance.lastSendReminderDate = this.getLastSendReminderDate(ReminderType.Signing);
    componentInstance.documentSigning = this.documentSigning;
    componentInstance.annualPaymentDeadline = this.paymentDeadline;
    componentInstance.changeAuthorisation = this.changeAuthorisation;

    componentInstance.sendSigningAdHocReminder.pipe(
      tap((reminder) => {
        this.reminders?.push(reminder);
        this.splitReminders();
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }

  protected showPaymentReminderPopup(): void {
    const componentInstance = this.modalFormsService
      .openModal(AdHocPaymentRemiderComponent).componentInstance as AdHocPaymentRemiderComponent;
    componentInstance.companyProfile = this.company!;
    componentInstance.documentSigning = this.documentSigning;
    componentInstance.annualPaymentDeadline = this.paymentDeadline;
    componentInstance.changeAuthorisation = this.changeAuthorisation;

    componentInstance.sendPaymentAdHocReminder.pipe(
      tap((reminder) => {
        this.reminders?.push(reminder);
        this.splitReminders();
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }

  getLastSendReminderDate(reminderType: ReminderType): Date | null {
    const reminderList = reminderType === ReminderType.Payment ? this.paymentReminderList : this.signingReminderList;
    const list = reminderList.filter((reminder) => reminder.status === ReminderStatus.Sent).sort((a, b) => {
      return new Date(b.scheduledOn).getTime() - new Date(a.scheduledOn).getTime();
    });

    if (list.length > 0) {
      return new Date(list[0].scheduledOn);
    }
    return null;
  }

  protected addReminder(reminderDate: Date, reminderType: ReminderType): void {
    const list = reminderType === ReminderType.Payment ? this.paymentReminderList : this.signingReminderList;
    if (list.find((reminder) => {
      const existDate = new Date(reminder.scheduledOn);
      return existDate.getUTCDate() === reminderDate.getUTCDate()
        && existDate.getUTCMonth() === reminderDate.getUTCMonth()
        && existDate.getUTCFullYear() === reminderDate.getUTCFullYear();
    })) {
      this.#toastr.warning("Reminder already exists", "Error");
      return;
    }

    const body = {
      documentId: this.documentId ?? '',
      reminderType,
      scheduledOn: reminderDate.toISOString(),
    }

    this.remindersService.addReminder(body).pipe(
      tap((reminder) => {
        this.#toastr.success("Reminder has been successfully added", "Success");
        this.reminders?.push(reminder);
        this.splitReminders();
      }),
      catchError((err) => {
        this.#toastr.error("Reminder create error", "Error");
        console.error(err);
        return EMPTY;
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }

  protected removeReminder(reminderId: string): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete reminder';
    (modalRef.componentInstance as ConfirmComponent).message = 'Do you want to delete the reminder?' +
      ' If you proceed, it will not be sent on the scheduled date.';
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => this.remindersService.deleteReminder(reminderId).pipe(
      tap( () => {
        this.#toastr.success("Reminder has been successfully deleted", "Success");
        this.reminders = this.reminders?.filter((reminder) => reminder.reminderId !== reminderId);
        this.splitReminders();
      }),
      catchError((err) => {
        this.#toastr.error("Reminder delete error", "Error");
        console.error(err);
        return EMPTY;
      }),
    );

    modalRef.closed.pipe(
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }

  protected getReminderByType(reminderType: ReminderType): ReminderRecord[] {
    if (!this.reminders?.length)
      return [];

    return this.reminders
      .filter((reminder) => reminder.type === reminderType)
      .sort((a, b) => {
        if (a.status !== b.status) {
          return a.status === ReminderStatus.Sent ? -1 : 1;
        }

        return new Date(a.scheduledOn).getTime() - new Date(b.scheduledOn).getTime();
      });
  }

  get isShowSigningReminder(): boolean {
    return (
      (!this.documentSigning?.approver || this.documentSigning?.approver?.approverStatus == ApproverStatus.Approved) 
      && this.documentSigning?.signingStatus === SigningStatus.Sent
    );
  }

  get isShowPaymentReminder(): boolean {
    return (
      this.documentSigning?.signingStatus === SigningStatus.Sent &&
      (this.documentSigning?.approver?.approverStatus === ApproverStatus.Approved ||
        !this.documentSigning?.approver)
    );
  }
}
