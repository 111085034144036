import { Component, DestroyRef, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { CommonModalFormComponent } from "../common-modal-form/common-modal-form.component";
import { InputComponent } from "../../components/common/input/input.component";
import { InputPhoneNumberComponent } from "../../components/common/input-phone-number/input-phone-number.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { SelectComponent } from "../../components/common/select/select.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectOption } from "../../../models/selectOptions";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DollarDirective } from "../../../directives/dollar.directive";
import { formatDate } from "@angular/common";
import { Company } from "../../../models/company";
import { AutocompleteComponent } from "../../components/common/autocomplete/autocomplete.component";
import { CompanyProfileService } from '../../company-profile/company-profile.service';
import { downloadBase64File } from '../../../functions/download-base64-file';
import { tap } from 'rxjs';

@Component({
  selector: 'app-generate-payslip',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    InputComponent,
    InputPhoneNumberComponent,
    ReactiveFormsModule,
    SelectComponent,
    FormsModule,
    DollarDirective,
    AutocompleteComponent
  ],
  templateUrl: './generate-payslip.component.html',
  styleUrl: './generate-payslip.component.scss'
})
export class GeneratePayslipComponent {
  @Input() companyProfile: Company | undefined;

  @ViewChild('modalElement', { static: true }) modalElement: ElementRef | undefined;

  private fb: FormBuilder = inject(FormBuilder);
  private activeModal = inject(NgbActiveModal);
  private destroyRef = inject(DestroyRef);
  private companyService = inject(CompanyProfileService);

  form!: FormGroup

  public reasonOptions: SelectOption[] = [
    { label: 'Change of Name confirmed', value: '491' },
    { label: 'Name Reservation confirmed', value: '61' },
    { label: 'Late fee (under 28 days)', value: '96' },
    { label: 'Late fee (over 28 days)', value: '401' },
    { label: 'Company Deregistration', value: '49' },
    { label: 'Annual Fee for Special Purpose Company', value: '65' },
    { label: 'Annual Fee for Standard Company', value: '321' },
    { label: 'Annual Fee for Proprietary Non-Profit Company', value: '61' },
    { label: 'Annual Fee for Listed Public Company', value: '1492' },
    { label: 'Annual Fee for Unlisted Public Company', value: '61' },
    { label: 'Annual Fee for Unlisted Public Non-Profit Company', value: '1492' },
    { label: 'Other', value: '' },
  ];

  ngOnInit(): void {
    this.initForm();
    this.listenReason();
    this.listenPaidAmount();
  }

  private initForm(): void {
    this.form = this.fb.group({
      reason: ['', Validators.required],
      paidAmount: ['', Validators.required],
    });
  }

  private listenReason(): void {
    this.form.get('reason')?.valueChanges.pipe(
      tap((reason: SelectOption) => {
        if(!isNaN(parseFloat(reason.value as string))) {
          this.form.get('paidAmount')?.patchValue(reason.value);
        } else {
          this.form.get('paidAmount')?.patchValue('');
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private listenPaidAmount(): void {
    this.form.get('paidAmount')?.valueChanges.pipe(
      tap((paidAmount: string) => {
        if(!paidAmount.includes('$')) {
          const changedPaidAmount = '$' + paidAmount;
          this.form.get('paidAmount')?.patchValue(changedPaidAmount,{ emitEvent: false });
        }

        const invalidPattern = /^\$\D.*$/;
        if (invalidPattern.test(paidAmount)) {
          this.form.get('paidAmount')?.patchValue('$',{ emitEvent: false });
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  public downloadPayslip(): void {
    const reason = (this.form.get('reason')?.value as SelectOption)?.label;
    const paidAmount = ((this.form.get('paidAmount')?.value) as string)?.replace('$', '');

    this.companyService.generatePayslip(this.companyProfile!.entityId, reason, paidAmount)
      .subscribe({
        next: (file) => downloadBase64File(file, ('Payslip ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US') + '.pdf'))
      });
    this.activeModal.close();
  }

  public close(): void {
    this.activeModal.close();
  }
}
