import {
  Component, computed,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { CompanyAppointChange, Form362Type } from '../../../../models/companyAppointChange';
import { Document } from '../../../../models/document';
import { ChangeDictionaryHelper, IDictionaryItem } from '../../../../models/shared/change-dictionary-helper.model';
import { IPDFSection } from '../../../../models/shared/pdf-section.interface';
import { ACNPipe } from "../../../../pipes/acnPipe";
import { formatDate, NgClass, UpperCasePipe } from "@angular/common";
import { DocumentTypePipe } from '../../../../pipes/document-type.pipe';
import { DocumentStatusPipe } from "../../../../pipes/enumsPipes/documentStatusPipe";
import { filter, firstValueFrom, Subject } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { DateFormatPipe } from "../../../../pipes/dateFormatPipe";
import { DocumentsService } from "../../../../services/documents.service";
import { ToastrService } from "ngx-toastr";
import { observe, Observer } from "fast-json-patch";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { AuthService } from "../../../../services/auth.service";
import { DividerComponent } from '../../../components/common/divider/divider.component';
import {
  DocumentChangesPreviewDetailsComponent,
  IDocumentChangePreview
} from './document-changes-preview-details/document-changes-preview-details.component';
import { EntityChangeData } from "../../../../models/entityChangeData";
import {
  CompanyChangeAddress
} from "../../../modals/documents/asic-forms/484-forms/a1-company-address-change/CompanyChangeAddress.model";
import { CountryPipe } from "../../../../pipes/countryPipe";
import { Company } from "../../../../models/company";
import { DocumentStatusEnum } from "../../../../models/enums/documentStatusEnum";
import { RelationshipTypeLabels } from '../../../../models/enums/relationshipTypeEnum';
import { Relationship } from '../../../../models/relationship';

@Component({
  selector: 'app-documents-changes-preview',
  standalone: true,
  imports: [
    ACNPipe,
    DocumentStatusPipe,
    NgClass,
    DateFormatPipe,
    NgxSkeletonLoaderModule,
    DocumentTypePipe,
    UpperCasePipe,
    DividerComponent,
    DocumentChangesPreviewDetailsComponent,
  ],
  providers: [DocumentTypePipe, ACNPipe, CountryPipe],
  templateUrl: './documents-changes-preview.component.html',
  styleUrl: './documents-changes-preview.component.scss'
})
export class DocumentsChangesPreviewComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() document!: Document;
  @Input() entity!: Company;
  @Input() relationshipHashset: Map<string, string> = new Map<string, string>();
  @Input() confirmAction$: Subject<boolean> | undefined;
  @Input() set updatePreview(value: boolean) {
    this.fillDocumentChangesPreviews();
  }
  @Output() editDocumentChange = new EventEmitter<number>();
  @Output() nextStep = new EventEmitter<boolean>();

  private documentsService = inject(DocumentsService);
  private authService = inject(AuthService);
  private toastr = inject(ToastrService);
  private documentTypePipe = inject(DocumentTypePipe);
  private acnPipe = inject(ACNPipe);
  private countryPipe = inject(CountryPipe);
  #destroyRef = inject(DestroyRef);

  profileName = computed(() => {
    const profile = this.authService.currentUserProfile();
    if (profile) {
      const profileNames = [profile.firstName, profile.middleName, profile.lastName];
      return profileNames.join(' ');
    }

    return '';
  });

  observer!: Observer<Document>;
  dictionaryChanges: { key: string, value: string }[] = [];
  documentChangesPreviews: IDocumentChangePreview[] = [];
  changeTypeMap = new Map<string, string>([
    ['205a', 'New Company Name'],
    ['c:205a', 'New Company Name'],
    ['484:a1', 'New Address'],
    ['c:484:a1', 'New Address'],
    ['484:a2', 'Officeholder and/or Member Details'],
    ['c:484:a2', 'Officeholder and/or Member Details'],
    ['484:a3', 'Change of Ultimate Holding Company'],
    ['c:484:a3', 'Change of Ultimate Holding Company'],
    ['484:b1', 'Cessation Details'],
    ['c:484:b1', 'Cessation Details'],
    ['484:b2', 'Appointment Details'],
    ['c:484:b2', 'Appointment Details'],
    ['410b', 'Company New Name'],
    ['c:410b', 'Company New Name'],
    ['410f', 'Company New Name'],
    ['c:410f', 'Company New Name'],
    ['106', 'Document Details'],
    ['c:106', 'Document Details'],
    ['370', 'Resignation Details'],
    ['c:370', 'Resignation Details'],
    ['361', 'Details of Change'],
    ['c:361', 'Details of Change']
  ]);

  readonly titleDelimiter = '=======================================================================';

  constructor() {
    this.toastr.toastrConfig.positionClass = 'toast-top-right';
  }

  ngOnInit(): void {
    this.listenHeaderButton();
    this.documentsService.disabledHeaderBtn.set(!this.document?.type);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['document']?.currentValue) {
      this.observer = observe(this.document);
      this.fillDocumentChangesPreviews();
    }
  }

  listenHeaderButton(): void {
    this.confirmAction$?.pipe(
      filter(confirm => confirm),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(() => {
      void this.confirm();
    });
  }

  async confirm(): Promise<void> {
    this.document.documentStatus = DocumentStatusEnum.AuthorisationPending;

    try {
      this.documentsService.patchLoading.set(true);
      await firstValueFrom(this.documentsService.patchDocument(this.document.documentId, this.observer));
      this.toastr.success("Document confirmed", "Success");
      this.nextStep.emit(true);
      this.confirmAction$?.next(false);
      this.documentsService.patchLoading.set(false);
    } catch (e) {
      this.documentsService.patchLoading.set(false);
      this.toastr.error("Error happened", "Failed");
      console.log(e);
    }
  }

  toggleDocumentChangeState(index: number): void {
    this.documentChangesPreviews[index].expanded = !this.documentChangesPreviews[index].expanded;
  }

  onEditDocumentChange(index: number): void {
    this.editDocumentChange.emit(index);
  }

  getHeaderData(index: number): RowInput[] {
    const changeDate = new Date(this.document.changes[index].changeDate);
    const type = this.documentTypePipe.transform(this.document.changes[index].changeType);
    const companyName = (this.document.changes as (EntityChangeData & { companyName?: string })[])
      .find(change => !!change?.companyName)?.companyName ?? '';

    const formNameArr = type.split(':');
    if (formNameArr.length === 1) {
      const lastCharIndex = formNameArr[0].length - 1;
      formNameArr[0] = formNameArr[0].slice(0, lastCharIndex) + formNameArr[0][lastCharIndex].toUpperCase();
    } else if (formNameArr.length === 2) {
      formNameArr[1] = formNameArr[1].toUpperCase();
    }
    const formName = formNameArr.join('');

    return [
      { key: 'Company Name:', value: this.document.company.name || companyName },
      { key: 'Type of ASIC Form:', value: 'ASIC ' + formName[0].toUpperCase() + formName.slice(1, formName.length) },
      { key: 'Date and Time:', value: formatDate(changeDate, 'dd/MM/yyyy hh:mm a', 'en-US').toLowerCase() },
      { key: 'Generated By:', value: this.profileName() },
    ];
  }

  getCompanyInfo(): RowInput[] {
    return [
      { key: 'ACN:', value: this.acnPipe.transform(this.document.company.acn) },
      { key: 'Company Name:', value: this.document.company.name },
    ];
  }

  getCompanyChangeInfo(index: number): RowInput[] {
    const companyInfo = [
      { key: 'ACN:', value: this.acnPipe.transform((this.document.changes[index] as unknown as { companyACN: string }).companyACN) },
      { key: 'Company Name:', value: (this.document.changes[index] as unknown as { companyName: string }).companyName },
      { key: 'Type of Change:', value: 'Appoint as a registered agent' },
    ];

    return companyInfo;
  }

  downloadPreview(index: number): void {
    this.isDictionaryExist(index)
    this.createPDF(index);
  }

  private createPDF(index: number): void {
    const pdf = new jsPDF();

    autoTable(pdf, {
      columnStyles: {
        key: {
          fontStyle: 'bold',
          cellWidth: 90,
          fillColor: 'white'
        },
        value: {
          cellWidth: 90,
          fillColor: 'white'
        }
      },
      body: this.getHeaderData(index),
    });

    if (this.document.company.acn) {
      const companyInformationHeader = [
        { key: 'Company Information' },
        { key: this.titleDelimiter },
      ];
      autoTable(pdf, {
        columnStyles: {
          key: {
            fontSize: 12,
            fontStyle: 'bold',
            fillColor: 'white'
          }
        },
        body: companyInformationHeader,
      });

      autoTable(pdf, {
        startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY + 1,
        columnStyles: {
          key: {
            cellWidth: 90,
            fillColor: 'white'
          },
          value: {
            cellWidth: 90,
            fillColor: 'white'
          }
        },
        body: this.getCompanyInfo(),
      });
    }

    this.getChangePdf(index).forEach((section) => {
      autoTable(pdf, {
        columnStyles: {
          key: {
            fontSize: 12,
            fontStyle: 'bold',
            fillColor: 'white'
          }
        },
        body: [{ key: section.header }, { key: this.titleDelimiter }],
      });

      autoTable(pdf, {
        startY: (pdf as unknown as { lastAutoTable: { finalY: number } }).lastAutoTable.finalY + 1,
        columnStyles: {
          key: {
            cellWidth: 90,
            fillColor: 'white'
          },
          value: {
            cellWidth: 90,
            fillColor: 'white'
          }
        },
        body: section.rows,
      });
    });

    pdf.save('Changes Preview ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US') + '.pdf');
  }

  isDictionaryExist(index: number): boolean {
    const change = this.document.changes[index];

    try {
      change.toDictionary(this.entity);
    } catch (error) {
      console.warn('isDictionaryExist: ', change?.$type, error);
      return false;
    }

    this.dictionaryChanges = this.documentChangesPreviews[index].preview
      .filter(c => !ChangeDictionaryHelper.SPECIAL_KEYS.includes(c.key))
      .map(c => ({
        ...c, value: ChangeDictionaryHelper.replaceNewLineWithComma(c.value)
      }));

    return true;
  }

  getRelationshipHashValueById(value: string): string {
    const idArray = value?.split('<br/>');
    if (!value || idArray?.length == 0 || this.relationshipHashset.size === 0) {
      return value;
    }

    let result = '';
    idArray?.forEach((id, index) => {
      const name = this.relationshipHashset.get(id);
      if (name) {
        if (index !== idArray.length - 1) {
          result = result + name + '<br/>';
        } else {
          result = result + name;
        }
      }
    });
    if (result != '') {
      return result;
    }

    return value;
  }

  private fillDocumentChangesPreviews(relationshipHashset = this.relationshipHashset) {
    this.documentChangesPreviews = this.document?.changes.map(change => {
      const dictionary = change.toDictionary(this.entity);
      const preview = dictionary
        .reduce((collection, item) => {
          const prefix = ChangeDictionaryHelper.PREFIXES.find(prefix => item.value.startsWith(prefix));

          if (prefix) {
            const handlePrefixResult = ChangeDictionaryHelper.handlePrefix(prefix, item, relationshipHashset.has(item.value))
              .map(i => ({ key: i.key, value: this.getRelationshipHashValueById(i.value) }));

            collection.push(...handlePrefixResult);
          } else {
            collection.push({ key: this.relationshipHashset.has(item.key) ? relationshipHashset.get(item.key)! : item.key, value: this.getRelationshipHashValueById(item.value) });
          }

          return collection;
        }, [] as IDictionaryItem[]) ?? [];

      return {
        expanded: true,
        type: change.buildPreviewType(),
        description: change.description,
        dictionary,
        preview,
      };
    }) ?? [];

    return this.documentChangesPreviews;
  }

  private isAppointmentOfRegisteredAgent362(index: number, changeDescription: string): boolean {
    return this.document.changes[index].changeType === 'c:362:a'
      && changeDescription.toLowerCase() === 'Appointment of registered agent'.toLowerCase();
  }

  private getChangePdf(index: number): IPDFSection[] {
    const change = this.document.changes[index];

    switch (change.$type) {
      case CompanyAppointChange.$type: {
        if ((change as CompanyAppointChange)?.actionType !== Form362Type.Appointment) {
          return [{ header: 'Cessation of registered agent details', rows: this.dictionaryChanges }];
        }

        return [{ header: 'Company Information', rows: this.getCompanyChangeInfo(index) }];
      }
      case CompanyChangeAddress.$type: {
        const filteredRelationshipPdf: IPDFSection[] = this.getChangeAddressRelationshipPdf(change as CompanyChangeAddress);

        const prepareToPdf: IPDFSection[] = change.prepareToPdf(this.entity);
        prepareToPdf.forEach(data => {
          if (data.header.toLowerCase() === 'new address') {
            (data.rows as { key: string; value: string }[]).forEach(row => {
              if (row.key === 'Country') {
                row.value = this.countryPipe.transform(row.value);
              }
            });
          }
        });

        return [...prepareToPdf, ...filteredRelationshipPdf];
      }
      default:
        return [{
          header: this.changeTypeMap.get(change.buildPreviewType()) ?? change.description,
          rows: this.dictionaryChanges
        }];
    }
  }

  private getChangeAddressRelationshipPdf(change: CompanyChangeAddress): IPDFSection[] {
    const relGroup = this.entity.officers.concat(this.entity.securityholders)
      .filter((relationship) => change.relationshipIds.includes(relationship.relationshipId))
      .reduce((relGroup, relationship) => {
        const key = relationship.individualId!;
        if (!relGroup[key]) {
          relGroup[key] = [relationship];
        }
        relGroup[key].push(relationship);
        return relGroup;
      }, {} as Record<string, Relationship[]>);

    const section = {
      header: 'Apply to',
      rows: Object.entries(relGroup).map(([, value]) => ({ key: value[0].individualDataOverride!.fullName, value: [...new Set(value.map(r => RelationshipTypeLabels[r.type]))].join(', ') }))
    }
        
    return [section];
  }
}
