import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Company } from '../../../../models/company';
import { Document } from '../../../../models/document';
import { EntitySecurityTransfer } from '../../../../models/EntitySecurityTransfer';
import { CompanyChangeData } from '../../../../models/enums/companyChangeData';
import { SecurityType } from '../../../../models/securityType';
import { ChangeDictionaryHelper } from '../../../../models/shared/change-dictionary-helper.model';
import { ShareIssueComponent } from '../share-issue/share-issue.component';
import { CertificateConsolidationOptions } from '../share-issue/share-issue.model';
import { ShareTransferComponent } from './share-transfer.component';
import { IStep } from '../../../../models/step';

export enum CompanySecurityTransferStepsEnum {
  FormDescription = 0,
  TransferFrom = 1,
  TransferTo = 2,
  AuthorisedSignatories = 3,
  Estimate = 4,
}

export const ShareTransferSteps: IStep<CompanySecurityTransferStepsEnum>[] = [
  { step: CompanySecurityTransferStepsEnum.FormDescription, label: 'Form Description' },
  { step: CompanySecurityTransferStepsEnum.TransferFrom, label: 'Transfer From' },
  { step: CompanySecurityTransferStepsEnum.TransferTo, label: 'Transfer To' },
  { step: CompanySecurityTransferStepsEnum.AuthorisedSignatories, label: 'Authorised Signatories' },
  { step: CompanySecurityTransferStepsEnum.Estimate, label: 'Estimate' }
];

export class CompanySecurityTransfer extends EntitySecurityTransfer<Company> {
  static override readonly $type = 'CompanySecurityTransfer';

  consolidateCertificate: boolean;

  constructor(data: Partial<CompanySecurityTransfer> = {}) {
    super(data);

    this.consolidateCertificate = data.consolidateCertificate ?? false;
  }

  override toDictionary(): { key: string; value: string }[] {
    const dict = new ChangeDictionaryHelper();
    const sellers = this.transferFrom.relationshipIds;
    const buyers = this.securityHoldersTo.map(holder => holder.relationshipId);

    dict
      .addIds('Transferor (Seller)', sellers)
      .addWithBeneficialOwnerPrefix('Shares are owned on behalf of', sellers[0])
      .addDivider()
      .addIds('Transferee (Buyer)', buyers)
      .addWithBeneficialOwnerPrefix('Shares are owned on behalf of', this.securityHoldersTo[0].relationshipId)
      .addEmptyLine()
      .add('Share Type', SecurityType.buildIdentifier(this.transferFrom.securityType))
      .add('Number of shares to be transferred', this.transferTo.numberIncrease.toString())
      .addMoney('Consideration paid', this.transferTo.considerationPaid)
      .addMoney('Total Amount Paid', this.transferTo.paidIncrease)
      .addMoney('Total Amount Unpaid', this.transferTo.unpaidIncrease)
      .addIfValueIsNotEmpty('Certificate Number', this.transferTo.shareCertificateNumber)
      .add('Certificate Consolidation', CertificateConsolidationOptions.find(option => option.value === this.consolidateCertificate)?.label)
      .addDate('Date of Transfer', this.transferFrom.transactionDate);

    return dict.dictionary;
  }

  override fillModal(modalRef: NgbModalRef, document: Document, actualCompany: Company): NgbModalRef {
    modalRef = this.fillModalWithCompany(modalRef, actualCompany, true);
    (modalRef.componentInstance as ShareIssueComponent).companyChangeData.documentId = document.documentId;
    return modalRef;
  }

  override fillModalWithCompany(modalRef: NgbModalRef, company: Company, isEdit: boolean): NgbModalRef {
    const instance = modalRef.componentInstance as ShareTransferComponent;
    instance.isEdit = isEdit;
    instance.companyChangeData = new CompanyChangeData(company);
    instance.formModel = this;

    return modalRef;
  }

  override prepareToRequest(): CompanySecurityTransfer {
    return {
      ...this,
      ...super.prepareToRequest(),
      transferFrom: this.transferFrom.prepareToRequest(),
      transferTo: this.transferTo.prepareToRequest(),
    };
  }
}
