<div class="wrapper">
  @if (autoSendingEnable) {
    <div class="sending-method-label auto-sending">Auto Sending</div>
  }

  @if (sendingType !== null) {
    <div class="sending-method-label"
         [class.sms]="sendingType === ReminderSendingType.SMS"
         [class.sms-and-email]="sendingType === ReminderSendingType.EmailAndSMS"
         [class.email]="sendingType === ReminderSendingType.Email"
    >
      {{ ReminderSendingTypeLabelsRecord[sendingType] }}
    </div>
  }
</div>
