import { Guid } from "../../app/helpers/guid.helper";
import { ActionType } from "../enums/alertEnums";

export abstract class AlertDetailBase {
    $type: string;
    detailModelId: string;
    changeDate: Date;
    title: string;
    message: string;
    actions: ActionModel[];

    static readonly $type: string;

    constructor(data?: Partial<AlertDetailBase>) {
        this.$type = (this.constructor as typeof AlertDetailBase).$type;
        this.detailModelId = data?.detailModelId ?? Guid.EmptyGuid;
        this.changeDate = data?.changeDate ?? new Date();
        this.title = data?.title ?? "";
        this.message = data?.message ?? "";
        this.actions = data?.actions ?? [];
    }
}

export class ActionModel {
    id: string;
    title: string;
    actionType: ActionType;
    actionData: string;

    constructor(data?: Partial<ActionModel>) {
        this.id = data?.id ?? Guid.EmptyGuid;
        this.title = data?.title ?? "";
        this.actionType = data?.actionType ?? ActionType.Manual;
        this.actionData = data?.actionData ?? "";
    }
}
