import { Component, DestroyRef, inject } from '@angular/core';
import { ICellRendererParams } from "ag-grid-community";
import { BulkSignatoryGroup } from "../../../../../../../models/signDocuments";
import { DocumentFileInfoShort } from '../../../../../../../models/files';
import { FilesService } from '../../../../../../../services/files.service';
import { DocumentSubFolderName } from '../../../../../../../models/enums/documentFolderNameEnum';
import { ToastrService } from 'ngx-toastr';
import { DocumentCommonService } from '../../../../../../../services/documents.common.service';

type CellRendererParams = ICellRendererParams<BulkSignatoryGroup>

@Component({
  selector: 'app-ag-documents-for-sign',
  standalone: true,
  imports: [],
  templateUrl: './ag-documents-for-sign.component.html',
  styleUrl: './ag-documents-for-sign.component.scss'
})

export class AgDocumentsForSignComponent {
  protected params!: CellRendererParams;
  protected filesService = inject(FilesService);
  protected toastr = inject(ToastrService);
  destroyRef: DestroyRef = inject(DestroyRef);
  #documentCommonService = inject(DocumentCommonService);

  protected documentsForSign: DocumentFileInfoShort[] = [];

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.documentsForSign = params.data?.filesInfo ?? [];
    this.setRowHeight();
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  setRowHeight(): void {
    const rowHeight = 28;

    if (this.documentsForSign.length > 1) {
      const documentsForSignRowHeight = this.documentsForSign.length * rowHeight;
      this.params.node.setRowHeight(documentsForSignRowHeight);
      this.params.api.onRowHeightChanged();
    }
  }

  downloadFile(documentId: string, fileName: string): void {
    this.#documentCommonService.downloadFile(documentId, fileName, DocumentSubFolderName.Final);
  }
}
