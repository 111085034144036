import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from "ag-grid-community";
import { ReminderSettingsModelExtended } from '../../../../../../models/reminderSettingsData';
import { AgActionIconButtonComponent } from "../../../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { HasRoleDirective } from '../../../../../../directives/has-role.directive';

type CellRendererParams = ICellRendererParams<ReminderSettingsModelExtended> & {
  edit: (data: ReminderSettingsModelExtended | undefined) => void
};

@Component({
  selector: 'app-ag-reminder-settings-action-component',
  standalone: true,
  imports: [
      AgActionIconButtonComponent,
      HasRoleDirective
    ],
  templateUrl: './ag-reminder-settings-action-component.component.html',
  styleUrl: './ag-reminder-settings-action-component.component.scss'
})
export class AgReminderSettingsActionComponentComponent implements ICellRendererAngularComp {
  params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  edit(): void {
    this.params.edit(this.params.data);
  }

}
