import { Component, OnInit, inject } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OrganisationService } from '../../../services/organisation.service';
import { catchError, map, of } from 'rxjs';
@Component({
  selector: 'app-redirect.esign',
  standalone: true,
  imports: [ RouterModule ],
  templateUrl: './redirect.esign.component.html',
  styleUrl: './redirect.esign.component.scss'
})
export class RedirectEsignComponent implements OnInit {

  private authService = inject(AuthService);
  protected organisationService = inject(OrganisationService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private sanitizer = inject(DomSanitizer);
  isAuthorized!: boolean;
  documentId  = '';
  logoUrl: SafeUrl | null = null;
  isDocument = true;
  successMessage = 'Envelope signed successfully';
  declinedMessage = 'Envelope declined';
  message = '';

  ngOnInit(): void {
    this.isAuthorized = this.authService.isAuthenticated;
    this.route.queryParamMap.subscribe((queryParams) => {
      const isDocumentParam = queryParams.get('isDocument');
      if (isDocumentParam && isDocumentParam === 'true') {
        this.isDocument = isDocumentParam === 'true';
      }
      
    });

    this.route.queryParamMap.subscribe((queryParams) => {
      const declinedParam = queryParams.get('declined');
      if (declinedParam && declinedParam === 'true') {
        this.message = this.declinedMessage;
      }
      else {
        this.message = this.successMessage;
      }
    });

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
      replaceUrl: true,
    });

    this.documentId = this.route.snapshot.paramMap.get('id') ?? '';
    if (this.documentId) {
      this.fetchLogo(this.documentId);
    }
    else {
      void this.authService.login();
    }
  }

  private fetchLogo(documentId: string): void {
    this.organisationService
      .getSmallLogByDocumentId(documentId)
      .pipe(
        map((blob) => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob))),
        catchError(() => {
          console.error('Failed to load the large logo. Falling back to default.');
          return of('assets/svg/pravo-logo-midnight.svg');
        })
      )
      .subscribe((logoUrl) => {
        this.logoUrl = logoUrl;
      });
  }

  get documentPath(): string[] {
    return this.isDocument ? ['/document', this.documentId] : ['/annual-statement', this.documentId];
  }
}
