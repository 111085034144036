import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, Signal, signal } from '@angular/core';
import { CommonModalFormComponent } from "../../common-modal-form/common-modal-form.component";
import { DividerComponent } from "../../../components/common/divider/divider.component";
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { SelectOption } from "../../../../models/selectOptions";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  MultipleInputEmailComponent
} from "../../../components/common/multiple-input-email/multiple-input-email.component";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { EmailTemplate } from "../../../../models/email-templates/emailTemplate";
import { SendOption } from "../../../../models/enums/annualStatementEnums";
import { catchError, EMPTY, firstValueFrom, map, Observable, of, tap } from "rxjs";
import { IEmailPreviewResponse } from "../../../../models/email-templates/IEmailPreviewResponse";
import { EmailTemplatesHelper, IEmailTemplateMockedData } from "../../../../models/email-templates/emailTemplatesHelper";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { OrganisationService } from "../../../../services/organisation.service";
import { CurrencyPipe, DatePipe, formatDate } from "@angular/common";
import { Company } from "../../../../models/company";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ACNPipe } from "../../../../pipes/acnPipe";
import { EmailMultipleAddress } from "../../../../models/email-templates/emailMultipleAddress";
import { ShadowDomViewerComponent } from "../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";
import { DocumentSigning } from "../../../../models/documentEnteties/document-signing";
import { ChangeAuthorisation } from "../../../../models/changeAuthorisation";
import { UIProfile } from "../../../../models/uiProfile";
import { TextMessagesTemplateSettingsBase } from "../../../../models/text-messages/textMessagesTemplateSettingsBase";
import { SelectComponent } from "../../../components/common/select/select.component";
import { Contact } from "../../../../models/contact";
import { TextMessageTemplatesService } from "../../../../services/sms-templates.service";
import { setControlDisabled } from "../../../../functions/set-control-disabled";
import { ReminderSendingType } from "../../../../models/enums/reminderEnums";
import { RemindersService } from "../../../../services/reminders.service";
import { TextMessageTemplate } from "../../../../models/text-messages/textMessageTemplate";
import { Guid } from "../../../helpers/guid.helper";
import { TextMessagesHelper } from "../../../../models/text-messages/textMessagesHelper";
import { AdHocPaymentReminderBody } from "../../../../models/adHocPaymentReminderBody";
import { AnnualStatementsService } from "../../../../services/annual-statements.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ReminderRecord } from '../../../../models/reminderRecord';

@Component({
  selector: 'app-ad-hoc-payment-reminder',
  standalone: true,
  imports: [
    CommonModalFormComponent,
    DividerComponent,
    RadioComponent,
    ButtonComponent,
    ReactiveFormsModule,
    MultipleInputEmailComponent,
    DatePipe,
    CurrencyPipe,
    NgxSkeletonLoaderModule,
    ShadowDomViewerComponent,
    SelectComponent
  ],
  providers: [EmailTemplatesService, TextMessageTemplatesService],
  templateUrl: './ad-hoc-payment-reminder.component.html',
  styleUrl: './ad-hoc-payment-reminder.component.scss'
})
export class AdHocPaymentRemiderComponent implements OnInit {
  @Input() isAnnualReminder = true;
  @Input() companyProfile: Company | undefined;
  @Input() documentSigning: DocumentSigning | null | undefined;
  @Input() annualPaymentDeadline: string | null | undefined;
  @Input() changeAuthorisation: ChangeAuthorisation | undefined;
  @Output() sendPaymentAdHocReminder = new EventEmitter<ReminderRecord>();

  private activeModal = inject(NgbActiveModal);
  private annualStatementsService = inject(AnnualStatementsService);
  private emailTemplatesService = inject(EmailTemplatesService);
  private textMessagesTemplateService = inject(TextMessageTemplatesService);
  private organisationService = inject(OrganisationService);
  private remindersService = inject(RemindersService);
  private acnPipe = inject(ACNPipe);
  private sanitizer = inject(DomSanitizer);
  private toastr = inject(ToastrService);
  private currencyPipe = inject(CurrencyPipe);
  private destroyRef = inject(DestroyRef);

  public sendByOptions: SelectOption[] = [
    { label: 'Email', value: SendOption.Email },
    { label: 'SMS', value: SendOption.Sms },
  ];

  public attachInvoiceOptions: SelectOption[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  public currentDate = new Date();
  public isSendDebtReminder = false;
  public loading = false;
  protected readonly SendOption = SendOption;

  public readonly payDebtUrl = 'https://paypaperbills.postbillpay.com.au/postbillpay/pay/default';
  public recipientPhoneList: SelectOption[] = [];
  public template!: EmailTemplate;
  private emailTemplates: EmailTemplate[] = [];
  public smsTemplate!: TextMessagesTemplateSettingsBase;
  private smsTemplates: Record<string, TextMessagesTemplateSettingsBase> | undefined;
  private payloadModel: Partial<IEmailTemplateMockedData> | undefined;
  private smsPayloadModel: Partial<IEmailTemplateMockedData> | undefined;
  form = new FormGroup({
    sendBy: new FormControl<SendOption>(SendOption.Email),
    attachInvoice: new FormControl<boolean>(true),
    recipientEmails: new FormControl<EmailMultipleAddress[]>([], CustomFormValidators.minArrayLength(1)),
    recipientPhones: new FormControl<SelectOption | null>(null, Validators.required)
  });

  htmlContent = signal<SafeHtml | null>(null);
  smsHtmlContent = signal<SafeHtml | null>(null);
  logo = toSignal(this.organisationService.getSmallLogo()
    .pipe(map(b => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(b))))
    .pipe(catchError(() => of('assets/svg/bolt-logo-icon.svg'))));

  phoneNumber: Signal<string | undefined> = toSignal(
    this.organisationService.getProfile().pipe(
      map(profile => profile.phoneNumber ?? '')
    )
  );

  userProfile: Signal<UIProfile | undefined> = toSignal(this.organisationService.getProfile());

  async ngOnInit(): Promise<void> {
    // TODO: only for test
    if(this.companyProfile && !this.companyProfile.billingContact) {
      this.companyProfile.billingContact = new Contact( {
        firstName: 'Va',
        lastName: 'Van',
        email: 'asd@sdf.com',
        phone: '12312312312'
      });
    }

    this.fillEmailsRecipient();
    this.getRecipientPhoneList();
    this.listenSendBy();
    this.listenRecipientPhone();

    this.loading = true;
    this.emailTemplates = await firstValueFrom(this.emailTemplatesService.loadEmailTemplates());
    this.smsTemplates = await firstValueFrom(this.textMessagesTemplateService.loadSmsDefaultTemplates());
    this.setEmailPreview();
    this.setSmsPreview();
    setControlDisabled(this.form.controls.recipientPhones, true);
  }

  private fillEmailsRecipient(): void {
    if (!this.companyProfile?.billingContact?.email) return;

    const recipient: EmailMultipleAddress = {
      email: this.companyProfile.billingContact.email.trim(),
      fullName: `${ this.companyProfile.billingContact.firstName.trim() } ${ this.companyProfile.billingContact.lastName.trim() }`
    };

    this.form.controls.recipientEmails.setValue([recipient]);
  }

  private setEmailPreview(): void {
    const sendBy = this.form.controls.sendBy.value!;
    //TODO: Which template needs to be used here?
    if (this.isAnnualReminder && sendBy === SendOption.Email) {
      this.selectEmailTemplate('as-debt-reminder');
    } else if (!this.isAnnualReminder && sendBy === SendOption.Email) {
      this.selectEmailTemplate('asic-debt-reminder');
    }
  }

  private selectEmailTemplate(templateCode: string): void {
    const emailTemplate = this.emailTemplates.filter(template => template.code === templateCode);
    if (emailTemplate.length) {
      this.template = emailTemplate[0];

      const type = EmailTemplatesHelper.buildEmailTemplatePayload(templateCode).$type;
      this.payloadModel = this.buildPayload(templateCode, type);

      this.template.subjectTemplate = this.template.subjectTemplate
        .replace('{{CompanyName}}', this.payloadModel.companyName ?? '');
      this.loadPreview().subscribe(() => {
        this.loading = false;
      });
    }
  }

  private prepareTemplateString(text: string | null): string {
    return (text ?? '')
      .replaceAll('\n', '')
      .replaceAll('<br />', '\n')
      .replaceAll('</br>', '\n');
  }

  private loadPreview(): Observable<IEmailPreviewResponse> {
    const errorFormResponse = of({ subject: '', htmlContent: '' });

    return this.emailTemplatesService.loadPreview(EmailTemplatesHelper.buildEmailPreviewRequest(this.buildTemplate()))
      .pipe(
        tap({
          next: (res) => this.htmlContent.set(this.sanitizer.bypassSecurityTrustHtml(res.htmlContent) ?? ''),
          error: (error) => {
            console.warn('[loadPreview] error: ', error);
            this.toastr.error("Something happened while loading preview", "Error");
            this.loading = false;
          }
        }),
        catchError(() => errorFormResponse),
      );
  }

  fillBodyTemplate(bodyTemplate: string): string {
    const body = bodyTemplate
      .replaceAll('{{DirectorFirstName}}', this.payloadModel?.directorFirstName ?? '')
      .replaceAll('{{DocumentName}}', this.payloadModel?.documentName ?? '')
      .replaceAll('{{CompanyName}}',this.payloadModel?.companyName ?? '')
      .replaceAll('{{OrganisationPhone}}', this.payloadModel?.organisationPhone ?? '')
      .replaceAll('{{OrganisationEmail}}', this.payloadModel?.organisationEmail ?? '')
      .replaceAll('{{AnnualReviewFeeDeadline}}', this.payloadModel?.annualReviewFeeDeadline ?? '');

    return body ? body : bodyTemplate;
  }

  fillFooterTemplate(footerTemplate: string): string {
    const footer = footerTemplate
      .replace('{{Username}}', this.payloadModel?.username ?? '')
      .replace('{{OrganisationName}}', this.payloadModel?.organisationName ?? '');

    return footer ? footer : footerTemplate;
  }

  private buildTemplate(): EmailTemplate {
    return new EmailTemplate({
      ...this.template,
      subjectTemplate: this.template?.subjectTemplate,
      bodyTemplate: this.replaceNewLine(this.prepareTemplateString(this.fillBodyTemplate(this.template.bodyTemplate))),
      footerTemplate: this.replaceNewLine(this.prepareTemplateString(this.fillFooterTemplate(this.template.footerTemplate))),
    });
  }

  private getRecipientPhoneList(): void {
    if (!this.companyProfile?.billingContact?.phone) return;

    const fullName = `${this.companyProfile.billingContact.firstName.trim()} ${this.companyProfile.billingContact.lastName.trim()}`;
    const phone = this.companyProfile.billingContact.phone.trim();
    const recipient = {
      label: `${fullName} (${phone})`,
      value: phone
    };

    this.form.controls.recipientPhones.setValue(recipient);
  }

  private listenSendBy(): void {
    this.form.get('sendBy')?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(sendBy => {
      setControlDisabled(this.form.controls.recipientPhones, sendBy === SendOption.Email);
      setControlDisabled(this.form.controls.recipientEmails, sendBy === SendOption.Sms);
    });
  }

  private listenRecipientPhone(): void {
    this.form.get('recipientPhones')?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(recipientPhone => {
      if(recipientPhone) {
        this.setSmsPreview();
      }
    });
  }

  private setSmsPreview(): void {
    if (this.isAnnualReminder) {
      this.selectSmsTemplate('as-fee-reminder');
    } else {
      this.selectSmsTemplate('company-debt-reminder');
    }
  }

  private selectSmsTemplate(templateCode: string): void {
    if(this.smsTemplates) {
      const smsTemplate = Object.values(this.smsTemplates)
        .filter(template => template.templateCode === templateCode);
      this.smsTemplate = smsTemplate[0];

      const type = TextMessagesHelper.buildTextMessageTemplatePayload(templateCode).$type;
      this.smsPayloadModel = this.buildPayload(templateCode, type);

      const replacedSmsTemplate = this.smsTemplate.defaultTemplate
        .replace('{{CompanyName}}', this.smsPayloadModel.companyName ?? '')
        .replace('{{AnnualReviewFeeDeadline}}', this.smsPayloadModel.annualReviewFeeDeadline ?? '')
        .replace('{{AnnualReviewFee}}', this.smsPayloadModel.annualReviewFee ?? '')
        .replace('{{OrganisationName}}', this.smsPayloadModel.organisationName ?? '')
        .replace('{{OrganisationPhone}}', this.smsPayloadModel.organisationPhone ?? '');

      this.smsHtmlContent.set(this.sanitizer.bypassSecurityTrustHtml(replacedSmsTemplate));
    }
  }

  private buildPayload(templateCode: string, type: string): Partial<IEmailTemplateMockedData> {
    const billingContactFullName = (this.companyProfile?.billingContact?.firstName ?? '') + ' '
      + (this.companyProfile?.billingContact?.lastName ?? '');

    const payload = {
      $type: type,
      organisationName: this.userProfile()?.organisationName ?? '',
      organisationEmail: this.userProfile()?.email ?? '',
      organisationPhone: this.userProfile()?.phoneNumber ?? '',
      documentName: EmailTemplatesHelper.TemplateHeadersRecord[templateCode],
      companyName: this.companyProfile?.name ?? '',
      directorFirstName: this.changeAuthorisation?.authorisingParty?.individualDataOverride?.firstName ?? this.changeAuthorisation?.chairperson?.individualDataOverride?.firstName ?? '',
      directorFullName: this.changeAuthorisation?.authorisingParty?.individualDataOverride?.fullName ?? this.changeAuthorisation?.chairperson?.individualDataOverride?.fullName ?? '',
      username: this.organisationService.getCurrentUser()?.fullName ?? '',
      companyACN: this.companyProfile?.acn ?? '',
      partner: this.companyProfile?.partnerManager?.fullName ?? '',
      asicContact: '',
      accountManager: this.companyProfile?.accountManager?.fullName ?? '',
      billingContactFirstName: this.companyProfile?.billingContact?.firstName ?? '',
      billingContactFullName: billingContactFullName.trim() ?? '',
      payUrl: this.payDebtUrl,
    }

    if(this.isAnnualReminder) {
      return {
        ...payload,
        annualReviewFeeDeadline: this.annualPaymentDeadline ? formatDate(this.annualPaymentDeadline, 'dd MMM yyyy', 'en-US') : '',
        annualReviewFeeDeadlineShortFormat: this.annualPaymentDeadline ? formatDate(this.annualPaymentDeadline, 'dd/MM/yyyy', 'en-US') : '',
        annualReviewFee: this.currencyPipe.transform(this.companyProfile?.companyDebt?.amountOwing ?? 0, '$') ?? '',
      }
    } else {
      return {
        ...payload,
        debt: this.currencyPipe.transform(this.companyProfile?.companyDebt?.amountOwing ?? 0, '$') ?? '',
        dueDate: '',
        dueDateShortFormat: '',
      }
    }
  }

  private buildMessageTemplate(): TextMessageTemplate {
    return new TextMessageTemplate({
      textMessageTemplateId: Guid.EmptyGuid,
      organisationId: this.userProfile()?.organisationId,
      code: this.smsTemplate.templateCode,
      template: this.smsTemplate.defaultTemplate
    });
  }

  private replaceNewLine(text: string | null): string {
    return text?.replaceAll('\n', '</br>') ?? '';
  }

  public payDebt(): void {
    window.open(this.payDebtUrl, '_blank');
  }

  public refreshDebt(): void {
    const companyId = this.companyProfile?.entityId ?? '';
    if(!companyId) return;

    this.annualStatementsService.annualRegenerate(companyId).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  public sendReminder(): void {
    const sendingType = this.form.get('sendBy')?.value === SendOption.Email ? ReminderSendingType.Email : ReminderSendingType.SMS;
    const emails = sendingType === ReminderSendingType.Email
      ? (this.form.get('recipientEmails')?.value as unknown as EmailMultipleAddress[])?.map(item => item?.email)
      : [];
    const phones = sendingType === ReminderSendingType.SMS
      ? [this.form.get('recipientPhones')?.value?.value as string]
      : [];

    const body = {
      documentId: this.documentSigning?.documentId,
      entityId: this.companyProfile?.entityId,
      sendingType,
      emails,
      phones,
      emailPayloadModel: this.payloadModel,
      emailTemplate: this.buildTemplate(),
      textPayloadModel: this.smsPayloadModel,
      textTemplate: this.buildMessageTemplate(),
      includeInvoice: sendingType === ReminderSendingType.SMS ? false : this.form.get('attachInvoice')?.value ?? false,
      companyName: this.companyProfile?.name ?? '',
    } as AdHocPaymentReminderBody;

    this.remindersService.sendPaymentAdHocReminder(body).pipe(
      tap((remider) => {
        this.toastr.success("The payment reminder has been successfully sent to the recipients", "Success");
        this.sendPaymentAdHocReminder.emit(remider);
        this.activeModal.close();
      }),
      catchError((err: HttpErrorResponse) => {
        if (typeof err.error === 'string' && err.error.includes('System.NullReferenceException')) {
          this.toastr.error("The invoice doesn't exist. The payment reminder has not been sent.", 'Error');
        } else {
          this.toastr.error("The payment reminder hasn't been sent", 'Error');
        }

        return EMPTY
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  public close(): void {
    this.activeModal.dismiss();
  }
}
