import { Component, DestroyRef, inject } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererParams } from "ag-grid-community";
import { AnnualRecord } from "../../../../../../../models/annualRecord";
import { AnnualStatementPageFilter, AnnualStatementStatus, SigningStatus } from "../../../../../../../models/enums/annualStatementEnums";
import { TooltipComponent } from "../../../../tooltip/tooltip.component";
import { AnnualStatementsService } from "../../../../../../../services/annual-statements.service";
import { ConfirmComponent } from "../../../../../../modals/confirm/confirm.component";
import { of } from "rxjs";
import { HasRoleDirective } from '../../../../../../../directives/has-role.directive';
import { ApproverStatus } from '../../../../../../../models/enums/approverStatus';
import { RegenerateStatementModalComponent } from '../../../../../../modals/regenerate-statement-modal/regenerate-statement-modal.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<AnnualRecord> & {
  view: ActionEvent,
  send: ActionEvent,
  edit: ActionEvent,
  downloadDocuments: ActionEvent,
  archive: ActionEvent,
  ignore: ActionEvent,
  sendReminder: ActionEvent,
  sendPaymentReminder: ActionEvent,
  regenerate: ActionEvent,
  markAsSent: ActionEvent,
  markAsSigned: ActionEvent
  refreshCompanyDebt: ActionEvent,
  refreshCompanyInfo: ActionEvent,
  requestRefresh: ActionEvent,//???
};

@Component({
  selector: 'app-ag-annual-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    TooltipComponent,
    HasRoleDirective
  ],
  templateUrl: './ag-annual-actions.component.html',
  styleUrl: './ag-annual-actions.component.scss'
})
export class AgAnnualActionsComponent {
  protected modalService = inject(NgbModal);
  protected annualStatementsService = inject(AnnualStatementsService);
  protected destroyRef = inject(DestroyRef);

  protected params!: CellRendererParams;
  protected status: AnnualStatementStatus | null | undefined;
  protected signingStatus: SigningStatus | undefined;
  protected approverStatus: ApproverStatus | undefined;
  protected readonly SigningStatus = SigningStatus;
  protected isPaid: boolean | undefined;
  protected documentId: string | null | undefined;
  protected debt: number | undefined;
  protected AnnualStatementStatus = AnnualStatementStatus;
  protected AnnualStatementPageFilter = AnnualStatementPageFilter;
  protected readonly tooltipMessage = 'Customise and Send ';
  protected menu: { name: string, iconClass: string, action: () => void }[] = [];

  protected readonly menuActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
    { name: 'Re-generate', iconClass: 'icon ic-refresh mr-1', action: () => this.regenerate() },
    { name: 'Manually Mark as Sent', iconClass: 'icon ic-circle-check mr-1', action: () => this.markAsSent() },
    { name: 'Manually Mark as Signed', iconClass: 'icon ic-circle-check mr-1', action: () => this.markAsSigned() },
  ];

  protected readonly notReceivedMenuActions = [
    { name: 'Ignore', iconClass: 'icon ic-download-file mr-1', action: () => this.ignore() },
    { name: 'Re-generate', iconClass: 'icon ic-refresh mr-1', action: () => this.regenerate() },
  ];

  protected readonly menuSentActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
    { name: 'Send ad-hoc Signing Reminder', iconClass: 'icon ic-bell mr-1', action: () => this.sendReminder() },
    { name: 'Send ad-hoc Payment Reminder', iconClass: 'icon ic-bell mr-1', action: () => this.sendPaymentReminder() },
    { name: 'Refresh Debt via RA63', iconClass: 'icon ic-refresh mr-1', action: () => this.refreshCompanyDebt() },
    { name: 'Mark as Signed', iconClass: 'icon ic-circle-check mr-1', action: () => this.markAsSigned() },
  ];

  protected readonly menuSignedActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
    { name: 'Send ad-hoc Payment Reminder', iconClass: 'icon ic-bell mr-1', action: () => this.sendPaymentReminder() },
    { name: 'Refresh Debt via RA63', iconClass: 'icon ic-refresh mr-1', action: () => this.refreshCompanyDebt() },
  ];

  protected readonly menuPaidActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
    { name: 'Refresh Debt via RA63', iconClass: 'icon ic-refresh mr-1', action: () => this.refreshCompanyDebt() },
    { name: 'Mark as Signed', iconClass: 'icon ic-circle-check mr-1', action: () => this.markAsSigned() },
  ];

  protected readonly menuFinalizedActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
    { name: 'Archive', iconClass: 'icon ic-archive mr-1', action: () => this.archive() },
  ];

  protected readonly menuArchivedActions = [
    { name: 'Download Documents', iconClass: 'icon ic-download-file mr-1', action: () => this.downloadDocuments() },
  ];

  agInit(params: CellRendererParams): void {
    this.setParams(params);
    this.setMenuOption();
  }

  refresh(params: CellRendererParams): boolean {
    this.setParams(params);
    return true;
  }

  setParams(params: CellRendererParams): void {
    this.params = params;
    this.status = params.data?.status;
    this.signingStatus = params.data?.signingStatus;
    this.approverStatus = params.data?.approverStatus;
    this.isPaid = params.data?.isPaid;
    this.documentId = params.data?.documentId;
    this.debt = params.data?.debt ?? 0;
  }

  setMenuOption(): void {
    const page = this.annualStatementsService.pageFilter();
    const signingReminderName = 'Send ad-hoc Signing Reminder';
    const paymentReminderName = 'Send ad-hoc Payment Reminder';

    if(page === AnnualStatementPageFilter.NotReceived) {
      this.menu = this.notReceivedMenuActions;
    } else if(this.isPaid) {
      this.menu = this.menuPaidActions;
    } else if(this.status === AnnualStatementStatus.Sent) {
      if(page === AnnualStatementPageFilter.Overdue || page === AnnualStatementPageFilter.PaymentDue) {
        this.menu = this.menuSentActions;
      } else {
        this.menu = this.menuSentActions.filter(item => item.name !== paymentReminderName);
      }

      if(this.approverStatus && this.approverStatus !== ApproverStatus.Approved) {
        this.menu = this.menuSentActions.filter(item => item.name !== signingReminderName);
      }
    } else if(this.status === AnnualStatementStatus.Signed) {
      if(page === AnnualStatementPageFilter.Overdue || page === AnnualStatementPageFilter.PaymentDue) {
        this.menu = this.menuSignedActions;
      } else {
        this.menu = this.menuSignedActions.filter(item => item.name !== paymentReminderName);
      }
    } else if(this.status === AnnualStatementStatus.Finalized) {
      this.menu = this.menuFinalizedActions;
    } else if(this.status === AnnualStatementStatus.Archived) {
      this.menu = this.menuArchivedActions;
    } else {
      this.menu = this.menuActions;
    }
  }

  view(): void {
    this.params.view(this.params.data?.companyId);
  }

  send(): void {
    this.params.send(this.params.data?.documentId);
  }

  edit(): void {
    this.params.edit(this.params.data?.documentId);
  }

  downloadDocuments(): void {
    this.params.downloadDocuments(this.params.data);
  }

  archive(): void {
    if(this.status !== AnnualStatementStatus.Finalized) {
      const modalRef = this.modalService.open(ConfirmComponent);
      (modalRef.componentInstance as ConfirmComponent).title = 'Archive warning';
      (modalRef.componentInstance as ConfirmComponent).message = 'Once you change the status to "Archive",' +
        ' you will have limited options for managing the annual statement. This means you will not be able to refresh' +
        ' the debt or send the annual statement. If annual statements have already been sent for eSigning' +
        ' and are still in progress, the eSigning process will be cancelled. Payment reminders will not be sent either.\n' +
        'Would you like to continue?';
      (modalRef.componentInstance as ConfirmComponent).confirmText = 'Yes';
      (modalRef.componentInstance as ConfirmComponent).closeText = 'No';
      (modalRef.componentInstance as ConfirmComponent).confirm = () => {
        this.params.archive(this.params.data);
        return of(true);
      };
    } else {
      this.params.archive(this.params.data?.documentId);
    }
  }

  sendReminder(): void {
    this.params.sendReminder(this.params.data?.companyId);
  }

  sendPaymentReminder(): void {
    this.params.sendPaymentReminder(this.params.data?.companyId);
  }

  refreshCompanyDebt(): void {
    this.params.refreshCompanyDebt(this.params.data?.companyId);
  }

  refreshCompanyInfo(): void {
    this.params.refreshCompanyInfo(this.params.data?.companyId);
  }

  regenerate(): void {
    const modalRef = this.modalService.open(RegenerateStatementModalComponent, { size: 'sm', centered: true  });
    (modalRef.componentInstance as RegenerateStatementModalComponent).confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((action: string) => {
      if(action === 'regenerate') {
        this.regenerateFake();
       } else if(action === 'reprint') {
         this.reprint();
       }
    });
  }

  regenerateFake(): void {
    if(!this.debt) {
      const modalRef = this.modalService.open(ConfirmComponent);
      (modalRef.componentInstance as ConfirmComponent).title = 'No debt warning';
      (modalRef.componentInstance as ConfirmComponent).message = 'The company currently has no debt.' +
        ' Would you like to regenerate the annual statement?';
      (modalRef.componentInstance as ConfirmComponent).confirmText = 'Yes';
      (modalRef.componentInstance as ConfirmComponent).closeText = 'No';
      (modalRef.componentInstance as ConfirmComponent).confirm = () => {
        this.params.regenerate(this.params.data?.companyId);
        return of(true);
      };

      return;
    }

    this.params.regenerate(this.params.data?.companyId);
  }

  markAsSent(): void {
    this.params.markAsSent(this.params.data?.documentId);
  }

  markAsSigned(): void {
    const modalRef = this.modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Sign warning';
    (modalRef.componentInstance as ConfirmComponent).message = 'eSigning is currently in progress.' +
      ' If you mark the annual statement as "Signed," it will prevent signatories from signing and the envelope' +
      ' from being voided. Would you like to proceed?';
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Yes';
    (modalRef.componentInstance as ConfirmComponent).closeText = 'No';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => {
      this.params.markAsSigned(this.params.data?.documentId);
      return of(true);
    };
  }

  ignore(): void {
    this.params.ignore(this.params.data?.companyId);
  }

  reprint(): void {
    this.params.refreshCompanyInfo(this.params.data?.companyId);
  }
}
