import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { map, Observable } from 'rxjs';
import { Entity } from '../models/entity';
import { SecurityChange } from '../models/securityChange';
import { SecurityRegistryRecord } from '../models/securityRegistryRecord';
import { SecurityTransaction } from "../models/securityTransaction";
import { SecurityTransactionType } from "../models/enums/SecurityTransactionType";
import { ClientSecurityTransaction } from '../models/clientSecurityTransaction';
import { IndividualData } from '../models/individualData';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor(private http: HttpClient) { }

  getSecurityRegistry(id: string, groupByAsic = true, excludedDocumentId = '', applyIncompleteDocuments = true): Observable<SecurityRegistryRecord[]> {
    const options = { params: { excludedDocumentId, groupByAsic, applyIncompleteDocuments } };
    return this.http.get<SecurityRegistryRecord[]>(`${environment.api_url}/securities/${id}/`, options)
      .pipe(map((data) => data.map(record => new SecurityRegistryRecord(record))));
  }

  getSecurityEstimate<T extends Entity>(entityId: string, change: SecurityChange<T>[], excludedDocumentId = ''): Observable<SecurityRegistryRecord[]> {
    const options = { params: { excludedDocumentId } };
    return this.http.post<SecurityRegistryRecord[]>(`${environment.api_url}/securities/${entityId}/estimate`, change, options)
      .pipe(map((data) => data.map(record => new SecurityRegistryRecord(record))));
  }

  getSecurityTransactions(companyId: string): Observable<SecurityTransaction[]> {
    return this.http.get<SecurityTransaction[]>(`${environment.api_url}/securities/${companyId}/transactions`)
      .pipe(map((data) => data.map(record => new SecurityTransaction(record))))
      .pipe(map((data) => this.calculateBalance(data, false)));
  }

  calculateBalance(data: SecurityTransaction[], sumAll = true): ClientSecurityTransaction[] {
    const sortedData = data
      .sort((a, b) => a.transactionDate.getTime() - b.transactionDate.getTime() || b.transactionType - a.transactionType)
      .map((item) => {
        return new ClientSecurityTransaction({
          ...item,
          groupNumberBalance: item.numberIncrease,
          groupPaidBalance: item.paidIncrease,
          groupUnpaidBalance: item.unpaidIncrease,
          numberBalance: item.numberIncrease,
          paidBalance: item.paidIncrease,
          unpaidBalance: item.unpaidIncrease
        });
      });


    //calculate balances for each holding group
    const groupedData = sortedData.reduce((acc, item) => {
      acc[item.securityHoldingId!] = acc[item.securityHoldingId!] || new Array<ClientSecurityTransaction>();
      acc[item.securityHoldingId!].push(item);
      return acc;
    }, {} as Record<string, ClientSecurityTransaction[]>);

    Object.values(groupedData).forEach((group) => {
      group.forEach((item, i) => {
        if (i > 0) {
          if (item.transactionType === SecurityTransactionType.Balance) {
            item.groupNumberBalance = item.numberIncrease;
            item.groupPaidBalance = item.paidIncrease;
            item.groupUnpaidBalance = item.unpaidIncrease
          }
          else {
            item.groupNumberBalance = group[i - 1].numberBalance + item.numberIncrease;
            item.groupPaidBalance = group[i - 1].paidBalance + item.paidIncrease;
            item.groupUnpaidBalance = group[i - 1].unpaidBalance + item.unpaidIncrease;
          }
        }
      });
    });

    if (!sumAll) {
      sortedData.forEach((item) => {
        item.numberBalance = item.groupNumberBalance;
        item.paidBalance = item.groupPaidBalance;
        item.unpaidBalance = item.groupUnpaidBalance;
      });
    }
    else {
      sortedData.forEach((item, i) => {
        if (i > 0) {
          if (item.transactionType === SecurityTransactionType.Balance) {
            const previousHoldingTransaction = sortedData.slice(0, i).reverse().find(x => x.securityHoldingId === item.securityHoldingId && x.transactionType === SecurityTransactionType.Balance);

            if (previousHoldingTransaction) {
              item.numberBalance = previousHoldingTransaction ? sortedData[i - 1].numberBalance + item.numberIncrease - previousHoldingTransaction.groupNumberBalance : sortedData[i - 1].numberBalance + item.numberIncrease;
              item.paidBalance = previousHoldingTransaction ? sortedData[i - 1].paidBalance + item.paidIncrease - previousHoldingTransaction.groupPaidBalance : sortedData[i - 1].paidBalance + item.paidIncrease;
              item.unpaidBalance = previousHoldingTransaction ? sortedData[i - 1].unpaidBalance + item.unpaidIncrease - previousHoldingTransaction.groupUnpaidBalance : sortedData[i - 1].unpaidBalance + item.unpaidIncrease;
            }
            else {
              item.numberBalance = sortedData[i - 1].numberBalance + item.numberIncrease;
              item.paidBalance = sortedData[i - 1].paidBalance + item.paidIncrease;
              item.unpaidBalance = sortedData[i - 1].unpaidBalance + item.unpaidIncrease;
            }
          }
          else {
            item.numberBalance = sortedData[i - 1].numberBalance + item.numberIncrease;
            item.paidBalance = sortedData[i - 1].paidBalance + item.paidIncrease;
            item.unpaidBalance = sortedData[i - 1].unpaidBalance + item.unpaidIncrease;
          }
        }
      });
    }

    return sortedData.reverse();
  }

  updateBeneficialOwner(relationshipId: string, beneficialOwnerName: string) {
    return this.http.post<void>(`${environment.api_url}/securities/${relationshipId}/beneficial-owner`, `"${beneficialOwnerName}"`, { headers: { 'Content-Type': 'application/json' } });
  }

  updateAuthSignatories(relationshipId: string, newAuthSignatories: IndividualData[]) {
    return this.http.post<void>(`${environment.api_url}/securities/${relationshipId}/auth-signatories`, newAuthSignatories, { headers: { 'Content-Type': 'application/json' } });
  }

  getNextCertificateNumber(entityId: string): Observable<string> {
    return this.http.get<string>(`${environment.api_url}/securities/${entityId}/certificate/next`);
  }

  checkCertificateExists(entityId: string, certNumber: string): Observable<boolean> {
    return this.http.head(`${environment.api_url}/securities/${entityId}/certificate/${certNumber}`, { observe: 'response' })
      .pipe(map(response => response.status === 200));
  }
}
