import { Pipe, PipeTransform } from '@angular/core';
import { CompanySubtype } from '../../models/enums/companyEnums';

@Pipe({
  standalone: true,
  name: 'companySubtype'
})
export class CompanySubtypePipe implements PipeTransform {
  transform(value: CompanySubtype | undefined): string {
    if(value != undefined)
      return CompanySubtype[value];
    return '-';
  }
}
