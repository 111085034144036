import { Component } from '@angular/core';
import { RadioComponent } from "../../../components/common/radio/radio.component";
import { ButtonComponent } from "../../../components/common/button/button.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { InputComponent } from "../../../components/common/input/input.component";
import { TextareaComponent } from "../../../components/common/textarea/textarea.component";
import { EmailPreviewComponent } from "../../../components/email-preview/email-preview.component";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import {
  MultipleInputEmailComponent
} from "../../../components/common/multiple-input-email/multiple-input-email.component";
import {
  AnnualSignDocumentsComponent
} from "../../../annual-statement/annual-sign-documents/annual-sign-documents.component";
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import {
  AttachedFileLabelComponent
} from "../../../components/notes/components/attached-file-label/attached-file-label.component";
import { DatePickerComponent } from "../../../components/common/date-picker/date-picker.component";
import { FileUploadComponent } from "../../../components/common/file-upload/file-upload.component";
import { NotificationComponent } from "../../../components/common/notification/notification.component";
import { SendOption } from "../../../../models/enums/annualStatementEnums";
import { catchError, EMPTY, filter, from, Observable, switchMap, tap } from "rxjs";
import { toBase64 } from "../../../../functions/to-base64";
import { Operation } from "fast-json-patch";
import { LodgeDocumentsComponent } from "../lodge-documents/lodge-documents.component";
import { DatePipe, formatDate } from "@angular/common";
import { DocumentStatusEnum } from "../../../../models/enums/documentStatusEnum";
import { AnnualStatement } from "../../../../models/annualStatement";
import { DocumentSigning } from "../../../../models/documentEnteties/document-signing";
import { Document } from "../../../../models/document";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-sign-documents',
  standalone: true,
  imports: [
    RadioComponent,
    ButtonComponent,
    FormsModule,
    InputComponent,
    TextareaComponent,
    ReactiveFormsModule,
    EmailPreviewComponent,
    ListGridComponent,
    MultipleInputEmailComponent,
    AttachedFileLabelComponent,
    DatePickerComponent,
    FileUploadComponent,
    NotificationComponent,
    LodgeDocumentsComponent,
    DatePipe
  ],
  providers: [EmailTemplatesService],
  templateUrl: './sign-documents.component.html',
  styleUrl: './sign-documents.component.scss'
})
export class SignDocumentsComponent extends AnnualSignDocumentsComponent {
  protected readonly DocumentStatusEnum = DocumentStatusEnum;

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override disableHeaderBtn(value: boolean): void {
    this.documentsService.disabledHeaderBtn.set(value);
  }

  override showVoidEnvelope(value: boolean): void {
    this.documentsService.showVoidEnvelope.set(value);
  }

  override showSendEmailBtn(value: boolean): void {
    this.documentsService.showSendEmailBtn.set(value);
  }

  override showSendPaperBtn(value: boolean): void {
    this.documentsService.showSendPaperBtn.set(value);
  }

  override sendSignLoading(value: boolean): void {
    this.documentsService.sendSignLoading.set(value);
  }

  override patchOperation(documentId: string, operation: Operation[]): Observable<any> {
    return this.documentsService.patchDocumentOperation(documentId, operation);
  }

  override listenHeaderButton(): void {
    const documentId = this.documentId ?? '';

    this.confirmSentESignAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => this.sendToESign()),
      switchMap(() => {
        return this.changeDocumentStatusOperation(documentId, DocumentStatusEnum.LodgementPending);
      }),
      tap(() => this.confirmSentESignAction$?.next(false)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.confirmPaperSignAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => this.paperSign(this.documentId ?? '')),
      switchMap(() => {
        return this.changeDocumentStatusOperation(documentId, DocumentStatusEnum.LodgementPending);
      }),
      tap(() => this.confirmPaperSignAction$?.next(false)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.confirmAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => this.confirm()),
      tap(() => this.confirmAction$?.next(false)),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();

    this.confirmNotLodgedFormAction$?.pipe(
      filter(confirm => confirm),
      switchMap(() => {
        this.sendSignLoading(true);
        return this.changeDocumentStatusOperation(this.documentId ?? '', DocumentStatusEnum.Completed).pipe(
          catchError(err => {
            this.toastr.error('Change document status error', 'Error');
            console.error(err);
            this.sendSignLoading(false);
            return EMPTY;
          })
        ) as unknown as Observable<Document>;
      }),
      tap(() => {
        this.toastr.success('Document status changed successfully', 'Success');
        this.nextStep.emit(true);
        this.confirmNotLodgedFormAction$?.next(false);
        this.sendSignLoading(false);
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  override confirm(): Observable<AnnualStatement | Document | string> {
    const documentId = this.documentId ?? '';
    const changeDocumentStatus$ = this.changeDocumentStatusOperation(documentId, DocumentStatusEnum.LodgementPending);

    if (this.form.get('sendBy')?.value === SendOption.Sign) {
      return changeDocumentStatus$.pipe(
        catchError(err => {
          this.toastr.error('Change document status error', 'Error');
          console.error(err);
          this.sendSignLoading(false);
          return EMPTY;
        })
      );
    } else if (this.form.get('sendBy')?.value === SendOption.Email) {
      return this.sendEmail();
    } else if (this.form.get('sendBy')?.value === SendOption.Paper) {
      return changeDocumentStatus$.pipe(
        catchError(err => {
          this.toastr.error('Change document status error', 'Error');
          console.error(err);
          this.sendSignLoading(false);
          return EMPTY;
        })
      );
    }

    return EMPTY;
  }

  override paperSign(documentId: string): Observable<AnnualStatement | DocumentSigning> {
    return from(Promise.all(
      this.uploadedFiles.map(async (file: File) => ({
        fileName: file.name,
        base64FileContent: await toBase64(file),
      }))
    )).pipe(
      switchMap(attachmentsToUpload => {
        const paperSignBody = {
          signedDate: this.formattedSigningDate,
          attachmentsToUpload
        };

        return this.eSignService.paperSign(documentId, paperSignBody).pipe(
          tap(() => {
            this.toastr.success("Paper sign document confirmed", "Success");
            this.updateDocuments.emit(true);
          }),
          catchError(err => {
            this.toastr.error('Paper sign error', 'Error');
            console.error(err);
            return EMPTY;
          })
        );
      }),
    );
  }

  override showSendEmailNotification(recipientsList: string[]): void {
    const emailList = recipientsList.join(', ');
    const currentDate = formatDate(new Date(), 'dd MMM yyyy', 'en-US');
    this.successMessage = `The documents have been emailed to ${emailList} on ${currentDate}`;
  }
}
