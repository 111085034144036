<app-divider></app-divider>
<div>
  @if(isShowSigningReminder) {
    <app-reminder
      [reminderList]="signingReminderList"
      [signingStatus]="documentSigning?.signingStatus"
      (showReminder)="showSigningReminderPopup()"
      (addReminder)="addReminder($event, ReminderType.Signing)"
      (removeReminder)="removeReminder($event)"
    ></app-reminder>
  }

  @if(isShowPaymentReminder && showPaymentReminder) {
    <app-reminder
      [title]="'Payment Reminder'"
      [sendTooltipText]="'Send ad-hoc Payment Reminder'"
      [reminderList]="paymentReminderList"
      [signingStatus]="documentSigning?.signingStatus"
      (showReminder)="showPaymentReminderPopup()"
      (addReminder)="addReminder($event, ReminderType.Payment)"
      (removeReminder)="removeReminder($event)"
    ></app-reminder>
  }
</div>