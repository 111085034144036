import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ExternalEntity } from '../../../../models/externalEntity';
import { SubMenuItem } from '../../../../models/sectionFormMenu';
import { ExternalEntitiesService } from '../../../../services/external.entites.service';
import { ExternalSourceType } from '../../../../models/enums/externalEntityEnums';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgIf } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { EntityType } from '../../../../models/enums/entityType';

@Component({
  selector: 'app-xpm-card-profile',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgIf,
    CommonModule
  ],
  templateUrl: './xpm-card-profile.component.html',
  styleUrl: './xpm-card-profile.component.scss'
})
export class XpmCardProfileComponent{
  @Input() externalEntities!: ExternalEntity[];
  @Output() externalEntitiesChange = new EventEmitter<ExternalEntity[]>();
  @Input() entityId!: string;
  @Input() entityNumber!: string | undefined;
  @Input() entityType!: EntityType
  @Input() disableControls = false;
  @Output() onEntityRefreshed = new EventEmitter<void>();

  externalEntitiesService = inject(ExternalEntitiesService);

  lastSync = '';

  xpmMenu: SubMenuItem[] = [
    {
      title: 'Go to Profile',
      iconClass: 'icon ic-external-link',
      action: () => this.redirectToExternalProfile(),
      isVisable: () => this.isConnectedtoExtentedEntity()
    },
    {
      title: 'Refresh',
      iconClass: 'icon ic-refresh',
      action: () => { void this.refreshProfile(); },
      isVisable: () => this.isConnectedtoExtentedEntity()
    },
    {
      title: 'Unlink Profile',
      iconClass: 'icon ic-unlink',
      action: () =>  { void this.unlinkExternalProfile(); },
      isVisable: () => this.isConnectedtoExtentedEntity()
    },
    {
      title: 'Link Profile',
      iconClass: 'icon ic-link',
      action: () =>  { void this.linkExternalProfile() ; },
      isVisable: () => !this.isConnectedtoExtentedEntity()
    }
  ];

  getLastSyncDateMessage(): string {
    this.lastSync = this.externalEntitiesService.getLastSyncDateMessage(this.externalEntities, ExternalSourceType.Xpm);
    return this.lastSync;
  }

  updateEntities(newEntities: ExternalEntity[]): void {
    this.externalEntities = newEntities;
    this.externalEntitiesChange.emit(newEntities);
  }

  async refreshProfile() {
    await this.externalEntitiesService.refreshProfile(this.entityId, this.externalEntities, ExternalSourceType.Xpm);
    this.onEntityRefreshed.emit();
  } 

  redirectToExternalProfile() {
    this.externalEntitiesService.redirectToExternalProfile(this.externalEntities, ExternalSourceType.Xpm);
  }

  async unlinkExternalProfile() {
    const upadtedEnteties = await this.externalEntitiesService.unlinkExternalProfile(this.entityId, this.externalEntities, ExternalSourceType.Xpm);
    this.updateEntities(upadtedEnteties);
  }

  async linkExternalProfile() {
    const newupadtedEntety = await firstValueFrom(this.externalEntitiesService.linkExternalProfile(this.entityId,
      this.entityNumber ?? '',
      ExternalSourceType.Xpm,
      this.entityType)
    );

    this.externalEntities.push(newupadtedEntety);
    this.externalEntitiesChange.emit(this.externalEntities);
  }

  isConnectedtoExtentedEntity(): boolean {
    return this.externalEntitiesService.isConnectedtoExtentedEntity(this.externalEntities, ExternalSourceType.Xpm);
  }

}
