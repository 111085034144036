import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Entity } from '../../models/entity';
import { DocumentTypePipe } from '../../pipes/document-type.pipe';
import {
  AgAsicDocumentStatusComponent
} from '../components/common/grid/components/ag-asic-document-status/ag-asic-document-status.component';
import {
  AgDocumentActionsComponent
} from '../components/common/grid/components/ag-document-actions/ag-document-actions.component';
import { DocumentsService } from '../../services/documents.service';
import { DocumentRecord } from '../../models/documentRecord';
import { DocumentStatusPipe } from '../../pipes/enumsPipes/documentStatusPipe';
import { formatDate } from '@angular/common';
import { DocumentStatusEnum } from '../../models/enums/documentStatusEnum';
import { FormGroup } from "@angular/forms";
import {
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  ExcelRow,
  ExcelStyle,
  GetContextMenuItems,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ProcessCellForExportParams,
  ProcessHeaderForExportParams,
  ValueFormatterParams
} from "ag-grid-community";
import { FilterOption } from "../../models/gridFilter";
import { CellDataType } from "../../models/enums/agGridEnums";
import { dateComparator } from "../components/common/grid/functions/date-comparator";
import { convertEnumToFilterOption } from "../helpers/enum-to-filter-option.helper";
import { AsicStatus } from "../../models/enums/asicStatus";
import { Router } from "@angular/router";
import { uniqueArrayToFilterOptionHelper } from "../helpers/unique-array-to-filter-option.helper";
import { formTypesList } from "../constants/forms";
import {
  AgDocumentStatusComponent
} from "../components/common/grid/components/ag-document-status/ag-document-status.component";
import { EntityType } from '../../models/enums/entityType';
import {
  AgDocumentNameComponent
} from "../components/common/grid/components/ag-document-name/ag-document-name.component";
import {
  CompanyMistakeCorrection,
  CompanyMistakeCorrectionType
} from "../modals/documents/asic-forms/form492-request-correction/request-correction-change.model";
import { ModalFormsService } from "../../services/modal-forms.service";
import { Company } from "../../models/company";
import {
  Form492RequestCorrectionComponent
} from "../modals/documents/asic-forms/form492-request-correction/form492-request-correction.component";
import { DocumentStepEnum } from '../../models/enums/documentStepEnum';
import { documentStatusToStep } from "../helpers/document-status-to-step";
import { GridComponent } from "../components/common/grid/components/grid/grid.component";
import { NotesComponent } from "../components/notes/notes.component";
import { catchError, EMPTY, of, switchMap, tap } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ToastrService } from "ngx-toastr";
import { downloadBase64File } from "../../functions/download-base64-file";
import { FilesService } from "../../services/files.service";
import { DocumentSubFolderName } from "../../models/enums/documentFolderNameEnum";
import { ButtonComponent } from "../components/common/button/button.component";
import { HasRoleDirective } from '../../directives/has-role.directive';
import { SigningStatus } from '../../models/enums/annualStatementEnums';
import { ESignService } from '../../services/e-sign.service';
import { MarkAsSignedComponent } from '../modals/mark-as-signed/mark-as-signed.component';
import { MarkAsLodgedComponent } from '../modals/mark-as-lodged/mark-as-lodged.component';
import { AuthService } from "../../services/auth.service";
import { ExportDocumentsListComponent } from '../modals/export-documents-list/export-documents-list.component';
import { deepClone } from 'fast-json-patch';
import { ColumnWithExportName } from '../../models/columnWithExportName';
import { ExportTypeEnum } from '../../models/enums/exportTypeEnum';
import { excelReportTitleStyles, excelTitleForReport } from '../helpers/excel-title-for-report';
import { AgDocumentSigningStatusComponent } from '../components/common/grid/components/ag-document-signing-status/ag-document-signing-status.component';
import { AgCompanyDebtComponent } from '../components/common/grid/components/ag-company-debt/ag-company-debt.component';
import { OrganisationService } from '../../services/organisation.service';
import { DocumentCommonService } from '../../services/documents.common.service';

@Component({
  selector: 'app-documents',
  standalone: true,
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
  imports: [
    GridComponent,
    NotesComponent,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    HasRoleDirective
  ],
  providers: [DocumentStatusPipe],
})
export class DocumentsComponent implements OnInit {
  private modalService = inject(NgbModal);
  private documentsService = inject(DocumentsService);
  private documentCommonService = inject(DocumentCommonService);
  private filesService = inject(FilesService);
  private documentStatusPipe = inject(DocumentStatusPipe);
  private modalFormsService = inject(ModalFormsService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private toastr = inject(ToastrService);
  private authService = inject(AuthService);
  private eSignService = inject(ESignService);
  private organisationService = inject(OrganisationService);


  form!: FormGroup;
  gridApi!: GridApi;
  colDefs: ColDef[] = [];
  excelExportParams = this.exportParams() as ExcelExportParams;
  csvExportParams = this.exportParams() as CsvExportParams;
  excelStyles: ExcelStyle[] = excelReportTitleStyles;

  readonly pageTitle = 'Documents';
  readonly serverRowModelType: 'serverSide' | 'infinite' | 'clientSide' = 'serverSide';
  readonly defaultPageSize = 20;
  readonly cacheBlockSize = 20
  readonly EntityType = EntityType;
  DocumentStepEnum = DocumentStepEnum;
  ExportTypeEnum = ExportTypeEnum;

  documentRecords: DocumentRecord[] = [];
  selectedDocuments: DocumentRecord[] = [];
  profileUserNameList: string[] = this.organisationService.getCachedProfileUsers().map(u => u?.fullName);
  accountManagerFilterOptions: FilterOption[] = uniqueArrayToFilterOptionHelper(this.profileUserNameList);
  documentStatusFilterOptions: FilterOption[] = convertEnumToFilterOption(DocumentStatusEnum);
  signingStatusFilterOptions: FilterOption[] = convertEnumToFilterOption(SigningStatus);
  documentAsicStatusFilterOptions: FilterOption[] = convertEnumToFilterOption(AsicStatus);
  documentSigningStatusFilterOptions: FilterOption[] = convertEnumToFilterOption(SigningStatus);
  documentFormTypeFilterOptions: FilterOption[] = uniqueArrayToFilterOptionHelper(formTypesList).map(o => {
    o.label = o.label.replace('C:', '');
    return o;
  });
  private readonly documentTypePipe = new DocumentTypePipe();
  totalRecords = 0;
  private numberColumnForExport = 0;

  notesVisible = false;
  entityForNotes: Entity | undefined | null = null;
  documentCanBeOpened = false;

  ngOnInit(): void {
    this.documentCanBeOpened = this.authService.hasRole(['Admin', 'Manager', 'Preparer']);
    this.setGridConfig();
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Company Name',
        field: 'documentName',
        suppressMovable: true,
        flex: 2,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellDataType: CellDataType.TEXT,
        cellRenderer: AgDocumentNameComponent,
        cellRendererParams: { documentCanBeOpened: this.documentCanBeOpened },
        menuTabs: [],
        filterParams: {}
      },
      {
        headerName: 'Form',
        field: 'type',
        flex: 1,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.documentFormTypeFilterOptions,
          values: formTypesList
        },
        valueFormatter: (params: ValueFormatterParams<DocumentRecord, string>) => this.documentTypePipe.transform(params.data?.type),
        menuTabs: []
      },
      {
        headerName: 'Description',
        field: 'description',
        flex: 3,
        menuTabs: []
      },
      {
        headerName: 'Document Status',
        field: 'status',
        flex: 1,
        cellRenderer: AgDocumentStatusComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.documentStatusFilterOptions,
          values: Object.values(DocumentStatusEnum).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: []
      },
      {
        headerName: 'ASIC Status',
        field: 'asicStatus',
        flex: 1,
        cellRenderer: AgAsicDocumentStatusComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.documentAsicStatusFilterOptions,
          values: Object.values(AsicStatus).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: []
      },
      {
        headerName: 'Late Fee',
        field: 'lateFee',
        flex: 1,
        sortable: false,
        filter: 'agNumberColumnFilter',
        cellRenderer: AgCompanyDebtComponent,
        cellDataType: CellDataType.NUMBER,
        menuTabs: [],
      },
      {
        headerName: 'Late Fee',
        field: 'hasLateFee',
        suppressColumnsToolPanel: true,
        lockVisible: true,
        sortable: false,
        hide: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: [
            { label: 'Yes', value: 'true', active: false },
            { label: 'No', value: 'false', active: false }
          ],
          values: [true, false]
        },
        cellRendererParams: { allowEdit: false },
        menuTabs: [],
      },
      {
        headerName: 'Signing Status',
        field: 'signingStatus',
        cellRenderer: AgDocumentSigningStatusComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.signingStatusFilterOptions,
          values: Object.values(SigningStatus).filter((v) => !isNaN(Number(v))),
        },
        flex: 1,
        hide: true,
        menuTabs: []
      },
      {
        headerName: 'Date Signed',
        field: 'dateSigned',
        flex: 1,
        cellDataType: CellDataType.DATE,
        valueFormatter: (params: ValueFormatterParams<DocumentRecord, Date>) => {
          return params.value ? formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US') : '';
        },
        hide: true,
        menuTabs: []
      },
      {
        headerName: 'Lodgement Deadline',
        field: 'lodgementDeadline',
        flex: 1,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateComparator,
        },
        valueFormatter: (params: ValueFormatterParams<DocumentRecord, Date>) => {
          return params.value ? formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US') : '';
        },
        cellDataType: CellDataType.DATE,
        menuTabs: []
      },
      {
        headerName: 'Account Manager',
        field: 'accountManagerFullName',
        flex: 0.5,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.accountManagerFilterOptions,
          values: this.profileUserNameList,
        },
        hide: true,
        menuTabs: [],
      },
      {
        headerName: 'Date Created',
        field: 'createdOn',
        flex: 1,
        filter: 'agDateColumnFilter',
        hide: true,
        filterParams: {
          comparator: dateComparator,
        },
        valueFormatter: (params: ValueFormatterParams<DocumentRecord, Date>) => {
          return formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US');
        },
        cellDataType: CellDataType.DATE,
        menuTabs: []
      },
      {
        headerName: 'Date Modified',
        field: 'modifiedOn',
        hide: true,
        flex: 1,
        cellDataType: CellDataType.DATE,
        menuTabs: []
      },
      {
        headerName: 'Last Editor',
        field: 'lastEditorFullName',
        flex: 0.5,
        hide: true,
        menuTabs: [],
      },
      {
        width: 120,
        sortable: false,
        cellRenderer: AgDocumentActionsComponent,
        cellRendererParams: {
          openDocument: this.openDocument.bind(this),
          openNotes: this.openNotes.bind(this),
          deleteDocument: this.deleteDocument.bind(this),
          openForm492: this.openForm492.bind(this),
          openForm902: this.openForm902.bind(this),
          downloadDocument: this.downloadDocument.bind(this),
          markAsSigned: this.markAsSigned.bind(this),
          markAsLodged: this.markAsLodged.bind(this),
        },
        suppressHeaderMenuButton: true,
        menuTabs: []
      }
    ];
  }

  mainMenuActionItems = [
    { name: 'Delete in Bulk', action: () => this.deleteDocuemntBulk() },
    { name: 'Lodge in Bulk', action: () => this.lodgeBulk() },
    { name: 'Mark as Signed in bulk', action: () => this.markAsSignedBulk() },
    { name: 'Mark as Lodged in bulk', action: () => this.markAsLodgedBulk() },
    { name: 'Send for eSign in bulk', action: () => this.bulkSendForSigning() },
    { name: 'Download in bulk', action: () => this.downloadDocumentsBulk() },
    { name: 'Download combined PDF', selectedCount: 1, action: () => this.downloadCombinedPDF() },
  ];

  getMainMenuAction() {
    const filteredBySelectedCount = this.mainMenuActionItems.filter(item => !item.selectedCount || item.selectedCount === this.selectedDocuments.length);

    return filteredBySelectedCount;
  }

  dataSource: IServerSideDatasource = this.defaultDataSource;

  get defaultDataSource(): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const request = params.request;
        this.documentsService.getDocumentList(request).subscribe({
          next: (result) => {
            this.totalRecords = result.total;
            params.success({
              rowData: result.records,
              rowCount: result.total,
            });
          },
          error: (err) => {
            console.error(err);
            params.fail();
          },
        });
      },
    };
  }

  getRowId: GetRowIdFunc = (params: GetRowIdParams<DocumentRecord>) => params.data.documentId;

  get showMerge(): boolean {
    return this.documentRecords.filter(x => x.checked && x.type == "484").length > 1;
  }

  onSearch(searchText: string): void {
    this.dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        const request = params.request;
        this.documentsService.getDocumentList(request, searchText).subscribe({
          next: result => {
            params.success({
              rowData: result.records,
              rowCount: result.total,
            });
          },
          error: (err) => {
            console.error(err);
            params.fail();
          }
        });
      }
    };
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  reloadGrid(): void {
    this.gridApi?.setServerSideDatasource(this.dataSource);
    this.gridApi.deselectAll();
  }

  selectDocuments(documents: DocumentRecord[]): void {
    this.selectedDocuments = documents;
  }

  bulkSendForSigning(): void {
    if(this.selectedDocuments.some(x => x.status !== DocumentStatusEnum.SignaturePending && x.signingStatus !== SigningStatus.ReadyToSend)) {
      this.toastr.error('Only forms with \'Ready to Send\' signing status can be sent for signing. Please review the selected options and try again');
      return;
    }
    const selectedids = this.selectedDocuments.map((x: DocumentRecord) => x.documentId);
    void this.router.navigate(['bulk-send-documents-for-signing'], { queryParams: { selectedids }});
  }

  openNotes(document: DocumentRecord): void {
    if (this.modalOpened()) return;
    this.notesVisible = true;
    this.entityForNotes = new Entity({
      entityId: document.entityId,
      name: document.documentName,
      entityNumber: document.entityNumber,
    });
  }

  exportSelectedDocumentsToXls(): void {
    this.exportDocumentsList(true, ExportTypeEnum.EXCEL);
  }

  exportDocumentsList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const componentInstance = this.modalFormsService.openModal(ExportDocumentsListComponent, {
      modalDialogClass: 'export-documents-list'
    }).componentInstance as ExportDocumentsListComponent;

    componentInstance.colDefs = deepClone(this.colDefs) as ColumnWithExportName[];
    componentInstance.numberOfDocuments = isBulkExport ? this.selectedDocuments.length : this.totalRecords;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((columnForExport: string[]) => {
      this.numberColumnForExport = columnForExport.length - 1;

      this.dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          const request = params.request;
          request.endRow = isBulkExport ? this.selectedDocuments.length : this.totalRecords;
          this.documentsService.getDocumentList(request).subscribe({
            next: result => {
              params.success({
                rowData: result.records,
                rowCount: result.total,
              });

              const fileName = 'Documents ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US');
              if (exportType === ExportTypeEnum.EXCEL) {
                const params: ExcelExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
                const exportParamsXls = this.exportParamsXls() as ExcelExportParams;
                this.gridApi.exportDataAsExcel({ ...exportParamsXls, ...params, fileName });
              }

              this.dataSource = this.defaultDataSource;
            },
            error: (err) => {
              console.error(err);
              params.fail();
            }
          });
        }
      };
    });
  }

  exportParamsXls() {
    return {
      ...this.getExportParams(),
      prependContent: this.getExcelTitleRows(this.numberColumnForExport),
    };
  }

  getExcelTitleRows: (numberColumnForExport: number) => ExcelRow[] = (numberColumnForExport) =>
    excelTitleForReport(numberColumnForExport, 'Documents Report');

  getExportParams() {
    const documentTypePipe = this.documentTypePipe;
    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const columnId = params.column.getColId();

        if(columnId === 'type') {
          return documentTypePipe.transform(value as string);
        }
        
        if(columnId === 'status' || columnId === 'asicStatus' || columnId === 'signingStatus') {
          const filterParams = params.column.getColDef()?.filterParams as { filterOptions?: FilterOption[] };
          const options: FilterOption[] = filterParams?.filterOptions ?? [];
          return options.find(o => o.value == value)?.label ?? '';
        }
        
        if(columnId === 'createdOn' || columnId === 'lodgementDeadline'
            || columnId === 'modifiedOn' || columnId === 'dateSigned') {
          return value ? formatDate(value as string ?? '', 'dd MMM yyyy', 'en-US') : "";
        }

        return value as string;
      },
      processHeaderCallback(params: ProcessHeaderForExportParams) {
        const colDef = params.column.getColDef() as ColumnWithExportName
        return colDef?.exportColumnName ?? colDef.headerName;
      },
      sheetName: 'Documents',
    }
  }

  async openDocument(document: DocumentRecord): Promise<void> {
    const step = documentStatusToStep(document.status);
    await this.router.navigate(['/document', document.documentId],  { queryParams: { step } });
  }

  private deleteDocument(document: DocumentRecord): void {
    this.documentCommonService.deleteDocument(document).pipe(
      tap((result:boolean) => {
        if(result)
          this.gridApi?.setServerSideDatasource(this.dataSource);
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }


  private deleteDocuemntBulk() {
    this.documentCommonService.deleteDocumentBulk(this.selectedDocuments).pipe(
      tap((result:boolean) => {
        if(result) {
          this.reloadGrid();
        }
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  downloadDocument(document: DocumentRecord): void {
    this.filesService.getFiles(document.documentId).pipe(
      switchMap(res => {
        if(res.final.length) {
          return this.filesService.downloadFolder(document.documentId, false, DocumentSubFolderName.Final).pipe(
            tap(res => {
              if(res) {
                const currentDate = formatDate(new Date, 'dd_MM_yyyy', 'en-US');
                const fileName = 'Documents_' + currentDate + '.zip';
                downloadBase64File(res, fileName);
                this.toastr.success("Documents have been success downloaded", "Success");
              }
            }),
            catchError((error: HttpErrorResponse) => {
              console.error(error);
              this.toastr.error("Download documents error", "Error");
              return of('ERR');
            })
          )
        }

        this.toastr.warning("No documents for download", "Warning");

        return of(res);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Download documents error", "Error");
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private exportParams() {
    const documentStatusPipe = this.documentStatusPipe;

    return {
      processCellCallback(params: ProcessCellForExportParams): string {
        const value: unknown = params.value;
        const documentData = params.node?.data as DocumentRecord;
        if (params.column.getColDef().field === 'status') {
          return documentStatusPipe.transform(documentData.status);
        } else if (params.column.getColDef().field === 'createdOn') {
          return formatDate(documentData.createdOn ?? '', 'dd MMM yyyy', 'en-US');
        }

        return value as string;
      },
      sheetName: 'Documents',
      fileName: 'Documents ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
    }
  }

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {documentId: string}}}): GetContextMenuItems {
    const items = gridApi.defaultItems.slice();

    return ([
      {
        name: 'Open Document Detail in New Tab',
        action: () => {
          const documentId = gridApi.node.data.documentId;
          const url = `/document/${documentId}`;
          window.open(url, '_blank');
        }
      },
      'separator',
      ...items,
    ]) as unknown as GetContextMenuItems
  }

  private openForm492(documentRecord: DocumentRecord): void {
    if (this.modalOpened()) return;
    const company = new Company({ entityId: documentRecord.entityId, })
    const instance = this.modalFormsService.openModalWithCompany(new CompanyMistakeCorrection(), company).componentInstance as Form492RequestCorrectionComponent;
    instance.documentId = documentRecord.documentId;
  }

  private openForm902(documentRecord: DocumentRecord): void {
    if (this.modalOpened()) return;
    const company = new Company({ entityId: documentRecord.entityId, })
    const instance = this.modalFormsService.openModalWithCompany(new CompanyMistakeCorrection({ correctionType: CompanyMistakeCorrectionType.F902 }), company).componentInstance as Form492RequestCorrectionComponent;
    instance.documentId = documentRecord.documentId;
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }

  lodge(document: DocumentRecord) {
    if(!this.isLodgeAllowed([document])) return;

    this.documentsService.lodgeDocument(document.documentId, false).subscribe({
      next: () => this.toastr.success("Document sent to lodge", "Success"),
      error: () => this.toastr.error("Error while sending document to lodge", "Error"),
    });
  }

  lodgeBulk() {
    if(!this.isLodgeAllowed(this.selectedDocuments)) return;
    const selectedIds = this.selectedDocuments.map(x => x.documentId);
    this.documentsService.lodgeDocumentBulk(selectedIds, false).subscribe({
      next: () => this.toastr.success("Documents sent to lodge", "Success"),
      error: () => this.toastr.error("Error while sending documents to lodge", "Error"),
    });
  }

  isLodgeAllowed(docuemntRecords: DocumentRecord[]): boolean{
    const notAllowedTolodgeForms = ["c:106", "c:211", "c:280", "c:281", "c:2205"];
    if(docuemntRecords.some(x => notAllowedTolodgeForms.includes(x.type))){
      this.toastr.error("Error: Forms 106, 2205, 211, 280 and 281 cannot be lodged. Please review selected options and try again.", "Error");
      return false;
    }

    if(docuemntRecords.every(x => x.status !== DocumentStatusEnum.LodgementPending && x.asicStatus !== AsicStatus.NotLodged)){
      this.toastr.error("Only forms with 'Not Lodged' ASIC status can be lodged. Please review the selected options and try again.", "Error");
      return false;
    }

    return true;
  }

  markAsSigned(docuemntRecord: DocumentRecord) {
    if(docuemntRecord.status !== DocumentStatusEnum.SignaturePending && docuemntRecord.signingStatus !== undefined
      && ![SigningStatus.Sent, SigningStatus.ReadyToSend].includes(docuemntRecord.signingStatus)) {
        this.toastr.error('Only forms with \'Ready to Send\' or \'Sent\' signing status can be marked as signed. Please review the selected options and try again');
        return;
      }

      const modalRef = this.modalService.open(MarkAsSignedComponent, { size: 'lg', centered: true  });
      (modalRef.componentInstance as MarkAsSignedComponent).earliestChangeDate = docuemntRecord.earliestChangeDate ? new Date(docuemntRecord.earliestChangeDate) : new Date();
      (modalRef.componentInstance as MarkAsSignedComponent).confirm.pipe(
        switchMap((result) => {
          if (result.lodge) {
            return this.eSignService.markAsSign([docuemntRecord.documentId], result.signingDate).pipe(
              tap(() => this.toastr.success("Document were marked as signed", "Success")),
              switchMap(() =>
                this.documentsService.lodgeDocumentBulk([docuemntRecord.documentId], false).pipe(
                  tap(() => this.toastr.success("Document were sent to lodge", "Success"))
                )
              )
            );
          } else {
            this.markAsSignedRequest([docuemntRecord.documentId], result.signingDate);
            return EMPTY; // Return an empty observable since there's no further processing
          }
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          if((error.error as string).includes('incorrect status')) {
            this.toastr.error("Document have incorrect status to mark as signed", "Error");
          } else {
            this.toastr.error("Error while mark as signed", "Error");
          }
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
  }

  markAsSignedBulk() {
    if(this.selectedDocuments.some(x => x.status !== DocumentStatusEnum.SignaturePending && x.signingStatus !== undefined
      && ![SigningStatus.Sent, SigningStatus.ReadyToSend].includes(x.signingStatus))) {
        this.toastr.error('Only forms with \'Ready to Send\' or \'Sent\' signing status can be marked as signed. Please review the selected options and try again');
        return;
      }

      const selectedIds = this.selectedDocuments.map((x: DocumentRecord) => x.documentId);
      const earliestChangeDate = this.selectedDocuments.filter(x => x.earliestChangeDate).map(x => x.earliestChangeDate).sort()[0];

      const modalRef = this.modalService.open(MarkAsSignedComponent,{ size: 'lg', centered: true  });
      (modalRef.componentInstance as MarkAsSignedComponent).earliestChangeDate =earliestChangeDate ? new Date(earliestChangeDate) : new Date();
      (modalRef.componentInstance as MarkAsSignedComponent).confirm.pipe(
        switchMap((result) => {
          if (result.lodge) {
            return this.eSignService.markAsSign(selectedIds, result.signingDate).pipe(
              tap(() => this.toastr.success("Documents were marked as signed", "Success")),
              switchMap(() =>
                this.documentsService.lodgeDocumentBulk(selectedIds, false).pipe(
                  tap(() => this.toastr.success("Documents were sent to lodge", "Success"))
                )
              )
            );
          } else {
            this.markAsSignedRequest(selectedIds, result.signingDate);
            return EMPTY; // Return an empty observable since there's no further processing
          }
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          if((error.error as string).includes('incorrect status')) {
            this.toastr.error("Documents have incorrect status to mark as signed", "Error");
          } else {
            this.toastr.error("Error while mark as signed", "Error");
          }
          return EMPTY;
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
  }

  markAsSignedRequest(documentId: string[], signedDate: string) {
    return this.eSignService.markAsSign(documentId, signedDate).pipe(
      tap(() => {
        this.reloadGrid();
        this.toastr.success("Document was mark as signed", "Success");
      }),
      catchError((error: HttpErrorResponse) => {
        if((error.error as string).includes('incorrect status')) {
          this.toastr.error("Document have incorrect status to mark as signed", "Error");
        } else {
          this.toastr.error("Error while mark as signed", "Error");
        }
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  markAsLodged(docuemntRecord: DocumentRecord){
    if(docuemntRecord.asicStatus != AsicStatus.NotLodged || docuemntRecord.status != DocumentStatusEnum.LodgementPending) {
      this.toastr.error('Only forms with \'Not Lodged\' ASIC status and \'LodgementPending\' document status can be marked as lodged. Please review the selected options and try again.');
      return;
    }

    const modalRef = this.modalService.open(MarkAsLodgedComponent, { size: 'sm', centered: true });
    (modalRef.componentInstance as MarkAsLodgedComponent).confirm.pipe(
      switchMap((number) =>
        this.documentsService.markAsLodge([docuemntRecord.documentId], number).pipe(
          tap(() => {
            this.reloadGrid();
            this.toastr.success("Document was marked as lodged", "Success");
          })
        )
      ),
      catchError(() => {
        this.toastr.error("Error while marking as lodged", "Error");
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  markAsLodgedBulk() {
    if(this.selectedDocuments.some(x => x.asicStatus != AsicStatus.NotLodged || x.status != DocumentStatusEnum.LodgementPending)) {
      this.toastr.error('Only forms with \'Not Lodged\' ASIC status and \'LodgementPending\' document status can be marked as lodged. Please review the selected options and try again.');
      return;
    }

    const selectedIds = this.selectedDocuments.map((x: DocumentRecord) => x.documentId);

    const modalRef = this.modalService.open(MarkAsLodgedComponent, { size: 'sm', centered: true });
    (modalRef.componentInstance as MarkAsLodgedComponent).confirm.pipe(
      switchMap((number) =>
        this.documentsService.markAsLodge(selectedIds, number).pipe(
          tap(() => {
            this.reloadGrid();
            this.toastr.success("Document was marked as lodged", "Success");
          })
        )
      ),
      catchError(() => {
        this.toastr.error("Error while marking as lodged", "Error");
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  protected downloadDocumentsBulk(): void {
    const selectedIds = this.selectedDocuments.map(x => x.documentId);
    this.filesService.downloadDocumentBulk(selectedIds).pipe(
      tap(res => {
        if(res) {
          const currentDate = formatDate(new Date, 'dd_MM_yyyy', 'en-US');
          const fileName = 'Documents_' + currentDate + '.zip';
          downloadBase64File(res, fileName);
          this.toastr.success("Documents have been success downloaded", "Success");
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Download Documents error", "Error");
        return of('ERR');
      })
    ).subscribe();
  }

  downloadCombinedPDF(): void {
    const selectedIds = this.selectedDocuments.map(x => x.documentId);
    if(selectedIds.length > 1) {
      this.toastr.warning("Only one document is allowed to download as combined", "Warning");
    }

    this.filesService.downloadFolder(selectedIds[0], true, DocumentSubFolderName.Final).pipe(
      tap(res => {
        if(res) {
          const currentDate = formatDate(new Date, 'dd_MM_yyyy', 'en-US');
          const fileName = 'Documents_' + currentDate + '.pdf';
          downloadBase64File(res, fileName);
          this.toastr.success("Document have been success downloaded", "Success");
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Download Document error", "Error");
        return of('ERR');
      })
    ).subscribe();
  }
}

