import { Component } from '@angular/core';
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { ReminderSendingType, ReminderSendingTypeLabelsRecord } from '../../../../../../models/enums/reminderEnums';
import { ReminderSettingsModelExtended } from '../../../../../../models/reminderSettingsData';

type ActionEvent = (data: ReminderSettingsModelExtended | undefined) => void;
type CellRendererParams = ICellRendererParams<ReminderSettingsModelExtended> & {
  edit: ActionEvent
};

@Component({
  selector: 'app-ag-reminder-sending-method-label',
  standalone: true,
  templateUrl: './ag-reminder-sending-method-label.component.html',
  styleUrl: './ag-reminder-sending-method-label.component.scss'
})
export class AgReminderSendingMethodLabelComponent implements ICellRendererAngularComp {
  readonly ReminderSendingType = ReminderSendingType;
  readonly ReminderSendingTypeLabelsRecord = ReminderSendingTypeLabelsRecord;

  params!: CellRendererParams;
  sendingType: ReminderSendingType | null = null;
  autoSendingEnable = false;

  agInit(params: CellRendererParams): void {
    this.setupParams(params);
  }

  refresh(params: CellRendererParams): boolean {
    return this.setupParams(params);
  }

  private setupParams(params: CellRendererParams): boolean {
    this.params = params;
    this.sendingType = params.data?.sendingType ?? null;
    this.autoSendingEnable = params.data?.sendAutomatically ?? false;
    return true;
  }
}
