import { DestroyRef, inject } from "@angular/core";
import { AnnualStatementsService } from "../../services/annual-statements.service";
import { ModalFormsService } from "../../services/modal-forms.service";
import {
  CsvExportParams,
  ExcelExportParams, GetContextMenuItems,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  IServerSideDatasource, IServerSideGetRowsParams,
} from "ag-grid-community";
import { ColumnWithExportName } from "../../models/columnWithExportName";
import { AnnualRecord } from "../../models/annualRecord";
import { formatDate } from "@angular/common";
import { ExportCompanyListComponent } from "../modals/export-company-list/export-company-list.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ExportTypeEnum } from "../../models/enums/exportTypeEnum";
import { AnnualStatementPageFilter, AnnualStatementStatus, SigningStatus } from "../../models/enums/annualStatementEnums";
import { AnnualViewComponent } from "../modals/annual/annual-view/annual-view.component";
import {
  AdHocPaymentRemiderComponent
} from "../modals/reminders/ad-hoc-payment-reminder/ad-hoc-payment-reminder.component";
import { Router } from "@angular/router";
import { FilterOption, FilterParams } from "../../models/gridFilter";
import { uniqueArrayToFilterOptionHelper } from "../helpers/unique-array-to-filter-option.helper";
import { OrganisationService } from "../../services/organisation.service";
import { convertEnumToFilterOption } from "../helpers/enum-to-filter-option.helper";
import { TagsService } from "../../services/tags.service";
import { deepClone } from "fast-json-patch/commonjs/core";
import { ToastrService } from "ngx-toastr";
import { catchError, EMPTY, of, switchMap, tap } from "rxjs";
import {
  AdHocSigningReminderComponent
} from "../modals/reminders/ad-hoc-signing-reminder/ad-hoc-signing-reminder.component";
import { ESignService } from "../../services/e-sign.service";
import { HttpErrorResponse } from "@angular/common/http";
import { FilesService } from "../../services/files.service";
import { DocumentSubFolderName } from "../../models/enums/documentFolderNameEnum";
import { downloadBase64File } from "../../functions/download-base64-file";
import { ConfirmComponent } from "../modals/confirm/confirm.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CompaniesService } from "../../services/companies.service";
import { RegenerateStatementModalComponent } from "../modals/regenerate-statement-modal/regenerate-statement-modal.component";
import { AuthService } from "../../services/auth.service";

export class BaseAnnualStatements {
  public annualStatementsService = inject(AnnualStatementsService);
  public eSignService = inject(ESignService);
  protected tagsService = inject(TagsService);
  protected companiesService = inject(CompaniesService);
  protected destroyRef = inject(DestroyRef);
  private filesService = inject(FilesService);
  private organisationService = inject(OrganisationService);
  private router = inject(Router);
  private toastr = inject(ToastrService);
  private modalFormsService = inject(ModalFormsService);
  private authService = inject(AuthService);
  protected modalService = inject(NgbModal);

  gridApi!: GridApi;
  colDefs: ColumnWithExportName[] = [];
  pageFilter: AnnualStatementPageFilter = 0;
  totalRows = 0;
  currentAnnualIndex = 0;
  gridOptions = {
    overlayNoRowsTemplate: '<span class="no-rows-overlay">No data available</span>',
  };
  excelExportParams = this.annualStatementsService.exportParamsXls() as ExcelExportParams;
  excelStyles = this.annualStatementsService.excelStyles;

  readonly serverRowModelType: 'serverSide' | 'infinite' | 'clientSide' = 'serverSide';
  readonly defaultPageSize = 20;
  readonly cacheBlockSize = 20
  protected readonly ExportTypeEnum = ExportTypeEnum;
  AnnualStatementPageFilter = AnnualStatementPageFilter;

  checkedAnnualStatements: AnnualRecord[] = [];
  annualRows: AnnualRecord[] = [];

  profileUserNameList: string[] = this.organisationService.getCachedProfileUsers().map(u => u?.fullName);
  accountManagerFilterOptions: FilterOption[] = uniqueArrayToFilterOptionHelper(this.profileUserNameList);
  partnerManagerFilterOptions: FilterOption[] = uniqueArrayToFilterOptionHelper(this.profileUserNameList);
  statusFilterOptions: FilterOption[] = convertEnumToFilterOption(AnnualStatementStatus);
  signingStatusFilterOption: FilterOption[] = convertEnumToFilterOption(SigningStatus);

  dataSource: IServerSideDatasource = this.getDataSource(0,'');

  getRowId: GetRowIdFunc = (params: GetRowIdParams<AnnualRecord>) => params.data.documentId;
  canBeOpened = false;

  // pages: [] - all pages
  mainMenuActionItems = [
    { name: 'Export to xlsx', pages: [], action: () => this.exportSelectedAnnualStatementsToXls() },
    //{ name: 'Send in Bulk', pages: [AnnualStatementPageFilter.ReadyToSend], action: () => this.sendInBulk() },
    { name: 'Mark as Paid in bulk', pages: [AnnualStatementPageFilter.PaymentDue, AnnualStatementPageFilter.Overdue], action: () => this.maskAsPaidBulk() },
    { name: 'Mark as Sent in bulk', pages: [AnnualStatementPageFilter.ReadyToSend], isVisible: () => this.isMarkAsSentVisible(), action: () => this.markAsSentBulk() },
    { name: 'Mark as Signed in bulk', pages: [AnnualStatementPageFilter.ReadyToSend, AnnualStatementPageFilter.Sent],isVisible: () => this.isMarkAsSignedVisible(),  action: () => this.markAsSignedBulkAction() },
    { name: 'Re-generate annual statement', pages: [AnnualStatementPageFilter.ReadyToSend, AnnualStatementPageFilter.NotReceived], action: () => this.regenerateBulkAction() },
    { name: 'Download annual statement in bulk', pages: [AnnualStatementPageFilter.ReadyToSend], action: () => this.downloadDocumentsBulk() },
    { name: 'Download combined PDF', pages: [AnnualStatementPageFilter.ReadyToSend], isVisible: () => this.isDownloadCombinePdfVisible(), action: () => this.downloadCombinedPDF() },
    { name: 'Ignore in bulk', pages: [AnnualStatementPageFilter.NotReceived], action: () => this.ignoreBulk() },
  ];

  constructor() {
    this.canBeOpened = this.authService.hasRole(['Admin', 'Manager', 'Preparer']);
  }

  getMainMenuAction(pageFilter: AnnualStatementPageFilter) {
    const filteredByPage = this.mainMenuActionItems.filter(item => item.pages.length === 0 || item.pages.includes(pageFilter));
    const selectVisible = filteredByPage.filter(item => !item.isVisible || item.isVisible());

    return selectVisible;
  }

  isDownloadCombinePdfVisible() : boolean{
    return this.checkedAnnualStatements.length === 1;
  }
  isMarkAsSentVisible() : boolean{
    return this.checkedAnnualStatements.every(x => x.status === AnnualStatementStatus.ReadyToSend);
  }
  isMarkAsSignedVisible() : boolean{
    return this.checkedAnnualStatements.every(x => x.status === AnnualStatementStatus.ReadyToSend || x.status === AnnualStatementStatus.Sent || x.isPaid);
  }

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
    this.gridApi?.addEventListener('modelUpdated', () => {
      const totalPages = (this.gridApi as unknown as { paginationProxy: { totalPages: number }}).paginationProxy.totalPages;
      if (!totalPages) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    });
  }

  getDataSource(pageFilter: AnnualStatementPageFilter, searchText: string, startRow?: number, endRow?: number): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        this.annualStatementsService.pageFilter.set(pageFilter);
        const request = params.request;
        this.annualStatementsService.getAnnualList(request, pageFilter, searchText, startRow, endRow).subscribe({
          next: result => {
            this.annualRows = result.records;
            this.totalRows = result.total;
            params.success({
              rowData: result.records,
              rowCount: result.total,
            });
          },
          error: (err) => {
            console.error(err);
            params.fail();
          }
        });
      }
    };
  }

  getSelectedFilterOptions(columnId: string): string {
    if(!this.gridApi) { return ''; }
    const filterParams = this.gridApi.getColumn(columnId)?.getColDef().filterParams as FilterParams;
    const filterOptions = filterParams?.filterOptions ?? [];
    const selectedFilterOptions = filterOptions.filter(o => o.active).map(o => o.label).join(', ');
    return selectedFilterOptions;
  }

  protected view(companyId: string): void {
    void this.router.navigate(['/company-profile', companyId]);
  }

  protected send(annualStatementId: string | null) : void {
    if(!annualStatementId) {
      this.toastr.error('Document id not found', 'Error')
      return;
    }

    const componentInstance = this.modalFormsService.openModal(AnnualViewComponent).componentInstance as AnnualViewComponent;
    componentInstance.documentId = annualStatementId;
    componentInstance.partnerManagerName = this.annualRows.find(row => row.documentId)?.partnerManagerFullName ?? '';

    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      componentInstance['forceClose'] = true;
      componentInstance.close();
    });
  }

  protected edit(documentId: string): void {
    if(!documentId) {
      this.toastr.error('Document id not found', 'Error')
      return;
    }

    void this.router.navigate(['/annual-statement', documentId]);
  }

  protected downloadDocuments(record: AnnualRecord): void {
    this.filesService.getFiles(record.documentId).pipe(
      switchMap(res => {
        if(res.final.length) {
          return this.filesService.downloadFolder(record.documentId, false, DocumentSubFolderName.Final).pipe(
            tap(res => {
              if(res) {
                const currentDate = formatDate(new Date, 'dd_MM_yyyy', 'en-US');
                const fileName = 'Annual_Statements_' + currentDate + '.zip';
                downloadBase64File(res, fileName);
                this.toastr.success("Annual Statement have been success downloaded", "Success");
              }
            }),
            catchError((error: HttpErrorResponse) => {
              console.error(error);
              this.toastr.error("Download Annual Statement error", "Error");
              return of('ERR');
            })
          )
        }

        this.toastr.warning("No documents to download", "Warning");

        return of(res);
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Download documents error", "Error");
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  protected downloadDocumentsBulk(): void {
    const selectedIds = this.checkedAnnualStatements.map(x => x.documentId);
    this.filesService.downloadDocumentBulk(selectedIds).pipe(
      tap(res => {
        if(res) {
          const currentDate = formatDate(new Date, 'dd_MM_yyyy', 'en-US');
          const fileName = 'Annual_Statements_' + currentDate + '.zip';
          downloadBase64File(res, fileName);
          this.toastr.success("Annual Statements have been success downloaded", "Success");
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Download Annual Statements error", "Error");
        return of('ERR');
      })
    ).subscribe();
  }

  downloadCombinedPDF(): void {
    const selectedIds = this.checkedAnnualStatements.map(x => x.documentId);
    if(selectedIds.length > 1) {
      this.toastr.warning("Only one document is allowed to download as combined", "Warning");
    }

    this.filesService.downloadFolder(selectedIds[0], true, DocumentSubFolderName.Final).pipe(
      tap(res => {
        if(res) {
          const currentDate = formatDate(new Date, 'dd_MM_yyyy', 'en-US');
          const fileName = 'Annual_Statements_' + currentDate + '.pdf';
          downloadBase64File(res, fileName);
          this.toastr.success("Annual Statement have been success downloaded", "Success");
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.toastr.error("Download Annual Statement error", "Error");
        return of('ERR');
      })
    ).subscribe();
  }

  protected archive(record: AnnualRecord): void {
    this.annualStatementsService.archive(record.documentId).pipe(
      tap(() => {
        this.reloadGrid();
        this.toastr.success("Annual statement is archived", "Success");
      }),
      catchError((err) => {
        this.toastr.error("Error happen.", "Error");
        console.error(err);
        return of('ERR');
      }),
    ).subscribe();
  }

  protected sendReminder(companyId: string): void {
    const annualRow = this.annualRows.filter(row => row.companyId === companyId)[0];

    this.annualStatementsService.getAnnualById(annualRow.documentId).pipe(
      switchMap(annual => {
        const componentInstance = this.modalFormsService
          .openModal(AdHocSigningReminderComponent).componentInstance as AdHocSigningReminderComponent;
        componentInstance.companyProfile = annual.company;
        componentInstance.documentSigning = annual?.documentSigning;
        componentInstance.changeAuthorisation = annual.changeAuthorisation;
        componentInstance.annualPaymentDeadline = annualRow.paymentDeadline;

        return componentInstance.sendSigningAdHocReminder;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  protected sendPaymentReminder(companyId: string): void {
    const annualRow = this.annualRows.filter(row => row.companyId === companyId)[0];

    this.annualStatementsService.getAnnualById(annualRow.documentId).pipe(
      switchMap(annual => {
        const componentInstance = this.modalFormsService
          .openModal(AdHocPaymentRemiderComponent).componentInstance as AdHocPaymentRemiderComponent;
        componentInstance.companyProfile = annual.company;
        componentInstance.documentSigning = annual?.documentSigning;
        componentInstance.changeAuthorisation = annual.changeAuthorisation;
        componentInstance.annualPaymentDeadline = annualRow.paymentDeadline;

        return componentInstance.sendPaymentAdHocReminder;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private regenerateBulkAction() {
    const modalRef = this.modalService.open(RegenerateStatementModalComponent, { size: 'sm', centered: true  });
    (modalRef.componentInstance as RegenerateStatementModalComponent).confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((action: string) => {
      if(action === 'regenerate') {
        this.regenerateFakeBulk();
       } else if(action === 'reprint') {
         this.refreshInfoBulk();
       }
    });
  }

  protected regenerate(companyId: string): void {
    this.annualStatementsService.annualRegenerate(companyId).subscribe({
      next: () => {this.reloadGrid(); this.toastr.success("Annual Statement were regenerated", "Success")},
      error: () => this.toastr.error("Error while regenerationg annual statement", "Error"),
    });

  }

  protected regenerateFakeBulk(): void {
    const selectedIds = this.checkedAnnualStatements.map(x => x.companyId);
    this.annualStatementsService.annualRegenerateBulk(selectedIds).subscribe({
      next: () => {this.reloadGrid(); this.toastr.success("Annual Statements were regenerated", "Success")},
      error: () => this.toastr.error("Error while regenerationg annual statements", "Error"),
    });

  }

  protected markAsSent(annualStatementId: string): void {
    const annualStatement = this.annualRows.find(row => row.documentId === annualStatementId);
    if(annualStatement?.status != AnnualStatementStatus.ReadyToSend) {
      this.toastr.error("Incorrect status", "Error");
      return;
    }

    this.annualStatementsService.markAsSent([annualStatementId]).pipe(
      tap(() => {
        this.reloadGrid();
         this.toastr.success("Annual statement was mark as sent", "Success");
      }),
      catchError((err) => {
        this.toastr.error("Error happen.", "Error");
        console.error(err);
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  protected markAsSentBulk(): void {
    const selectedIds = this.checkedAnnualStatements.map(x => x.documentId);

    if(this.checkedAnnualStatements.some(x => x.status !== AnnualStatementStatus.ReadyToSend)) {
      this.toastr.error("Incorrect status", "Error");
      return;
    }

    this.annualStatementsService.markAsSent(selectedIds).pipe(
      tap(() => {
        this.reloadGrid();
        this.toastr.success("Annual statements were mark as sent", "Success");
      }),
      catchError((err) => {
        this.toastr.error("Error happen.", "Error");
        console.error(err);
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  protected ignore(companyId: string): void {
    this.annualStatementsService.ignore(companyId).pipe(
      tap(() => {
        this.reloadGrid();
         this.toastr.success("Annual statement is ignored", "Success");
      }),
      catchError((err) => {
        this.toastr.error("Error happen.", "Error");
        console.error(err);
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  protected ignoreBulk(): void {
    const selectedIds = this.checkedAnnualStatements.map(x => x.companyId);
    this.annualStatementsService.ignoreBulk(selectedIds).pipe(
      tap(() => {
        this.reloadGrid();
        this.toastr.success("Annual statements were ignored", "Success");
      }),
      catchError((err) => {
        this.toastr.error("Error happen.", "Error");
        console.error(err);
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  maskAsPaidBulk (){
    const selectedIds = this.checkedAnnualStatements.map(x => x.documentId);
    this.annualStatementsService.markAsPaidBulk(selectedIds).pipe(
      tap(() => {
        this.reloadGrid();
         this.toastr.success("Annual statements were marked as paid", "Success");
      }),
      catchError((err) => {
        this.toastr.error("Error happen.", "Error");
        console.error(err);
        return of('ERR');
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  refreshCompanyDebt(companyId: string): void {
    this.companiesService.refreshDebts(companyId).subscribe({
      next: () => this.toastr.success("Request sent", "Success"),
      error: () => this.toastr.error("Error while sending request", "Error"),
    });
  }

  refreshCompanyDebtBulk(): void {
    const selectedIds = this.checkedAnnualStatements.map(x => x.companyId);
    this.companiesService.refreshDebtsBulk(selectedIds).subscribe({
      next: () => this.toastr.success("Request sent", "Success"),
      error: () => this.toastr.error("Error while sending request", "Error"),
    });
  }

  refreshInfo(companyId: string): void {
    this.companiesService.refreshInfo(companyId).subscribe({
      next: () => this.toastr.success("Request sent", "Success"),
      error: () => this.toastr.error("Error while sending request", "Error"),
    });
  }

  refreshInfoBulk(): void {
    const selectedIds = this.checkedAnnualStatements.map(x => x.companyId);
    this.companiesService.refreshInfoBulk(selectedIds).subscribe({
      next: () => this.toastr.success("Request sent", "Success"),
      error: () => this.toastr.error("Error while refreshing debt", "Error"),
    });
  }

  // sendInBulk(): void {
  // }

  protected markAsSigned(annualStatementId: string): void {
    const signedDate = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');
    this.eSignService.markAsSign([annualStatementId], signedDate).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if((error.error as string).includes('incorrect status')) {
          this.toastr.error("Document have incorrect status to mark as signed", "Error");
        } else {
          this.toastr.error("Error while mark as signed", "Error");
        }

        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.reloadGrid();
      this.toastr.success("Annual statements was mark as signed", "Success");
    });
  }

  protected markAsSignedBulkAction(): void {
    const selectedIds = this.checkedAnnualStatements.map(x => x.documentId);
    if(this.checkedAnnualStatements.some(x => x.signingStatus === SigningStatus.Sent)) {
      const modalRef = this.modalService.open(ConfirmComponent);
      (modalRef.componentInstance as ConfirmComponent).title = 'Sign warning';
      (modalRef.componentInstance as ConfirmComponent).message = 'eSigning is currently in progress.' +
        ' If you mark the annual statement as "Signed," it will prevent signatories from signing and the envelope' +
        ' from being voided. Would you like to proceed?';
      (modalRef.componentInstance as ConfirmComponent).confirmText = 'Yes';
      (modalRef.componentInstance as ConfirmComponent).closeText = 'No';
      (modalRef.componentInstance as ConfirmComponent).confirm = () => {
        this.markAsSignedBulk(selectedIds);
        return of(true);
      };
    }
    else{
      this.markAsSignedBulk(selectedIds);
    }
  }

  protected markAsSignedBulk(ids: string[]): void {
    const signedDate = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');
    this.eSignService.markAsSign(ids, signedDate).pipe(
      tap(() => {
        this.reloadGrid();
        this.toastr.success("Annual statements was mark as signed", "Success");
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        if((error.error as string).includes('incorrect status')) {
          this.toastr.error("Annual statements have incorrect status to mark as signed", "Error");
        } else {
          this.toastr.error("Error while mark as signed", "Error");
        }
        return EMPTY;
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  selectAnnualStatements(annual: AnnualRecord[]): void {
    this.checkedAnnualStatements = annual;
  }

  onSearch(searchText: string): void {
    this.dataSource = this.getDataSource(this.pageFilter, searchText);
  }

  reloadGrid(): void {
    this.gridApi?.setServerSideDatasource(this.dataSource);
    this.gridApi?.deselectAll();
  }

  exportSelectedAnnualStatementsToXls(): void {
    this.exportAnnualList(true, ExportTypeEnum.EXCEL);
  }

  exportSelectedAnnualStatementsToCsv(): void {
    this.exportAnnualList(true, ExportTypeEnum.CSV);
  }


  exportAnnualList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const previousPaginationPageSize = this.gridApi.paginationGetPageSize();
    this.gridApi.updateGridOptions({ paginationPageSize: this.totalRows });
    const componentInstance = this.modalFormsService.openModal(ExportCompanyListComponent, {
      modalDialogClass: 'export-company-list'
    }).componentInstance as ExportCompanyListComponent;

    componentInstance.title = 'Export Annual Statements';
    componentInstance.subTitle = 'annual statements selected';
    componentInstance.colDefs = deepClone(this.colDefs) as ColumnWithExportName[];
    componentInstance.numberOfCompanies = isBulkExport ? this.checkedAnnualStatements.length : this.totalRows;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      tap(() => this.gridApi.updateGridOptions({ paginationPageSize: this.totalRows })),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((columnForExport: string[]) => {
      const nameIndex = columnForExport.findIndex(field => field === 'companyName');
      if(nameIndex >= 0) {
        columnForExport.splice(nameIndex + 1, 0, 'acn');
      }

      this.annualStatementsService.numberColumnForExport.set(columnForExport.length - 1);

      const fileName = this.getSelectedFilterOptions('status')
        ? this.getSelectedFilterOptions('status') + ' Annual Statements ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US')
        : 'Annual Statements ' + formatDate(new Date(), 'dd-MM-yyyy', 'en-US');

      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        const exportParamsXls = this.annualStatementsService.exportParamsXls() as ExcelExportParams;
        this.gridApi.exportDataAsExcel({ ...exportParamsXls, ...params, fileName });
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsCsv({ ...params, fileName });
      }

      this.gridApi.updateGridOptions({ paginationPageSize: previousPaginationPageSize });
    });

    componentInstance.resetPagination.pipe(
      tap(() => this.gridApi.updateGridOptions({ paginationPageSize: previousPaginationPageSize })),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {documentId: string}}}): GetContextMenuItems {
    const items = gridApi.defaultItems.slice();

    return ([
      {
        name: 'Open Customize and Send in New Tab',
        action: () => {
          const documentId = gridApi.node.data.documentId;
          if(documentId) {
            const url = `/annual-statement/${documentId}`;
            window.open(url, '_blank');
          }
        }
      },
      'separator',
      ...items,
    ]) as unknown as GetContextMenuItems
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
