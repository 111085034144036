import { Component, inject, OnInit } from '@angular/core';
import { AlertDetailsComponent } from "../alert-details/alert-details.component";
import { ButtonComponent } from "../../components/common/button/button.component";
import { GridComponent } from "../../components/common/grid/components/grid/grid.component";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
  ValueFormatterParams
} from "ag-grid-community";
import { dateTimeComparator } from "../../components/common/grid/functions/date-time-comparator";
import { formatDate, NgClass } from "@angular/common";
import { CellDataType } from "../../../models/enums/agGridEnums";
import {
  AgAlertsTitleComponent
} from "../../components/common/grid/components/alerts/ag-alerts-title/ag-alerts-title.component";
import {
  AgAlertsCategoryComponent
} from "../../components/common/grid/components/alerts/ag-alerts-category/ag-alerts-category.component";
import {
  AgAlertsActionsComponent
} from "../../components/common/grid/components/alerts/ag-alerts-actions/ag-alerts-actions.component";
import { AlertType } from '../../../models/enums/alertEnums';
import { BaseAlerts } from "../base-alerts";
import { rightSideExpandDetails } from "../../../animations/right-side-expand-details";
import { ActivatedRoute } from "@angular/router";
import { debounceTime, filter, of, switchMap } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AlertRecord } from '../../../models/alerts/alertRecord';

@Component({
  selector: 'app-inbox-alerts',
  standalone: true,
  imports: [
    AlertDetailsComponent,
    ButtonComponent,
    GridComponent,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgClass
  ],
  templateUrl: './inbox-alerts.component.html',
  styleUrl: './inbox-alerts.component.scss',
  animations: [rightSideExpandDetails]
})
export class InboxAlertsComponent extends BaseAlerts implements OnInit {
  private route = inject(ActivatedRoute);

  readonly pageTitle = 'Inbox';

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.setGridConfig();
    this.openAlertDetails();
  }

  setGridConfig(): void {
    this.colDefs = [
      {
        headerName: 'Date',
        field: 'dateCreated',
        sort: 'desc',
        flex: 1.1,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateTimeComparator,
        },
        valueFormatter: (params: ValueFormatterParams<AlertRecord, Date>) => {
          return formatDate(params.value ?? '', 'dd MMM yyyy HH:mm', 'en-US');
        },
        cellDataType: CellDataType.DATE,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Description',
        field: 'modelDetailTitle',
        flex: 2,
        cellRenderer: AgAlertsTitleComponent,
        cellRendererParams: {
          openAlert: this.openAlert.bind(this),
        },
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Type of Alert ',
        field: 'type',
        flex: 1,
        cellRenderer: AgAlertsCategoryComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          filterOptions: this.alertTypeFilterOptions,
          values: Object.values(AlertType).filter((v) => !isNaN(Number(v))),
        },
        menuTabs: ['generalMenuTab']
      },
      {
        headerName: 'Entity name',
        field: 'entityName',
        flex: 0.5,
        hide: true,
        menuTabs: ['generalMenuTab']
      },
      {
        width: 90,
        sortable: false,
        cellRenderer: AgAlertsActionsComponent,
        cellRendererParams: {
          openAlert: this.openAlert.bind(this),
        },
        suppressHeaderMenuButton: true,
        menuTabs: []
      }
    ];
  }

  private openAlertDetails(): void {
    this.route.queryParams.pipe(
      filter(params => !!params['alertId'] && !this.$showDetails()),
      debounceTime(1000),
      switchMap(params => {
        this.removeQueryParams();
        const findIndex = this.alertRows.findIndex(row => row.id === params['alertId']);

        if(findIndex < 0) return of(params);

        this.openAlert({ alertRecord: this.alertRows[findIndex], index: findIndex });

        return of(params);
      })
    ).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private removeQueryParams(): void {
    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
  }

  override getDataSource(searchText: string): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const request = params.request;
        const inboundFilterModel = this.alertService.getInboundFilterModel();
        if (request.filterModel && Object.keys(request.filterModel)?.length) {
          request.filterModel = { ...inboundFilterModel, ...request.filterModel };
        } else {
          request.filterModel = inboundFilterModel;
        }

        this.alertService.getInboxAlertList(request, searchText).subscribe({
          next: (result) => {
            this.alertRows = result.records;
            this.totalRows = result.total;
            params.success({
              rowData: result.records,
              rowCount: result.total,
            });
            this.alertRowsLoaded$.next(true);
          },
          error: (err) => {
            console.error(err);
            params.fail();
          }
        });
      }
    }
  }
}
