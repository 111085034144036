import { Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { startWith } from 'rxjs';
import { DatepickerHelper } from '../../../custom-form-validators/date-picker-form-validators';
import { setControlDisabled } from '../../../functions/set-control-disabled';
import { Document } from '../../../models/document';
import { EntityChangeData } from '../../../models/entityChangeData';
import { CorporateHolderModel, SecurityRegistryRecord } from '../../../models/securityRegistryRecord';
import { SelectOption } from '../../../models/selectOptions';
import { IStep } from '../../../models/step';
import { SecurityService } from '../../../services/security.service';
import { companyNameValidator } from '../../../validators/compny-name.validator';
import {
  reservationNumberCustomErrors,
  reservationNumberValidator
} from '../../../validators/reservation-number.validator';
import { BaseStepperFormComponent } from '../stepper-form/base-stepper-component/base-stepper-form.component';
import { CompanyChangeName, legalElements, PaymentMethod, paymentMethodOptions } from './CompanyChangeName';
import { ACNPipe } from '../../../pipes/acnPipe';
import {
  StepperFormDescriptionComponent
} from "../stepper-form/stepper-form-description/stepper-form-description.component";
import { StepperFormComponent } from "../stepper-form/stepper-form.component";
import { YesNoControlComponent } from "../../components/common/yes-no-control-component/yes-no-control.component";
import { InputComponent } from "../../components/common/input/input.component";
import { SelectComponent } from "../../components/common/select/select.component";
import {
  AbnMaskedInputComponent
} from "../../components/common/masked-input/specific-masked-inputs/abn-masked-input/abn-masked-input.component";
import { TextareaComponent } from "../../components/common/textarea/textarea.component";
import { RadioComponent } from "../../components/common/radio/radio.component";
import { DatePickerComponent } from "../../components/common/date-picker/date-picker.component";
import {
  AuthorisedSignatoriesStepComponent, IAuthorisedSignatoriesFormGroup
} from "../documents/asic-forms/components/authorised-signatories-step/authorised-signatories-step.component";
import {
  BeneficialOwnersGroupComponent
} from "../documents/asic-forms/form-divident-statement/nbo-sings-group/beneficial-owners-group.component";
import { CheckboxComponent } from "../../components/common/checkbox/checkbox.component";
import { abnDeclarationMessage } from "../../constants/abn-declaration-message.constant";
import { ValidationErrorComponent } from "../../components/common/validation-error/validation-error.component";

export enum CompanyNameChangeStepsEnum {
  FormDescription = 0,
  NewCompanyName = 1,
  Resolution = 2,
  AuthorisedSignatories = 3,
}

export const CompanyNameChangeSteps: IStep<CompanyNameChangeStepsEnum>[] = [
  { step: CompanyNameChangeStepsEnum.FormDescription, label: 'Form Description' },
  { step: CompanyNameChangeStepsEnum.NewCompanyName, label: 'New Company Name' },
  { step: CompanyNameChangeStepsEnum.Resolution, label: 'Resolution' },
  { step: CompanyNameChangeStepsEnum.AuthorisedSignatories, label: 'Authorised Signatories' },
];

@Component({
  selector: 'app-company-name-change',
  standalone: true,
  templateUrl: './company-name-change.component.html',
  styleUrl: '../stepper-form/base-stepper-component/base-stepper-form.component.scss',
  imports: [
    ReactiveFormsModule,
    StepperFormDescriptionComponent,
    StepperFormComponent,
    YesNoControlComponent,
    InputComponent,
    SelectComponent,
    AbnMaskedInputComponent,
    TextareaComponent,
    RadioComponent,
    DatePickerComponent,
    AuthorisedSignatoriesStepComponent,
    BeneficialOwnersGroupComponent,
    CheckboxComponent,
    ValidationErrorComponent
  ]
})
export class CompanyNameChangeComponent extends BaseStepperFormComponent<CompanyNameChangeStepsEnum, CompanyChangeName> implements OnInit {
  securityService = inject(SecurityService);

  readonly reservationNumberCustomErrors = reservationNumberCustomErrors;
  readonly availableBillingOptions: SelectOption[] = paymentMethodOptions;
  readonly abnDeclarationMessage = abnDeclarationMessage;
  override readonly StepsEnum = CompanyNameChangeStepsEnum;
  override steps = CompanyNameChangeSteps;
  override currentStepIndex = 0;
  override currentStep = CompanyNameChangeStepsEnum.FormDescription;
  stepsSignal: WritableSignal<IStep<CompanyNameChangeStepsEnum>[]> = signal(CompanyNameChangeSteps);

  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  acnPipe = new ACNPipe();
  holders: SecurityRegistryRecord[] = [];
  legalElementsOptions: SelectOption[] = [];
  corporateHolders: CorporateHolderModel[] = [];
  securityRegistryRecords: SecurityRegistryRecord[] = [];

  override stepperForm = new FormGroup({
    [CompanyNameChangeStepsEnum.FormDescription]: new FormGroup({}),
    [CompanyNameChangeStepsEnum.NewCompanyName]: new FormGroup({
      useAcnAsNewCompanyName: new FormControl<boolean | null>(false, [Validators.required]),
      companyData: new FormGroup({
        newCompanyName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(3), companyNameValidator()]),
        newNameReservedWithForm410: new FormControl<boolean | null>(false, [Validators.required]),
        reservationNumber: new FormControl<string | null>(null, [Validators.required, reservationNumberValidator]),
        isProposedNameIdenticalToRegisteredBusinessName: new FormControl<boolean | null>(false, [Validators.required]),
        abnConfirmation: new FormControl<boolean | null>(false, [Validators.requiredTrue]),
        businessNameHolderAbn: new FormControl<string | null>(null),
        manualReviewRequested: new FormControl<boolean | null>(false, [Validators.required]),
        newLegalElementsIfAcnIsUsed: new FormControl<string | null>(null, [Validators.required]),
        manualProcessingReason: new FormControl<string | null>(null, [Validators.required])
      }),
      paymentMethod: new FormControl<PaymentMethod>(PaymentMethod.INV, [Validators.required]),
    }),
    [CompanyNameChangeStepsEnum.Resolution]: new FormGroup({
      resolutionText: new FormControl<string | null>(null, [Validators.required]),
      changeDate: new FormControl<Date | null>(DatepickerHelper.getToday()),
    }),
    [CompanyNameChangeStepsEnum.AuthorisedSignatories]: AuthorisedSignatoriesStepComponent.defineForm(),
  });

  constructor() {
    super();
    this.redirectAfterSubmit = true;
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.legalElementsOptions = legalElements[this.companyChangeData.isPublic ? 'public' : 'proprietary'];
    this.loadNboHolders();
    this.listenUseAcnAsNewCompanyNameChange();
    this.listenHasReservationBy410Change();
    this.listenIsProposedNameIdenticalToBusinessNameChange();
    this.listenManualReviewRequestedChange();
    this.updateFormSteps();

    this.setupChange();

    this.listenCompanyNameChanges();
    this.listenNewLegalElementsIfAcnIsUsedChanges();
  }

  override afterSubmit(changes: EntityChangeData[]) {
    const change = changes[0] as CompanyChangeName;
    this.setupChange(change);
  }

  override setupChange(change: CompanyChangeName = this.formModel): void {
    if (!this.isEdit && change === this.formModel) {
      return;
    }

    this.newCompanyNameForm.patchValue(change);
    this.companyDataForm.patchValue({
      ...change,
      newCompanyName: change.useAcnAsNewCompanyName ? '' : change.newCompanyName,
    });
    this.resolutionForm.patchValue({
      ...change,
      changeDate: DatepickerHelper.toDate(change.changeDate),
    });
    this.companyDataForm.controls.abnConfirmation.setValue(this.isEdit);
  }

  override setCurrentStep(newStepIndex: number): void {
    this.currentStep = this.stepsSignal()[newStepIndex].step;
    this.currentStepIndex = newStepIndex;

    if (this.currentStep === CompanyNameChangeStepsEnum.Resolution) {
      this.trySetSpecialResolutionText();
    }
  }

  override buildDocument(): Document | null {
    const newCompanyName = this.newCompanyNameForm.value?.useAcnAsNewCompanyName
      ? this.getCurrentAcnCompanyName()
      : this.newCompanyNameForm.controls.companyData.controls.newCompanyName.value;

    const change = new CompanyChangeName({
      ...this.newCompanyNameForm.value.companyData as Partial<CompanyChangeName>,
      ...this.newCompanyNameForm.value as Partial<CompanyChangeName>,
      ...this.resolutionForm.value as Partial<CompanyChangeName>,
      manualReviewRequested: this.newCompanyNameForm.controls.companyData.controls.manualReviewRequested.value! && this.newCompanyNameForm.controls.companyData.controls.isProposedNameIdenticalToRegisteredBusinessName.value!,
      changeDate: this.resolutionForm.value.changeDate!,
      newCompanyName: newCompanyName ?? '',
      description: newCompanyName ? 'Company name changed to ' + newCompanyName : ''
    });

    try {
      return new Document({
        changes: [change],
        entityId: this.companyChangeData?.entityId,
        type: 'c:205a',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  private listenUseAcnAsNewCompanyNameChange(): void {
    this.newCompanyNameForm.controls.useAcnAsNewCompanyName.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((useAcnAsNewCompanyName) => {
        setControlDisabled(this.companyDataForm.controls.newLegalElementsIfAcnIsUsed, useAcnAsNewCompanyName === false);

        setControlDisabled(this.companyDataForm.controls.reservationNumber, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDataForm.controls.newCompanyName, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDataForm.controls.newNameReservedWithForm410, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDataForm.controls.isProposedNameIdenticalToRegisteredBusinessName, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDataForm.controls.abnConfirmation, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDataForm.controls.businessNameHolderAbn, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDataForm.controls.manualReviewRequested, useAcnAsNewCompanyName === true);
        setControlDisabled(this.companyDataForm.controls.manualProcessingReason, useAcnAsNewCompanyName === true);

        this.companyDataForm.controls.newNameReservedWithForm410.patchValue(this.companyDataForm.value.newNameReservedWithForm410 ?? false);
        this.companyDataForm.controls.isProposedNameIdenticalToRegisteredBusinessName.patchValue(this.companyDataForm.value.isProposedNameIdenticalToRegisteredBusinessName ?? false);
        this.companyDataForm.controls.manualReviewRequested.patchValue(this.companyDataForm.value.manualReviewRequested ?? false);
      });
  }

  private listenHasReservationBy410Change(): void {
    this.companyDataForm.controls.newNameReservedWithForm410.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((hasReservationBy410) => {
        setControlDisabled(this.companyDataForm.controls.reservationNumber, hasReservationBy410 !== true);
      });
  }

  private listenIsProposedNameIdenticalToBusinessNameChange(): void {
    this.companyDataForm.controls.isProposedNameIdenticalToRegisteredBusinessName.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((isProposedNameIdenticalToBusinessName) => {
        setControlDisabled(this.companyDataForm.controls.businessNameHolderAbn, isProposedNameIdenticalToBusinessName !== true);
        setControlDisabled(this.companyDataForm.controls.abnConfirmation, isProposedNameIdenticalToBusinessName !== true);
        if (isProposedNameIdenticalToBusinessName === true) {
          this.companyDataForm.controls.manualReviewRequested.patchValue(this.companyDataForm.value.manualReviewRequested ?? false);
        } else if (isProposedNameIdenticalToBusinessName === false) {
          setControlDisabled(this.companyDataForm.controls.manualProcessingReason);
        }
      });
  }

  private listenManualReviewRequestedChange(): void {
    this.companyDataForm.controls.manualReviewRequested.valueChanges
      .pipe(startWith(this.companyDataForm.value.manualReviewRequested), takeUntilDestroyed(this.destroyRef))
      .subscribe((manualReviewRequested) => {
        setControlDisabled(this.companyDataForm.controls.manualProcessingReason, manualReviewRequested !== true);
      });
  }

  private listenCompanyNameChanges(): void {
    this.companyDataForm.controls.newCompanyName.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setSpecialResolutionText());
  }

  private listenNewLegalElementsIfAcnIsUsedChanges(): void {
    this.companyDataForm.controls.newLegalElementsIfAcnIsUsed.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setSpecialResolutionText());
  }

  private trySetSpecialResolutionText(): void {
    const isResolutionTextEmpty = !!this.resolutionForm.controls.resolutionText.value?.trim();
    const isResolutionTextEqualToPrevious = this.resolutionForm.controls.resolutionText.value === this.formModel.resolutionText;

    if (isResolutionTextEmpty || isResolutionTextEqualToPrevious) return;

    this.setSpecialResolutionText();
  }

  private setSpecialResolutionText(): void {
    const useAcnAsNewCompanyName = !!this.newCompanyNameForm.value.useAcnAsNewCompanyName;

    const newCompanyName = useAcnAsNewCompanyName
      ? this.getCurrentAcnCompanyName()
      : this.newCompanyNameForm.controls.companyData.controls.newCompanyName.value;
    const oldCompanyName = this.companyChangeData.companyName;

    const acnAsNameResolution = `The name of the company shall be changed from ${ oldCompanyName } to ${ newCompanyName }`;
    const newCompanyNameResolution = `The name of the company shall be changed from ${ oldCompanyName } to ${ newCompanyName }`;

    this.resolutionForm.controls.resolutionText.setValue(useAcnAsNewCompanyName ? acnAsNameResolution : newCompanyNameResolution);
  }

  private getCurrentAcnCompanyName(): string {
    return `A.C.N. ${ this.acnPipe.transform(this.companyChangeData.companyAcn) } ${ this.companyDataForm.controls.newLegalElementsIfAcnIsUsed.value }`
  }

  private updateFormSteps(): void {
    const steps = CompanyNameChangeSteps.filter((step) => {

      if (step.step === CompanyNameChangeStepsEnum.AuthorisedSignatories) {
        return this.corporateHolders.length;
      }

      return true;
    });

    this.stepsSignal.set(steps);
  }

  private loadNboHolders(): void {
    this.securityService.getSecurityRegistry(this.companyChangeData.entityId, true, '', false)
      .subscribe((securityHolderRecords) => {
        this.holders = securityHolderRecords.filter(record => !record.isBeneficialOwner);
        this.securityRegistryRecords = securityHolderRecords;
        this.corporateHolders = securityHolderRecords
          .flatMap((record) => record.holders.filter(h => h.$type === CorporateHolderModel.$type)) as CorporateHolderModel[];

        this.updateFormSteps();
      });
  }

  get newCompanyNameForm() {
    return this.stepperForm.controls[CompanyNameChangeStepsEnum.NewCompanyName];
  }

  get companyDataForm() {
    return this.stepperForm.controls[CompanyNameChangeStepsEnum.NewCompanyName].controls.companyData;
  }

  get resolutionForm() {
    return this.stepperForm.controls[CompanyNameChangeStepsEnum.Resolution];
  }

  get authorisedSignatoriesForm(): IAuthorisedSignatoriesFormGroup {
    return this.stepperForm.controls[CompanyNameChangeStepsEnum.AuthorisedSignatories];
  }
}
