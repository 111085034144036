import { Component } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererParams } from "ag-grid-community";
import { SignRow } from "../../../../../../../models/signDocuments";
import { SignGroup, SigningStatus } from "../../../../../../../models/enums/annualStatementEnums";
import { ApproverStatus } from "../../../../../../../models/enums/approverStatus";
import { SignatureStatus } from "../../../../../../../models/enums/signatureStatus";

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<SignRow> & {
  editInfo: ActionEvent,
  delete: ActionEvent,
  resend: ActionEvent,
};

@Component({
  selector: 'app-ag-annual-sign-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle
  ],
  templateUrl: './ag-annual-sign-actions.component.html',
  styleUrl: './ag-annual-sign-actions.component.scss'
})
export class AgAnnualSignActionsComponent {
  protected params!: CellRendererParams;
  protected groupName = '';
  protected rowIndex: number | undefined;
  protected type: SignGroup | undefined;
  protected disabled = false;
  protected status: ApproverStatus | SigningStatus | SignatureStatus | undefined;
  protected AnnualSignGroup = SignGroup;
  protected menuActions = [
    { name: 'Delete', iconClass: 'icon ic-trash mr-1', isVisible:() => this.isDeleteActionVisible(), action: () => this.delete() },
    { name: 'Re-send', iconClass: 'icon ic-send mr-1', isVisible:() => this.isResendActionVisible(), action: () => this.resend() },
    { name: 'Change sending info', iconClass: 'icon ic-send mr-1', isVisible:() => this.isChangeSendingInfoActionVisible(), action: () => this.editInfo() },
  ];


  getMenuActions() {
    return this.menuActions.filter(x => x.isVisible());
  }

  isDeleteActionVisible():boolean {
    if((this.type === SignGroup.Approver && this.status === ApproverStatus.Created) || (this.type == SignGroup.CarbonCopy && this.status != SigningStatus.Signed))
      return true;

    return false
  }

  isResendActionVisible(): boolean {
    if((this.type === SignGroup.Signatories && this.status === SigningStatus.Sent)
      || (this.type === SignGroup.Approver && this.status === ApproverStatus.WaitingForApproval))
      return true;

    return false;
  }

  isChangeSendingInfoActionVisible (): boolean {
    if((this.type === SignGroup.Signatories && this.status !== SigningStatus.Signed && this.status !== SigningStatus.ReadyToSend)
      || (this.type === SignGroup.Approver && this.status == ApproverStatus.WaitingForApproval))
      return true;

    return false;
  }

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.type = params.data?.type;
    this.status = params.data?.status;
    this.disabled = params.data?.disabled ?? false;
    if(this.params.node.rowIndex !== null && this.params.node.rowIndex !== undefined) {
      this.rowIndex = this.params.node.rowIndex;
    }
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  delete(): void {
    this.params.delete(this.rowIndex);
  }

  resend(): void {
    this.params.resend(this.rowIndex);
  }

  editInfo(): void {
    this.params.editInfo(this.rowIndex);
  }
}
