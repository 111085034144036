export enum ReminderType {
  Signing,
  Payment
}

export enum ReminderSendingType {
  Email,
  SMS,
  EmailAndSMS
}

export enum ReminderStatus {
  Pending,
  Sent,
  Canceled,
  Failed
}
export enum ReminderSettingTypeEnum{
  AnnualStatementPayment,
  CompanyDebt,
  AnnualStatementSigning,
  AsicFormSigning
}

export const ReminderSendingTypeLabelsRecord: Record<ReminderSendingType, string> = {
  [ReminderSendingType.Email]: "Email",
  [ReminderSendingType.SMS]: "SMS",
  [ReminderSendingType.EmailAndSMS]: "SMS and Email",
};
