import { Pipe, PipeTransform } from "@angular/core";
import { ReminderSettingTypeEnum } from "../../models/enums/reminderEnums";

@Pipe({
  standalone: true,
  name: 'reminderNameTypePipe'
})
export class ReminderNameTypePipe implements PipeTransform {
  transform(value: ReminderSettingTypeEnum | undefined): string {
    switch (value) {
         case ReminderSettingTypeEnum.AnnualStatementPayment:
           return 'Annual Statement Payment Reminder';
         case ReminderSettingTypeEnum.AnnualStatementSigning:
          return 'Signing Reminder for Annual Statement';
         case ReminderSettingTypeEnum.AsicFormSigning:
          return 'Signing Reminder for ASIC Forms';
         case ReminderSettingTypeEnum.CompanyDebt:
          return 'Company Debt Reminder';
          default:
        return 'Name';
       }
  }
}
