<app-common-modal-form
  header="Annature Login"
  confirmText="Login"
  closeText="Cancel"
  icon="ic-mail"
  [formGroup]="form"
  (close)="close()"
  (confirm)="confirm()"
  [isLoading]="isLoading"
>
  <div style="padding-bottom: 15px">
    <app-input label="ID"  formControlName="clientId"/>
    <app-input label="Key"  formControlName="clientKey"/>
  </div>
</app-common-modal-form>
