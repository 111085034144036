import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import {
    AgTagEntityTitleComponent
} from "../../components/common/grid/components/ag-tag-entity-title/ag-tag-entity-title.component";
import { AsyncPipe, CurrencyPipe, DatePipe, LowerCasePipe, NgClass } from "@angular/common";
import { ButtonComponent } from "../../components/common/button/button.component";
import { DividerComponent } from "../../components/common/divider/divider.component";
import { DocumentStatusPipe } from "../../../pipes/enumsPipes/documentStatusPipe";
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TagComponent } from "../../components/common/tag/tag.component";
import { EntityType } from "../../../models/enums/entityType";
import { Company } from "../../../models/company";
import { Trust } from "../../../models/trust";
import { FilesService } from "../../../services/files.service";
import {
  BehaviorSubject, catchError,
  combineLatest, debounceTime,
  filter,
  forkJoin, from,
  Observable, of,
  ReplaySubject,
  shareReplay,
  switchMap,
  tap} from "rxjs";
import { DocumentToAttach, FileCreateDto, FilesResponse } from "../../../models/files";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { downloadBase64File } from "../../../functions/download-base64-file";
import { toBase64 } from "../../../functions/to-base64";
import { DocumentStatusEnum } from '../../../models/enums/documentStatusEnum';
import { AnnualStatement } from "../../../models/annualStatement";
import { AnnualStatementStatus, AnnualStep, SigningStatus } from "../../../models/enums/annualStatementEnums";
import { SplitByCapitalLetterPipePipe } from "../../../pipes/split-by-capital-letter-pipe.pipe";
import { CheckboxComponent } from "../../components/common/checkbox/checkbox.component";
import { ModalFormsService } from "../../../services/modal-forms.service";
import { AnnualUploadFilesComponent } from "../../modals/annual/annual-upload-files/annual-upload-files.component";
import { TooltipComponent } from "../../components/common/tooltip/tooltip.component";
import { DocumentSubFolderName } from '../../../models/enums/documentFolderNameEnum';
import { CompaniesService } from "../../../services/companies.service";
import { AnnualStatementsService } from "../../../services/annual-statements.service";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { ReminderComponent } from "../../components/common/reminder/reminder.component";
import { ReminderType } from '../../../models/enums/reminderEnums';
import { RemindersSidebarComponent } from "../../components/reminders-sidebar/reminders-sidebar.component";
import { DocumentCommonService } from '../../../services/documents.common.service';

@Component({
  selector: 'app-annual-statement-sidebar',
  standalone: true,
  imports: [
    AgTagEntityTitleComponent,
    AsyncPipe,
    ButtonComponent,
    CurrencyPipe,
    DatePipe,
    DividerComponent,
    DocumentStatusPipe,
    LowerCasePipe,
    NgbDropdown,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgxSkeletonLoaderModule,
    TagComponent,
    NgClass,
    SplitByCapitalLetterPipePipe,
    CheckboxComponent,
    TooltipComponent,
    ReminderComponent,
    RemindersSidebarComponent
],
  templateUrl: './annual-statement-sidebar.component.html',
  styleUrl: './annual-statement-sidebar.component.scss'
})
export class AnnualStatementSidebarComponent implements OnInit {
  @Input() loadCompany = false;
  @Input() entityType = EntityType.Company;
  @Input() entity: Company | Trust | null = null;
  @Input() currentStepIndex: number | undefined;
  @Input() set annualStatement(value: AnnualStatement) {
    if(!value) return;
    this.annual = value;
    this.annual$.next(value)
  }
  @Output() updateAnnualStatement = new EventEmitter<boolean>();

  private modalFormsService = inject(ModalFormsService);
  private modalService = inject(NgbModal);
  private companiesService = inject(CompaniesService);
  private annualStatementsService = inject(AnnualStatementsService);
  #filesService = inject(FilesService);
  #documentCommonService = inject(DocumentCommonService);
  #toastr = inject(ToastrService);
  #destroyRef = inject(DestroyRef);
  #cdr = inject(ChangeDetectorRef);

  protected documentsToAttachInfo = 'You can attach unlodged ASIC forms to the annual statement documents.' +
    ' To do so, select the forms you want to attach by ticking the appropriate boxes.';
  protected filesToAttachInfo = 'You may upload up to 10 PDF files, including your own invoice, with a total size limit of 2 MB.';

  protected readonly ReminderType = ReminderType;
  protected readonly SigningStatus = SigningStatus;
  protected readonly DocumentSubFolderName = DocumentSubFolderName;
  public loading = false;
  protected annual: AnnualStatement | undefined;
  protected documentsToAttach: DocumentToAttach[] = [];
  private readonly annual$ = new ReplaySubject<AnnualStatement>(1);
  private readonly refreshList$ = new BehaviorSubject(false);
  protected readonly DocumentStatusEnum = DocumentStatusEnum;
  protected readonly AnnualStep = AnnualStep;
  protected readonly AnnualStatementStatus = AnnualStatementStatus;
  protected documentsList$:Observable<FilesResponse> = combineLatest([this.refreshList$, this.annual$]).pipe(
    filter(([_, annual]) => this.currentStepIndex === AnnualStep.SignDocuments),
    tap(() => { this.loading = true; this.#cdr.detectChanges(); }),
    debounceTime(2000), // temporary fix for no generated files issue
    filter(([_, annual]) => !!annual),
    switchMap(([_, annual]) => this.#filesService.getFiles(annual.documentId)),
    tap(() => this.loading = false),
    shareReplay(1),
  );

  ngOnInit(): void {
    this.loadRecentSignDocuments();
  }

  private loadRecentSignDocuments(): void {
    this.annual$.pipe(
      filter(() => this.currentStepIndex === AnnualStep.SignDocuments),
      switchMap(annual => {
        return this.companiesService.getRecentSignDocuments(annual?.entityId ?? '', annual?.documentSigning?.signingStatus)
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(documentsToAttach => {
      this.documentsToAttach = documentsToAttach.map(file => ({ ...file, selected: false }));
      this.annualStatementsService.documentsToAttach.set(this.documentsToAttach);
    });
  }

  protected downloadFile(fileName: string, subFolder: string): void {
    this.#documentCommonService.downloadFile(this.annual!.documentId, fileName, subFolder);
  }

  protected deleteFile(fileName: string): void {
    this.#documentCommonService.deleteFile(this.annual!.documentId, fileName).pipe(
      tap((result:boolean) => {
        if(result)
          this.refreshList$.next(true)
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe();
  }

  protected openUploadFileModal(subFolderName: string) {
    const componentInstance = this.modalFormsService.openModal(AnnualUploadFilesComponent, {
      size: 'lg'
    }).componentInstance as AnnualUploadFilesComponent;

    componentInstance.save.pipe(
      switchMap((files: File[]) => {
        const uploadFiles$ = files.map(file => {
          return from(toBase64(file)).pipe(
            switchMap(base64File => {
              const body: FileCreateDto = {
                fileName: file.name,
                base64FileContent: base64File,
              };
              //TODO: Replace with multiple file uploads when the API is ready
              return this.#filesService.uploadFile(this.annual!.documentId, body, subFolderName);
            })
          );
        });

        return forkJoin(uploadFiles$);
      }),
      takeUntilDestroyed(this.#destroyRef)
    ).subscribe(() => {
      this.refreshList$.next(true);
    });
  }

  protected downloadCombinedPDF(subFolder: string): void {
    this.#documentCommonService
    this.#filesService
      .downloadFolder(this.annual!.documentId, true, subFolder)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.#toastr.error("Download 'Annual Statement.pdf' error", "Error");
          return of('ERR');
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((res) => {
        if(res !== 'ERR') {
          downloadBase64File(res, `Annual Statement ${this.annual?.company.name}.pdf`);
        }
      });
  }

  protected downloadZIP(subFolder: string): void {
    this.#filesService
      .downloadFolder(this.annual!.documentId, false, subFolder)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.#toastr.error("Download 'Annual Statement.zip' error", "Error");
          return of('ERR');
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((res) => {
        if(res !== 'ERR') {
          downloadBase64File(res, "Annual Statement.zip");
        }
      });
  }

  protected selectAttachedDocument(selected: boolean, index: number): void {
    this.documentsToAttach[index].selected = selected;
    this.annualStatementsService.documentsToAttach.set(this.documentsToAttach);
  }
}
