import { Guid } from "../../app/helpers/guid.helper";
import { AlertSeverity, AlertStatus, AlertType, ResolvedStatus } from "../enums/alertEnums";

export class AlertRecord{
    id: string;
    enitityId: string | null;
    individualId: string | null;
    entityName?: string;
    status: AlertStatus | undefined;
    resolvedStatus: ResolvedStatus | undefined;
    type: AlertType | undefined;
    severity: AlertSeverity | undefined;
    dateCreated: Date;
    isReaded: boolean;
    modelDetailTitle: string;
    postponedTill?: Date;
    needAttention: boolean;
  
    constructor(data?: Partial<AlertRecord>) {
      this.id = data?.id ?? Guid.EmptyGuid;
      this.enitityId = data?.enitityId ?? null;
      this.individualId = data?.individualId ?? null;
      this.entityName = data?.entityName;
      this.status = data?.status;
      this.resolvedStatus = data?.resolvedStatus;
      this.type = data?.type;
      this.severity = data?.severity;
      this.dateCreated = data?.dateCreated ?? new Date();
      this.isReaded = data?.isReaded ?? false;
      this.modelDetailTitle = data?.modelDetailTitle ?? '';
      this.postponedTill = data?.postponedTill;
      this.needAttention = data?.needAttention ?? false;
    }
}