import { HttpErrorResponse } from "@angular/common/http";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { catchError, Observable, of, switchMap, tap, throwError } from "rxjs";
import { ConfirmComponent } from "../app/modals/confirm/confirm.component";
import { DocumentRecord } from "../models/documentRecord";
import { AsicStatus } from "../models/enums/asicStatus";
import { SigningStatus } from "../models/enums/annualStatementEnums";
import { DocumentsService } from "./documents.service";
import { FilesService } from "./files.service";
import { downloadBase64File } from "../functions/download-base64-file";

@Injectable({
  providedIn: 'root',
})
export class DocumentCommonService {
  #destroyRef = inject(DestroyRef);
  #modalService = inject(NgbModal);
  #toastr = inject(ToastrService);
  #documentsService = inject(DocumentsService);
  #filesService = inject(FilesService);

  public deleteDocument(document: DocumentRecord): Observable<boolean> {
    if(!this.isAllowedToDeleteDocuments([document])){
      this.#toastr.error("Lodged documents, or documents that were sent for lodgement cannot be deleted", "Error");
      return of(false);
    } 

    let messsage = `Are you sure you would like to delete the selected document - ${document.documentName}?`
    if(this.documentsIsSigningProcess([document])) {
      messsage += 'Deleting the document will automatically delete the eSigning envelope. Would you like to continue?';
    }

    const modalRef = this.#modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete document';
    (modalRef.componentInstance as ConfirmComponent).message = messsage;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => {
      return this.#documentsService.deleteDocumentChange(document.documentId).pipe(
        tap(() => {
          this.#toastr.success('Document deleted!', 'Success');
        }),
        takeUntilDestroyed(this.#destroyRef),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.#toastr.error("Error while deleting document", "Error");
          return of(error);
        })
      );
    };

    return modalRef.closed.pipe(
      switchMap((confirmed: boolean) => {
        if (confirmed) {
          return of(true);
        }
        return of(false);
      })
    );
  }

  public deleteDocumentBulk(documentRecords: DocumentRecord[]): Observable<boolean> {
    if(!this.isAllowedToDeleteDocuments(documentRecords)){
      this.#toastr.error("Lodged documents, or documents that were sent for lodgement cannot be deleted", "Error");
      return of(false);
    } 

    const selectedids = documentRecords.map((x: DocumentRecord) => x.documentId);

    let messsage = 'Are you sure you would like to delete the selected document?'
    if(this.documentsIsSigningProcess(documentRecords)) {
      messsage += 'Deleting the documents will automatically delete the eSigning envelope. Would you like to continue?';
    }

    const modalRef = this.#modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete document';
    (modalRef.componentInstance as ConfirmComponent).message = messsage;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => {
      return this.deleteBulk(selectedids).pipe(
          takeUntilDestroyed(this.#destroyRef),
          catchError((error: HttpErrorResponse) => {
            console.error(error);
            this.#toastr.error("Error while deleting document", "Error");
            return of(error);
          })
        )
      };

    return modalRef.closed.pipe(
      switchMap((confirmed: boolean) => {
        if (confirmed) {
          return of(true);
        }
        return of(false);
      })
    );
  }

  public downloadFile(documentId: string, fileName: string, subFolder: string): void {
    this.#filesService
      .downloadFile(documentId, fileName, subFolder)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          this.#toastr.error(`Download ${fileName} error`, "Error");
          return of('ERR');
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe((res) => {
        if(res !== 'ERR') {
          downloadBase64File(res, fileName);
        }
      });
  }

  public deleteFile(documentId: string, fileName: string): Observable<boolean> {
    const modalRef = this.#modalService.open(ConfirmComponent);
    (modalRef.componentInstance as ConfirmComponent).title = 'Delete file';
    (modalRef.componentInstance as ConfirmComponent).message = `Are you sure you want to delete file ${fileName} ?`;
    (modalRef.componentInstance as ConfirmComponent).confirmText = 'Delete';
    (modalRef.componentInstance as ConfirmComponent).confirm = () => this.#filesService.deleteFile(documentId, fileName).pipe(
      tap(() => {
        this.#toastr.success('File deleted!', 'Success');
      }),
      catchError((error: HttpErrorResponse) => {
        this.#toastr.error("Error while deleting file", "Error");
        return throwError(() => error);
      })
    );

    return modalRef.closed.pipe(
      switchMap((confirmed: boolean) => {
        if (confirmed) {
          return of(true);
        }
        return of(false);
      })
    );
  }

  private deleteBulk(selectedids: string[])
  {
    return this.#documentsService.deleteDocumentChangeBulk(selectedids).pipe(
      tap(() => {
        this.#toastr.success('Documents deleted!', 'Success');
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        this.#toastr.error("Error while deleting documents", "Error");
        return of(error);
      })
    );
  }

  public isAllowedToDeleteDocuments(documentRecords: DocumentRecord[]): boolean {
    if(documentRecords.some(x => x.asicStatus === AsicStatus.Pending
      || x.asicStatus === AsicStatus.WaitingForASIC
      || x.asicStatus === AsicStatus.Validated
      || x.asicStatus === AsicStatus.ManualProcessing
      || x.asicStatus === AsicStatus.Lodged)) {
        return false;
    }
      return true;
  }
  
  public documentsIsSigningProcess(documentRecords: DocumentRecord[]): boolean {
    if(documentRecords.some(x => x.signingStatus === SigningStatus.Sent || x.signingStatus === SigningStatus.Rejected || x.signingStatus === SigningStatus.Signed)) {
        return true;
    }
      return false;
  }
  
}