import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ShareholderRelationshipDetails } from '../../../../models/relationship';
import { finalize, forkJoin, tap } from 'rxjs';
import { SecurityService } from '../../../../services/security.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MultipleInputNameComponent } from "../multiple-input-name/multiple-input-name.component";
import { CustomFormValidators } from "../../../../custom-form-validators/custom-form-validators";
import { ButtonComponent } from "../button/button.component";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { IndividualData } from '../../../../models/individualData';
import { CorporateHolderModel } from '../../../../models/securityRegistryRecord';

@Component({
  selector: 'app-auth-signatory',
  standalone: true,
  templateUrl: './auth-signatory.component.html',
  styleUrls: ['./auth-signatory.component.scss', '../../common/input/input.component.scss'],
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    MultipleInputNameComponent,
    ValidationErrorComponent,
  ]
})
export class AuthSignatoryComponent implements OnInit {
  private securityService = inject(SecurityService);
  private toastr = inject(ToastrService);

  @Input() authSignatoriesControl = new FormControl<IndividualData[]>([], { nonNullable: true });
  @Input({ required: true }) details!: ShareholderRelationshipDetails;
  @Input({ required: true }) relationshipId!: string;
  @Input({ required: true }) holderName!: string;
  @Input() corporateHolders: CorporateHolderModel[] = [];
  @Input() isPosted: boolean = true;
  @Input() controlLike = false;
  @Input() tip = '';
  @Input() customErrors = {
    startsWithDigit: 'The name must start with a letter',
    oneLetterPartName: 'Initials aren\'t allowed'
  };

  isEdit = false;
  isLoading = false;
  allowEdit = true;

  ngOnInit(): void {
    const hasAuthorities = (this.details.authorisedSignatories?.length ?? 0) > 0;
    this.allowEdit = this.isPosted || !hasAuthorities;
    this.authSignatoriesControl.addValidators([
      Validators.required,
      CustomFormValidators.directorNameValidator,
      CustomFormValidators.directorOneLetterNameValidator
    ]);
  }

  save(): void {
    if (this.authSignatoriesControl.invalid || this.isLoading) {
      this.authSignatoriesControl.markAsTouched();
      return;
    }

    const authSignatories: IndividualData[] = this.authSignatoriesControl.value ?? [];

    this.isLoading = true;

    if(this.corporateHolders.length === 0) {
      // Update from company profile
      this.securityService.updateAuthSignatories(this.relationshipId, authSignatories)
        .pipe(finalize(() => this.isLoading = false))
        .subscribe({
          next: () => {
            this.toastr.success('Authorised signatories updated');
            this.details.authorisedSignatories = authSignatories;
            this.onDataSaved.emit(authSignatories);
            this.isEdit = false;
          },
          error: () => this.toastr.error('Failed to update authorised signatories')
        });
    } else {
      // Update all holders with the same name during document creation
      const updateObservables = this.corporateHolders
        .filter(holder => holder.name === this.holderName && holder.isPosted)
        .map(holder => this.securityService.updateAuthSignatories(holder.relationshipId, authSignatories));
  
      if (updateObservables.length > 0) {
        this.isLoading = true;
        forkJoin(updateObservables)
          .pipe(tap(() => {
            this.isLoading = false;
            this.isEdit = false;
          }))
          .subscribe({
            next: () => {
              this.updateNotPostedHolders(authSignatories);
              this.toastr.success('Authorised signatories updated');
            },
            error: () => this.toastr.error('Failed to update authorised signatories')
          });
      } else {
        this.updateNotPostedHolders(authSignatories);
        this.isEdit = false;
        this.isLoading = false;
        this.onDataSaved.emit(authSignatories);
      }
    }
  }

  updateNotPostedHolders(authSignatories: IndividualData[]): void {
    this.corporateHolders
      .filter((holder) => holder.name === this.holderName)
      .forEach(holder => {
        holder.details.authorisedSignatories = authSignatories;
      });
  }

  startEditing(): void {
    if (this.isLoading) return;
    this.authSignatoriesControl.setValue([...(this.details.authorisedSignatories ?? [])]);
    this.isEdit = true;
  }

  cancelEditing(): void {
    if (this.isLoading) return;
    this.authSignatoriesControl.setValue([...(this.details.authorisedSignatories ?? [])]);
    this.isEdit = false;
  }

  get authSignatories(): string {
    return this.details.authorisedSignatories?.length
      ? this.details.authorisedSignatories.map(signatory => signatory.fullName).join('; ')
      : '-';
  }

  @Output() onDataSaved = new EventEmitter<IndividualData[]>();
}