import { Component, Input } from '@angular/core';
import { ACNPipe } from "../../../pipes/acnPipe";
import { CompanySubtypePipe } from '../../../pipes/enumsPipes/companySpecialPurposeStatusPipe';
import { CompanyStatusPipe } from "../../../pipes/enumsPipes/companyStatusPipe";
import { CompanyTypePipe } from "../../../pipes/enumsPipes/companyTypePipe";
import { DateFormatPipe } from "../../../pipes/dateFormatPipe";
import { DefaulValuePipe } from "../../../pipes/enumsPipes/defaultValuePipe";
import { AppCopyDirective } from "../../../directives/copy.directive";
import { Company } from "../../../models/company";
import { PhonePipe } from "../../../pipes/phonePipe";
import { JurisdictionPipe } from "../../../pipes/enumsPipes/jurisdictionPipe";
import { TitleCasePipe } from "@angular/common";

@Component({
  selector: 'app-details',
  standalone: true,
  templateUrl: './details.component.html',
  styleUrl: './details.component.scss',
  imports: [
    ACNPipe,
    CompanyStatusPipe,
    CompanyTypePipe,
    DateFormatPipe,
    DefaulValuePipe,
    AppCopyDirective,
    CompanySubtypePipe,
    PhonePipe,
    JurisdictionPipe,
    TitleCasePipe,
  ]
})
export class DetailsComponent {
  @Input() pendingButtonState = true;
  @Input() companyProfile!: Company;
  @Input() companyProfilePrevious!: Company;
  @Input() companyProfileDifference: Partial<Company> | undefined;
  @Input() isDifferenceBetweenCompany = false;
}
