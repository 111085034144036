import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { RemindersService } from "../../../../../../services/reminders.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CommonModalFormComponent } from "../../../../../modals/common-modal-form/common-modal-form.component";
import { SelectOption } from "../../../../../../models/selectOptions";
import { CheckboxComponent } from "../../../../../components/common/checkbox/checkbox.component";
import { DatepickerHelper } from "../../../../../../custom-form-validators/date-picker-form-validators";
import { RadioComponent } from "../../../../../components/common/radio/radio.component";
import {
  MultipleInputNumbersComponent
} from "../../../../../components/common/multiple-input-numbers/multiple-input-numbers.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { setControlDisabled } from "../../../../../../functions/set-control-disabled";
import { catchError, EMPTY, finalize, startWith, tap } from "rxjs";
import { SelectComponent } from "../../../../../components/common/select/select.component";
import { ReminderSendingType } from '../../../../../../models/enums/reminderEnums';
import { ReminderSettingModel, ReminderSettingsModelExtended } from '../../../../../../models/reminderSettingsData';
import { SettingsService } from '../../../../../../services/settings.service';
import { compare } from 'fast-json-patch';
import { ToastrService } from 'ngx-toastr';
import { ReminderNameTypePipe } from '../../../../../../pipes/enumsPipes/reminderNameTypePipe';

@Component({
  selector: 'app-edit-reminder-modal',
  standalone: true,
  providers: [RemindersService],
  imports: [
    ReactiveFormsModule,
    CheckboxComponent,
    RadioComponent,
    MultipleInputNumbersComponent,
    CommonModalFormComponent,
    SelectComponent,
    ReminderNameTypePipe
  ],
  templateUrl: './edit-reminder-modal.component.html',
  styleUrl: './edit-reminder-modal.component.scss'
})
export class EditReminderModalComponent implements OnInit {
  private activeModal = inject(NgbActiveModal);
  private destroyRef = inject(DestroyRef);
  private settingsService = inject(SettingsService);
  private toastr = inject(ToastrService);

  reminderModelClone: ReminderSettingModel | null = null;

  @Input() reminder!: ReminderSettingsModelExtended;
  @Output() confirm = new EventEmitter<ReminderSettingsModelExtended>();

  readonly sendingMethodOptions: SelectOption[] = [
    { label: 'SMS', value: ReminderSendingType.SMS },
    { label: 'Email', value: ReminderSendingType.Email },
    { label: 'SMS and Email', value: ReminderSendingType.EmailAndSMS },
  ];

  readonly datesCustomErrors: Record<string, string> = {
    noEnoughItems: 'Field is empty',
    tooManyItems: 'There are too many items. The maximum quantity is []',
    someNumbersAreSmaller: 'Some dates are smaller than allowed minimum. The minimum date of days before is []',
    someNumbersAreBigger: 'The number of days cannot exceed []',
  };
  readonly datesLimits = {
    minQuantity: 1,
    minNumber: 1,
    maxNumber: 29,
  };

  readonly timeOfSendingOptions: SelectOption[] = this.getTimeOfSendingOptions();

  isLoading = false;
  form = new FormGroup({
    autoSending: new FormControl<boolean>(false),
    timeOfSending: new FormControl<string>('07:00:00', [Validators.required]),
    sendingType: new FormControl<ReminderSendingType>(ReminderSendingType.Email, [Validators.required]),
    dates: new FormControl<(string | number)[]>([], [Validators.required]),
  });

  ngOnInit(): void {
    this.listenAutoSendingChange();
    this.reminderModelClone = structuredClone(this.reminder);

    this.form.patchValue({
      autoSending: this.reminder.sendAutomatically ?? false,
      sendingType: this.reminder.sendingType as ReminderSendingType,
      dates:  this.reminder.scheduleDaysBeforeDeadline,
      timeOfSending: this.reminder.reminderTime ?? '07:00:00'
    });
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    this.isLoading = true;

    const payload: ReminderSettingModel = {
      ...this.reminder,
      scheduleDaysBeforeDeadline: (this.form.value.dates ?? []).map((num) => +num),
      sendingType: this.form.value.sendingType!,
      sendAutomatically: this.form.controls.autoSending.value!,
      reminderTime: this.form.value.timeOfSending!,
    };

    const remindersPatch = compare(this.reminderModelClone!, payload);

    this.settingsService.patchReminderSettings(this.reminder.type, remindersPatch).pipe(
      tap(() => {
        this.toastr.success('Data saved successfully', 'Success')
        Object.assign(this.reminder, payload);
        this.confirm.emit(this.reminder);
        this.activeModal.close();
      }),
      takeUntilDestroyed(this.destroyRef),
      catchError(() => {
        this.toastr.error('Error happened while saving data', 'Error');
        return EMPTY;
      }),
      finalize(() => this.isLoading = false)
    ).subscribe();
  }

  close(): void {
    this.activeModal.close();
    this.form.disable();
    this.activeModal.dismiss();
  }

  private listenAutoSendingChange(): void {
    this.form.controls.autoSending.valueChanges
      .pipe(startWith(false), takeUntilDestroyed(this.destroyRef))
      .subscribe((autoSending) => {
        setControlDisabled(this.form.controls.timeOfSending, !autoSending);
        setControlDisabled(this.form.controls.sendingType, !autoSending);
        setControlDisabled(this.form.controls.dates, !autoSending);
      });
  }

  private joinWithAnd(strings: string[]) {
    if (!strings.length) {
      return "";
    } else if (strings.length === 1) {
      return strings[0];
    } else if (strings.length === 2) {
      return strings.join(', ');
    } else {
      const lastIndex = strings.length - 1;
      return strings.slice(0, lastIndex).join(', ') + ' and ' + strings[lastIndex];
    }
  }

  private getTimeOfSendingOptions(): SelectOption[] {
    return Array.from({ length: 24 }, (_, index) => {
      const hour = index;
      const time = `${hour.toString().padStart(2, '0')}:00:00`;
  
      return {
        label: time,
        value: time,
      };
    });
  }

  get deadlineMessage(): string {
    const baseDate = new Date(2024, 2, 1);
    const numbers = (this.form.controls.dates.value ?? []).map((value) => Number(value));
    const dates = numbers.map((num) => {
      const dateToHandle = new Date(baseDate);
      const date = dateToHandle.getDate();
      dateToHandle.setDate(date - num);
      return DatepickerHelper.toString(dateToHandle);
    }).reverse();

    return `
    Your reminder(s) will be sent <b>${ numbers.join(', ') }</b> days before the due date.<br/>
    Sample deadline: <b>01/03/2024</b>.<br/>
    Reminders will be sent on: <b>${ this.joinWithAnd(dates) }</b>.`;
  }
}
