<div class="card settings-page">
  <app-page-title
    header="Reminders"
    iconClass="ic-bell"
    backButtonPath="settings"
  >
  </app-page-title>

  @if(isLoading)
  {
    <app-loader [loading]="isLoading"></app-loader>
  } @else {
    <div class="card-body">
      <app-list-grid
        [rowData]="reminders"
        [colDefs]="colDefs"
        [suppressRowClickSelection]="true"
        [suppressCellFocus]="true"
        (gridReady)="onGridReady($event)"
      />
    </div>
  }
</div>
