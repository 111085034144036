import { Address } from "../../address";
import { EntityType } from "../../enums/entityType";
import { ExternalSourceType } from "../../enums/externalEntityEnums";
import { AlertDetailBase } from "../alertDetailBase";

export class EntityAddressDifferenceAlertDetail extends AlertDetailBase {
    static override readonly $type = "EntityAddressDifferenceAlertDetail";
    entityName: string;
    entityType: EntityType | null;
    entityAddress: Address;
    externalEntityAddress: Address;
    providerType: ExternalSourceType | null;
    externalEntityId: string;
    entityId: string;

    constructor(data?: Partial<EntityAddressDifferenceAlertDetail>){
        super(data);
        this.entityName = data?.entityName ?? '';
        this.entityId = data?.entityId ?? '';
        this.providerType = data?.providerType ?? null;
        this.entityType = data?.entityType ?? null;
        this.externalEntityId = data?.externalEntityId ?? '';
        this.entityAddress = new Address(data?.entityAddress) ?? new Address();
        this.externalEntityAddress = new Address(data?.externalEntityAddress) ?? new Address();
    }

}