import { ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ValidationErrors,
  Validators
} from "@angular/forms";
import { InputComponent } from '../input/input.component';
import { InputNumberComponent } from "../input-number/input-number.component";
import { OnInit } from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { SecurityService } from '../../../../services/security.service';
import { NumbersValidators } from '../../../../validators/numbers.validators';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { LinkComponent } from '../link/link.component';
import { ClientSecurityTransaction } from '../../../../models/clientSecurityTransaction';

@Component({
  selector: 'input-certificate-number',
  standalone: true,
  imports: [
    FormsModule,
    InputNumberComponent,
    FormSwitchButtonComponent,
    CommonModule,
    NgClass,
    LinkComponent,
    ValidationErrorComponent,
],
  templateUrl: '../input-number/input-number.component.html',
  styleUrl: `../input/input.component.scss`,
})
export class InputCertificateNumberComponent extends InputComponent implements OnInit  {
  securityService = inject(SecurityService);
  cdr = inject(ChangeDetectorRef);
    
  @Input() public entityId!: string;
  @Input() override customErrors: Record<string, string> = {
    ...NumbersValidators.numbersValidatorErrors,
    invalidCertificateNumber: 'This certificate number already exists in the Share Register.'
  };
  @Input() min: number | null = 1;
  @Input() max: number | null = null;
  @Input() transactionsList: ClientSecurityTransaction[] = [];

  ngOnInit() {
    if (this.ngControl.control) {
      this.ngControl.control.addValidators(
        [
          NumbersValidators.isNumber,
          Validators.required,
          this.CertificateNumberValidator.bind(this)
        ]);
    }

    const maxNumber = this.transactionsList.reduce((max, share) => Math.max(max, Number(share.shareCertificateNumber) || 0), 0)
    this.value = "" + (maxNumber + 1);
  }

  CertificateNumberValidator(control: AbstractControl): ValidationErrors | null {
    const number = control.value as string ?? '';
    const containsNumber = this.transactionsList.some(share => share.shareCertificateNumber == number);
    return containsNumber ? { invalidCertificateNumber: true } : null;
  }
}
