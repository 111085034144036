<div class="card-header py-0 d-flex flex-row justify-content-start align-items-center gap-24">
  <app-button
    [btnColor]="'white'"
    [text]="'Back'"
    [iconClass]="'icon ic-arrow-left'"
    (click)="profileViewChange.emit(false)"
  />
  <div class="divider"></div>
  <div class="profile-title">Shareholder Profile</div>
</div>

<div class="card-body">
  @for (holder of securityholder.holders; track $index) {
    @if ($index != 0) {
      <app-divider class="my-4"></app-divider>
    }
    <div class="info-container -info-container details">
      <div class="details-item">
        <div class="title">Shareholder</div>
        <div class="d-flex align-items-center prop">
          <i
            class="icon mr-2"
            [ngClass]="{
              'ic-my-account': holder.$type === IndividualHolderModel.$type,
              'ic-companies': holder.$type === CorporateHolderModel.$type
            }"
          ></i>

          @if (holder.$type === CorporateHolderModel.$type) {
            <app-span-with-open-icon
              [route]="(holder | as : CorporateHolderModel).entityId
                ? ['company-profile', (holder | as : CorporateHolderModel).entityId!]
                : []"
            >
              <app-entity-name-number-record
                [name]="holder.name"
                [numberFormatted]="(holder | as : CorporateHolderModel).entityData.entityNumber | acn"
              />
            </app-span-with-open-icon>
          } @else if (holder.$type === IndividualHolderModel.$type) {
            <div>
              <app-span-with-open-icon
                [route]="(holder | as : IndividualHolderModel).individualId
                  ? ['individual', (holder | as : IndividualHolderModel).individualId!]
                  : []"
              >
                <div [class.changes-hidden]="!(holder | as : IndividualHolderModel)?.pendingButtonState">
                  @if (
                    (holder | as : IndividualHolderModel)?.pendingButtonState
                    && (holder | as : IndividualHolderModel)?.previousIndividualData?.fullName
                    && (holder | as : IndividualHolderModel)?.previousIndividualData?.fullName !== holder.name
                  ) {
                    <div class="prop previous">
                      {{ (holder | as : IndividualHolderModel)?.previousIndividualData?.fullName }}
                    </div>
                    <div class="prop current">{{ holder.name }}</div>
                  } @else {
                    <div class="prop">
                      {{ holder.name | defaultValue }}
                    </div>
                  }
                </div>
              </app-span-with-open-icon>
            </div>
          }
        </div>
      </div>

      <div class="details-item">
        <div class="title">Address</div>
        <div [class.changes-hidden]="!(holder | as : IndividualHolderModel)?.pendingButtonState">
          @if (holder.$type === IndividualHolderModel.$type) {
            @if (
              (holder | as : IndividualHolderModel)?.pendingButtonState
              && (holder | as : IndividualHolderModel)?.previousIndividualData?.address?.normalizedFullAddress
              && (holder | as : IndividualHolderModel)?.previousIndividualData?.address?.normalizedFullAddress
              !== (holder | as : IndividualHolderModel)?.individualData?.address?.normalizedFullAddress
              ) {
              <div class="prop previous">
                {{ (holder | as : IndividualHolderModel)?.previousIndividualData?.address?.normalizedFullAddress | defaultValue }}
              </div>
              <div class="prop current">
                {{ (holder | as : IndividualHolderModel)?.individualData?.address?.normalizedFullAddress | defaultValue }}
              </div>
            } @else {
              <div class="prop">
                {{ (holder | as : IndividualHolderModel)?.individualData?.address?.normalizedFullAddress | defaultValue }}
              </div>
            }
          } @else if (holder.$type === CorporateHolderModel.$type) {
            {{ (holder | as : CorporateHolderModel).entityData.registeredAddress.normalizedFullAddress | defaultValue }}
          }
        </div>
      </div>

      <div class="details-item">
        <div class="title">Beneficially Owned</div>
        <div class="prop">
          <app-ag-yes-no-status [value]="holder.details.isBeneficialOwner"/>
        </div>
      </div>

      @if (!holder.details.isBeneficialOwner) {
        <div class="details-item">
          <div class="title">Beneficial Owner</div>
          <div class="prop">
            <app-beneficial-owner
              [details]="holder.details"
              [relationshipId]="holder.relationshipId"
              [showOpenIcon]="true"
              (onDataSaved)="onDataSaved.emit()"
            />
          </div>
        </div>
      }
    </div>

    @if (holder.$type == CorporateHolderModel.$type) {
      <div class="signatories">
        <div class="title">Authorised Signatories</div>
        <div class="prop">
          <app-auth-signatory
            tip="Add Authorised Signatories’ names separated by semicolon"
            [details]="holder.details"
            [holderName]="holder.name"
            [isPosted]="holder.isPosted"
            [relationshipId]="holder.relationshipId"
            (onDataSaved)="onDataSaved.emit()"
          />
        </div>
      </div>
    }
  }
</div>

<div class="card-body pt-0">
  <app-list-grid
    [rowData]="[securityholder]"
    [colDefs]="colDefs"
    [suppressRowClickSelection]="true"
    [suppressCellFocus]="true"
  />
</div>
