<app-stepper-form
  header="Transfer"
  subheader="Form 484C3"
  [steps]="steps"
  [currentStep]="currentStep"
  [currentStepIndex]="currentStepIndex"
  [isLoading]="isLoading"
  [form]="stepperForm"
>
  <div class="step-content share-transfer full-width" [formGroup]="stepperForm">
    <div class="step-content">
      <app-stepper-form-description
        formKey="484C3"
        [hidden]="currentStep !== StepsEnum.FormDescription"
      ></app-stepper-form-description>
    </div>

    <section
      class="step-section transfer-from"
      [formGroupName]="StepsEnum.TransferFrom"
      [hidden]="currentStep !== StepsEnum.TransferFrom"
    >
      <h1 class="h1-heading">Transferor (Seller)</h1>

      <div class="form-group">
        <app-date-picker
          label="Date of Transfer"
          formControlName="date"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [smallWidth]="true"
          [customErrors]="customDatepickerErrors"
        ></app-date-picker>
      </div>

      <div class="form-group">
        <app-shareholder-with-nbo-sign
          [formGroup]="transferFromForm.controls.shareholder"
          [securityRegistryRecords]="securityRegistryRecords"
        ></app-shareholder-with-nbo-sign>
      </div>

      <app-divider margin="24px 0"></app-divider>

      <div class="form-group">
        <div class="form-group share-types">
          <app-share-security-holdings-dropdown
            formControlName="securityHoldingId"
            [options]="securityRegistryRecordsHoldings"
          ></app-share-security-holdings-dropdown>
        </div>
      </div>

      <div class="share-numbers-grid">
        <div class="form-group number-of-shares-cancelled">
          <app-input-number
            label="Number of shares to be transferred"
            formControlName="numberIncrease"
            [customErrors]="totalNumberOfSharesCustomErrors"
            [min]="0"
            [max]="this.selectedShareholderShareType?.number ?? 0"
          ></app-input-number>
        </div>

        <div class="form-group total-consideration-paid">
          <app-input-number
            label="Consideration Paid"
            formControlName="considerationPaid"
            [customErrors]="totalNumberOfSharesCustomErrors"
            [min]="0"
          ></app-input-number>
        </div>

        <div class="form-group total-paid">
          <app-input-number
            label="Total Amount Paid"
            formControlName="paidIncrease"
            [customErrors]="totalNumberOfSharesCustomErrors"
            [min]="0"
            [max]="this.selectedShareholderShareType?.paid ?? 0"
          ></app-input-number>
        </div>

        <div class="form-group total-unpaid">
          <app-input-number
            label="Total Amount Unpaid"
            formControlName="unpaidIncrease"
            [customErrors]="totalNumberOfSharesCustomErrors"
            [min]="0"
            [max]="this.selectedShareholderShareType?.unpaid ?? 0"
          ></app-input-number>
        </div>
      </div>

      <div class="form-group" [hidden]="formShareNumberErrorMessageHidden">
        <app-validation-error
          [control]="transferFromForm"
          [customErrors]="customErrorsOfShareCancellationNumbers"
        ></app-validation-error>
      </div>
    </section>

    <section
      class="step-section transfer-to"
      [formGroupName]="StepsEnum.TransferTo"
      [hidden]="currentStep !== StepsEnum.TransferTo"
    >
      <h1 class="h1-heading">Transferee (Buyer)</h1>

      <div class="form-group">
        <app-select-shareholder-form
          [formGroup]="transferToForm.controls.selectShareholder"
          [securityRegistryRecords]="transferToSecurityRegistryRecords"
          [selectedIndividualShareholder]="selectedIndividualShareholder"
          [selectedCompanyShareholder]="selectedCompanyShareholder"
        ></app-select-shareholder-form>
      </div>

      <app-divider margin="24px 0"></app-divider>

      <div class="form-group">
        <app-share-security-holdings-dropdown
          formControlName="securityHoldingId"
          [options]="securityRegistryRecordsHoldings"
        ></app-share-security-holdings-dropdown>
      </div>

      <div class="form-group">
        <app-input-number
          label="Number of shares to be transferred"
          formControlName="numberOfSharesTransfered"
          [customErrors]="totalNumberOfSharesCustomErrors"
          [min]="0"
          [max]="this.selectedShareholderShareType?.number ?? 0"
        ></app-input-number>
      </div>

      <div class="form-group">
        <app-select
          label="Certificate Consolidation"
          formControlName="consolidateCertificate"
          [options]="certificateConsolidationOptions"
          [valuePrimitive]="true"
        ></app-select>
      </div>

      <div class="form-group">
        <input-certificate-number
          label="Certificate Number"
          formControlName="shareCertificateNumber"
          [entityId]="companyChangeData.entityId"
          [transactionsList]="transactionsList"
        ></input-certificate-number>
      </div>
    </section>

    <section
      class="step-section"
      [formGroupName]="StepsEnum.AuthorisedSignatories"
      [hidden]="currentStep !== StepsEnum.AuthorisedSignatories"
    >
      <app-authorised-signatories-step
        [title]="'Authorised Signatories'"
        [formGroup]="authorisedSignatoriesForm"
        [securityRegistryRecords]="authorisedSecurityRegistryRecords"
      />
    </section>
    
    <section
      class="step-section estimate w-100"
      [formGroupName]="StepsEnum.Estimate"
      [hidden]="currentStep !== StepsEnum.Estimate"
    >
      <h1 class="h1-heading">Estimate</h1>

      <app-share-estimate-table
        [isEstimateLoading]="isEstimateLoading"
        [estimatedSecurityRegistryRecords]="estimatedSecurityRegistryRecords"
      ></app-share-estimate-table>
    </section>
  </div>
</app-stepper-form>
