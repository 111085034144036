import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ReminderSettingTypeEnum } from '../../../../../../models/enums/reminderEnums';
import { ReminderNameTypePipe } from '../../../../../../pipes/enumsPipes/reminderNameTypePipe';
@Component({
  selector: 'app-ag-reminder-settings-name-component',
  standalone: true,
  imports: [ReminderNameTypePipe],
  templateUrl: './ag-reminder-settings-name-component.component.html',
  styleUrl: './ag-reminder-settings-name-component.component.scss'
})
export class AgReminderSettingsNameComponentComponent implements ICellRendererAngularComp {
  type!: ReminderSettingTypeEnum;

  agInit(params: ICellRendererParams): void {
    this.type = params.value as ReminderSettingTypeEnum;
  }

  refresh(params: ICellRendererParams): boolean {
    this.type = params.value as ReminderSettingTypeEnum;
    return true;
  }

}
