import { DestroyRef, inject, Renderer2, signal, WritableSignal } from "@angular/core";
import { ModalFormsService } from "../../services/modal-forms.service";
import { AlertsService } from "../../services/alert.service";
import {
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { Alert } from "../../models/alerts/alert";
import { FilterOption } from "../../models/gridFilter";
import { convertEnumToFilterOption } from "../helpers/enum-to-filter-option.helper";
import { AlertStatus, AlertType, ResolvedStatus } from "../../models/enums/alertEnums";
import { ExportCompanyListComponent } from "../modals/export-company-list/export-company-list.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ExportTypeEnum } from "../../models/enums/exportTypeEnum";
import { observe, Observer } from "fast-json-patch";
import { OrganisationService } from "../../services/organisation.service";
import { uniqueArrayToFilterOptionHelper } from "../helpers/unique-array-to-filter-option.helper";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { deepClone } from "fast-json-patch/commonjs/core";
import { Subject } from "rxjs";
import { AlertRecord } from "../../models/alerts/alertRecord";

export class BaseAlerts {
  private modalFormsService = inject(ModalFormsService);
  protected alertService = inject(AlertsService);
  protected organisationService = inject(OrganisationService);
  private renderer2 = inject(Renderer2);
  private toastr = inject(ToastrService);
  protected router = inject(Router);
  destroyRef: DestroyRef = inject(DestroyRef);
  protected alertsService = inject(AlertsService);

  observer!: Observer<Alert[]>;

  gridApi!: GridApi;
  colDefs: ColDef[] = [];
  totalRows = 0;
  currentAlertIndex = 0;
  gridOptions = {
    overlayNoRowsTemplate: '<span class="no-rows-overlay">No data available</span>',
  };
  excelExportParams = this.alertService.exportParamsXls() as ExcelExportParams;
  excelStyles = this.alertService.excelStyles;

  tagsList: string[] = [];
  tagsFilterOption: FilterOption[] = [];

  alertTypesAllowedForIgnoredOrPostponed = [
    AlertType.XpmSyncIssue,
    AlertType.IdentifiedExternalChanges,
    AlertType.AsicDataIssue
  ];

  postponeMenu = [
    { label: 'One day', value: 1 },
    { label: 'One week', value: 7 },
    { label: 'Two weeks', value: 14 },
    { label: 'One month', value: 30 }
  ];

  readonly serverRowModelType: 'serverSide' | 'infinite' | 'clientSide' = 'serverSide';
  readonly defaultPageSize = 20;
  readonly cacheBlockSize = 20
  readonly maxBlocksInCache = 1;
  protected readonly ExportTypeEnum = ExportTypeEnum;
  protected readonly AlertType = AlertType;
  checkedInboxAlerts: AlertRecord[] = [];
  alertRows: AlertRecord[] = [];
  $showDetails: WritableSignal<boolean> = signal(false);
  $selectedAlert = signal<Alert | null>(null);
  protected alertRowsLoaded$ = new Subject<boolean>();

  alertTypeFilterOptions: FilterOption[] = convertEnumToFilterOption(AlertType);
  alertStateFilterOptions: FilterOption[] = convertEnumToFilterOption(AlertStatus) ?? [];
  alertStatusFilterOptions: FilterOption[] = convertEnumToFilterOption(ResolvedStatus);
  requireAttentionFilterOptions: FilterOption[] = [
    { label: 'Yes', value: 'true', active: false },
    { label: 'No', value: 'false', active: false }
  ];
  profileUserNameList: string[] = this.organisationService.getCachedProfileUsers().map(u => u?.fullName);
  companyAccountManagerFilterOptions = uniqueArrayToFilterOptionHelper(this.profileUserNameList);
  companyPartnerManagerFilterOptions = uniqueArrayToFilterOptionHelper(this.profileUserNameList);

  dataSource: IServerSideDatasource = this.getDataSource('');

  getRowId: GetRowIdFunc = (params: GetRowIdParams<AlertRecord>) => params.data.id;

  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
    this.gridApi?.addEventListener('modelUpdated', () => {
      const totalPages = (this.gridApi as unknown as { paginationProxy: { totalPages: number }}).paginationProxy.totalPages;
      if (!totalPages) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    });
  }

  selectInboxAlerts(alerts: AlertRecord[]): void {
    this.checkedInboxAlerts = alerts;
  }

  onSearch(searchText: string): void {
    this.dataSource = this.getDataSource(searchText);
  }

  getDataSource(searchText: string): IServerSideDatasource {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        const request = params.request;
        this.alertService.getInboxAlertList(request, searchText).subscribe({
          next: result => {
            this.alertRows = result.records;
            this.totalRows = result.total;
            params.success({
              rowData: result.records,
              rowCount: result.total,
            });
            this.alertRowsLoaded$.next(true);
          },
          error: (err) => {
            console.error(err);
            params.fail();
          }
        });
      }
    };
  }

  bulkMarkRead(): void {
    const unreadAlerts = this.checkedInboxAlerts.filter(alert => !alert.isReaded);
    if(unreadAlerts.length) {
      const alertIds = unreadAlerts.map(alert => alert.id);
      this.observer = observe(unreadAlerts[0]);
      unreadAlerts[0].isReaded = true;

      this.bulkUpdateAlerts(alertIds, this.observer);

      if(this.router.url === '/alerts/inbox') {
        this.toastr.success('Alert(s) marked as read', 'Success');
      }
    }
  }

  bulkMarkRequireAttention() {
    const notNeedAttentionAlerts = this.checkedInboxAlerts.filter(alert => !alert.needAttention);
    if(notNeedAttentionAlerts.length) {
      const alertIds = notNeedAttentionAlerts.map(alert => alert.id);
      this.observer = observe(notNeedAttentionAlerts[0]);
      notNeedAttentionAlerts[0].needAttention = true;

      this.bulkUpdateAlerts(alertIds, this.observer);
    }
  }

  bulkArchive(): void {
    const notArchivedAlerts = this.checkedInboxAlerts.filter(alert => alert.status !== AlertStatus.Archived);
    if(notArchivedAlerts.length) {
      const alertIds = notArchivedAlerts.map(alert => alert.id);
      this.observer = observe(notArchivedAlerts[0]);
      notArchivedAlerts[0].status = AlertStatus.Archived;

      this.bulkUpdateAlerts(alertIds, this.observer);
    }
  }

  bulkIgnore(): void {
    const notIgnoredAlerts = this.checkedInboxAlerts.filter(alert => {
      return alert.status !== AlertStatus.Ignored
      && this.alertTypesAllowedForIgnoredOrPostponed.some(type => type === alert.type)
    });
    if(notIgnoredAlerts.length) {
      const alertIds = notIgnoredAlerts.map(alert => alert.id);
      this.observer = observe(notIgnoredAlerts[0]);
      notIgnoredAlerts[0].status = AlertStatus.Ignored;

      this.bulkUpdateAlerts(alertIds, this.observer);
    }
  }

  bulkPostpone(numberOfDays: number): void {
    const notPostponedAlerts = this.checkedInboxAlerts.filter(alert => {
      return alert.status !== AlertStatus.Postponed
        && this.alertTypesAllowedForIgnoredOrPostponed.some(type => type === alert.type)
    });
    if(notPostponedAlerts.length) {
      const alertIds = notPostponedAlerts.map(alert => alert.id);
      this.observer = observe(notPostponedAlerts[0]);
      notPostponedAlerts[0].status = AlertStatus.Postponed;

      const currentDate = new Date();
      const postponedDate = new Date();
      postponedDate.setDate(currentDate.getDate() + numberOfDays);
      notPostponedAlerts[0].postponedTill = postponedDate;

      this.bulkUpdateAlerts(alertIds, this.observer);
    }
  }

  bulkUpdateAlerts(alertIds: string[], observer: Observer<Alert[]>): void {
    this.alertService.bulkUpdateAlert(alertIds, observer).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(alerts => {
      if(this.router.url === '/alerts/inbox') {
        this.gridApi.refreshServerSide();
      } else {
        if(alerts.length) {
          alerts.forEach(alert => {
            this.gridApi.getRowNode(alert.id)?.setData(alert);
            this.gridApi.getRowNode(alert.id)?.setSelected(false);
            this.gridApi.refreshServerSide({ route: [alert.id] });
          });
        }
      }
    });
  }

  markAllRead(): void {
    const unreadAlerts = this.alertRows.filter(alert => !alert.isReaded);
    if(unreadAlerts.length) {
      const alertIds = unreadAlerts.map(alert => alert.id);
      this.observer = observe(unreadAlerts[0]);
      unreadAlerts[0].isReaded = true;

      this.alertService.bulkUpdateAlert(alertIds, this.observer).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(alerts => {
        if(alerts.length) {
          this.gridApi.setGridOption("serverSideDatasource", this.dataSource);
        }
      });
    }
  }

  exportSelectedAlertsToXls(): void {
    this.exportAlertsList(true, ExportTypeEnum.EXCEL);
  }

  exportAlertsList(isBulkExport: boolean, exportType: ExportTypeEnum): void {
    if (this.modalOpened()) return;
    const componentInstance = this.modalFormsService.openModal(ExportCompanyListComponent, {
      modalDialogClass: 'export-company-list'
    }).componentInstance as ExportCompanyListComponent;

    componentInstance.title = 'Export Alerts';
    componentInstance.subTitle = 'alerts selected';
    componentInstance.colDefs = deepClone(this.colDefs) as ColDef[];
    componentInstance.numberOfCompanies = isBulkExport ? this.checkedInboxAlerts.length : this.totalRows;
    componentInstance.exportType = exportType;
    componentInstance.confirm.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((columnForExport: string[]) => {
      this.alertService.numberColumnForExport.set(columnForExport.length - 1);

      if (exportType === ExportTypeEnum.EXCEL) {
        const params: ExcelExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        const exportParamsXls = this.alertService.exportParamsXls() as ExcelExportParams;
        this.gridApi.exportDataAsExcel({ ...exportParamsXls, ...params });
      } else if (exportType === ExportTypeEnum.CSV) {
        const params: CsvExportParams = isBulkExport ? { columnKeys: columnForExport, onlySelected: true } : { columnKeys: columnForExport };
        this.gridApi.exportDataAsCsv(params);
      }
    });
  }

  openAlert(data: { alertRecord: AlertRecord, index: number }) {
    this.alertService.getAlert(data.alertRecord.id).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe({
        next: alert => {
          this.$selectedAlert.set(alert);
          this.$showDetails.set(true);
          this.currentAlertIndex = data.index;
          this.renderer2.addClass(document.body.querySelector('.content-container'), 'overflow-hidden');
        },
        error: () => {
          this.toastr.error('Error happen', 'Error');
      },
      complete: () => {
        this.gridApi.refreshServerSide({ route: [data.alertRecord.id] });
      }
    });
  }

  closeDetails(): void {
    this.gridApi.refreshServerSide();
    this.$showDetails.set(false);
    this.renderer2.removeClass(document.body.querySelector('.content-container'), 'overflow-hidden');
  }

  previousAlert(): void {
    if(this.currentAlertIndex > 0) {
      this.currentAlertIndex--;
      const rowsNumber= this.gridApi.paginationGetPageSize() * this.gridApi.paginationGetCurrentPage() - 1;
      if(this.currentAlertIndex === rowsNumber) {
        this.gridApi.paginationGoToPreviousPage();
        this.updateSelectedAlert(true);
      } else {
        this.updateSelectedAlert(false);
      }
    }
  }

  nextAlert(): void {
    if(this.currentAlertIndex < this.totalRows){
      this.currentAlertIndex++;
      const rowsNumber= (this.gridApi.paginationGetPageSize() * (this.gridApi.paginationGetCurrentPage() + 1));
      if(this.currentAlertIndex === rowsNumber) {
        this.gridApi.paginationGoToNextPage();
        this.updateSelectedAlert(true);
      } else {
        this.updateSelectedAlert(false);
      }
    }
  }

  updateAlertGridRow(alert: Alert): void {
    const updatedNode = this.gridApi.getRowNode(alert.id);
    updatedNode?.setData(alert.toAlertRecord());
    if (updatedNode) {
      this.gridApi.refreshCells({
        rowNodes: [updatedNode]
      });
    }
  }

  private updateSelectedAlert(changedPage: boolean): void {
    let currentIndex = 0;
    if(this.currentAlertIndex < this.gridApi.paginationGetPageSize()) {
      currentIndex = this.currentAlertIndex;
    } else {
      const countRows = (this.gridApi.paginationGetPageSize() * this.gridApi.paginationGetCurrentPage());
      if(countRows) {
        currentIndex = this.currentAlertIndex % (this.gridApi.paginationGetPageSize() * this.gridApi.paginationGetCurrentPage());
      } else {
        currentIndex = 0;
      }
    }

    if(changedPage) {
      this.alertRowsLoaded$.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(() => {
        this.fetchAlerts(this.alertRows[currentIndex].id);
      });
    }
    else {
      const id = this.$selectedAlert()?.id ?? '';
      this.alertRows.forEach(row => {
        if(row.id === id) {
          this.fetchAlerts(this.alertRows[currentIndex].id);
      }})
    }
  }

  private fetchAlerts(alertId: string) {
    this.alertService.getAlert(alertId).subscribe({ 
      next: (alert: Alert | null) => {
        this.$selectedAlert.set(alert);
      },
      error: () => {
        this.toastr.error('Error happen', 'Error');
      }
    });
  }

  get modalOpened() {
    return this.modalFormsService.modalOpened;
  }
}
