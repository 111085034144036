import { Component, EventEmitter, inject, Output } from '@angular/core';
import { CommonModalFormComponent } from '../../../../../modals/common-modal-form/common-modal-form.component';
import { InputComponent } from '../../../../../components/common/input/input.component';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IntegrationsService } from '../../../../../../services/integrations.service';
import { AnnatureConnection } from '../../../../../../models/annatureConnection';
import { IntegrationConnectionsEnum } from '../../../../../../models/enums/IntegrationConnectionsEnum';
import { ToastrService } from 'ngx-toastr';
import { ConnectionStatusEnum } from '../../../../../../models/enums/connectionStatusEnum';

@Component({
  selector: 'app-annature-login-modal',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputComponent,
    CommonModalFormComponent,
  ],
  templateUrl: './annature-login-modal.component.html',
  styleUrl: './annature-login-modal.component.scss'
})

export class AnnatureLoginModalComponent {
  private activeModal = inject(NgbActiveModal);
  private integrationsSerivice = inject(IntegrationsService);
  private toastr = inject(ToastrService);
  isLoading = false;
  
  @Output() confirmEvent = new EventEmitter<ConnectionStatusEnum>();

  form = new FormGroup({
    clientId: new FormControl<string | null>(null, [Validators.required]),
    clientKey: new FormControl<string | null>(null, [Validators.required]),
  });


  confirm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const payload = new AnnatureConnection(this.form.value as Partial<AnnatureConnection>);

    this.isLoading = true;
    this.form.disable();

   this.integrationsSerivice.connectByKeys(IntegrationConnectionsEnum.AnnatureConnection, payload)
      .subscribe({
        next: (status) => {
          if(status === ConnectionStatusEnum.RequiredAction) {
            this.toastr.warning('Connection Success!', 'Required Action');
          }
          else{
            this.toastr.success('Connected!', 'Success');
          }

          this.confirmEvent.emit(status);
          this.activeModal.close()
        },
        error: () => {
          this.isLoading = false;
          this.form.enable();
          this.toastr.error('Connection Failed!', 'Error');
        }
      });
  }

  close(): void {
    this.activeModal.close();
  }

}
