export interface ISettingLink {
  title: string;
  icon: string;
  description: string;
  path: string[];
  disabled?: boolean;
}

export interface ISettingsLinksBlock {
  title: string;
  links: ISettingLink[];
}

export const settingsLinksBlocks: ISettingsLinksBlock[] = [
  {
    title: '',
    links: [
      {
        title: 'Organisation Details',
        description: 'Visit this page to ensure your account details in Bolt are current.',
        icon: 'ic-organisation',
        path: ['settings', 'my-organisation'],
      },
      {
        title: 'Users and Permissions',
        description: 'Are you struggling to manage user access and permissions? Take charge of your team\'s access with Bolt\'s easy-to-use access permissions.',
        icon: 'ic-accounts',
        path: ['settings', 'accounts']
      },
      {
        title: 'Agent Details',
        description: 'To ensure uninterrupted and efficient system usage, taking control of your ASIC account details is crucial.',
        icon: 'ic-asic-black-white',
        path: ['settings', 'agent-settings']
      },
      {
        title: 'Branding',
        description: 'Elevate your documents by adding your logo and a footer to create customised cover pages and improve client communication.',
        icon: 'ic-upload-file',
        path: ['settings', 'branding'],
      },
    ]
  }, {
    title: '',
    links: [
      {
        title: 'Manage Tags',
        description: "Start using tags in Bolt to filter entities with lightning speed and group them efficiently. Create Tags for your entities now.",
        icon: 'ic-tags',
        path: ['settings', 'tags'],
      },
      {
        title: 'Integrations',
        description: `Bolt has partnered with leading eSigning and practice management providers to ensure that we address all of Bolt's users' needs. Click "Integrations" to learn more.`,
        icon: 'ic-link',
        path: ['settings', 'integrations'],
      },
      {
        title: 'Document Settings',
        description: "Have the freedom to customise your ASIC forms and annual statement settings based on your firm's needs. Get started by reviewing the list.",
        icon: 'ic-settings',
        path: ['settings', 'document-settings'],
      },
      {
        title: 'Cover Letter Templates',
        description: "Make the most of Bolt's extensive document templates to personalize your client communication and create powerful connections.",
        icon: 'ic-file',
        path: ['settings', 'cover-letter-templates'],
      },
      {
        title: 'Email Settings',
        description: "Send emails to your clients directly from Bolt by customising your email settings.",
        icon: 'ic-settings',
        path: ['settings', 'email-settings'],
      },
      {
        title: 'Email Templates',
        description: "Utilise Bolt's diverse email templates to assertively personalise your communication with clients and establish a more impactful connection.",
        icon: 'ic-mail',
        path: ['settings', 'email-templates'],
      },
      {
        title: 'SMS Templates',
        description: "Enhance client engagement by customizing your SMS templates before sending reminders. Personalise the templates for a more meaningful connection with your clients.",
        icon: 'ic-message',
        path: ['settings', 'sms-templates'],
      },
      {
        title: 'Reminders',
        description: "Struggling to keep up with ASIC payment and lodgement deadlines? Take control and never miss a deadline again by using Bolt's effective reminder feature.",
        icon: 'ic-bell',
        path: ['settings', 'reminders'],
      },
    ]
  },
];

