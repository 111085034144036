import { EntityType } from "../../enums/entityType";
import { ExternalSourceType } from "../../enums/externalEntityEnums";
import { AlertDetailBase } from "../alertDetailBase";

export class EntityMissingInIntegrationAlertDetail extends AlertDetailBase {
    static override readonly $type = "EntityMissingInIntegrationAlertDetail";
    externalName: string;
    providerType: ExternalSourceType | null;
    entityType: EntityType | null;
    externalId: string;

    constructor(data?: Partial<EntityMissingInIntegrationAlertDetail>){
        super(data);
        this.providerType = data?.providerType ?? null;
        this.entityType = data?.entityType ?? null;
        this.externalId = data?.externalId ?? '';
        this.externalName = data?.externalName ?? '';
    }

}