<app-common-modal-form
  icon="icon ic-edit"
  header="Edit Document Settings"
  confirmText="Save"
  closeText="Cancel"
  [isLoading]="isLoading"
  (confirm)="confirm()"
  (close)="close()"
>
  <div class="edit-document-settings-modal-body" [formGroup]="form">
    <div class="documents-part">
      <div class="part-title">Documents</div>

      <div class="part-body">
        <app-select
          label="Receive email for eSigned documents. Select recipient(s) from the list"
          formControlName="receiveEmailsForDocs"
          [options]="NotifyWhenAllPartiesSignedDocumentOptions"
          [valuePrimitive]="true"
        />
      </div>
    </div>

    <div class="annual-statement-part">
      <div class="part-title">Annual Statement</div>

      <div class="part-body">
        <app-select
          label="Receive email for eSigned documents. Select recipient(s) from the list"
          formControlName="receiveEmailsForAS"
          [options]="NotifyWhenAllPartiesSignedDocumentOptions"
          [valuePrimitive]="true"
        />

        <app-yes-no-control
          label="Do you want to add Director Signature in Company Statement?"
          formControlName="insertDirectorSignatureInCompanyStatement"
        />
      </div>
    </div>
  </div>
</app-common-modal-form>
