import { Guid } from "../../app/helpers/guid.helper";
import { AlertStatus, AlertType, AlertSeverity, ResolvedStatus } from "../enums/alertEnums";
import { AlertDetailBase } from "./alertDetailBase";
import { EntityNameDifferenceAlertDetail } from "./alertDetails/entityNameDifferenceAlertDetail";
import { EntityType } from "../enums/entityType";
import { Entity } from "../entity";
import { Company } from "../company";
import { Trust } from "../trust";
import { Individual } from "../individual";
import { AlertRecord } from "./alertRecord";
import { EntityAddressDifferenceAlertDetail } from "./alertDetails/entityAddressDifferenceAlertDetail";
import { EntityMissingAlertDetail } from "./alertDetails/entityMissingAlertDetail";
import { EntityMissingInIntegrationAlertDetail } from "./alertDetails/entityMissingInIntegrationAlertDetail";
import { AnnualStatementAlertDetail } from "./alertDetails/annualStatementAlertDetail";

export class Alert {
    id: string;
    entityId?: string;
    entity?: Company | Trust | Entity | null;
    entityType: EntityType | null;
    organisationId: string;
    individualId?: string;
    individual?: Individual;
    hash: string;
    status: AlertStatus | undefined;
    resolvedStatus: ResolvedStatus | undefined;
    type: AlertType | undefined;
    severity: AlertSeverity | undefined;
    postponedTill?: Date;
    dateCreated: Date;
    model: AlertDetailBase;
    isReaded: boolean;
    needAttention: boolean;
    

    constructor(data: Partial<Alert> = {}) {
        this.id = data.id ?? Guid.EmptyGuid;
        this.entityId = data.entityId;
        this.entity = data.entity ?? null;
        this.entityType = data.entityType ?? null;
        this.organisationId = data.organisationId ?? "";
        this.individualId = data.individualId;
        this.individual = data.individual;
        this.hash = data.hash ?? "";
        this.status = data.status;
        this.resolvedStatus = data.resolvedStatus;
        this.type = data.type;
        this.severity = data.severity;
        this.postponedTill = data.postponedTill;
        this.dateCreated = data.dateCreated ?? new Date();
        this.model = this.createAlertDetailInstance(data.model);
        this.isReaded = data.isReaded ?? false;
        this.needAttention = data.needAttention ?? false;
    }

    createAlertDetailInstance(data): AlertDetailBase {
        //workaround for type casting
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        switch (data.$type) {
            case EntityNameDifferenceAlertDetail.$type:
                return new EntityNameDifferenceAlertDetail(data as Partial<EntityNameDifferenceAlertDetail>);
            case EntityAddressDifferenceAlertDetail.$type:
                return new EntityAddressDifferenceAlertDetail(data as Partial<EntityAddressDifferenceAlertDetail>);
            case EntityMissingAlertDetail.$type:
                return new EntityMissingAlertDetail(data as Partial<EntityMissingAlertDetail>);
            case EntityMissingInIntegrationAlertDetail.$type:
                return new EntityMissingInIntegrationAlertDetail(data as Partial<EntityMissingInIntegrationAlertDetail>);
            case AnnualStatementAlertDetail.$type:
                return new AnnualStatementAlertDetail(data as Partial<AnnualStatementAlertDetail>);
            default:
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                throw new Error(`Unknown change data type: ${data.$type}`);
        }
    }

    public toAlertRecord() {
        return new AlertRecord({
            id: this.id,
            enitityId: this.entityId,
            individualId: this.individualId,
            entityName: this.entity?.name,
            status: this.status,
            resolvedStatus: this.resolvedStatus,
            type: this.type,
            severity: this.severity,
            dateCreated: this.dateCreated,
            isReaded: this.isReaded,
            modelDetailTitle: this.model.title,
            postponedTill: this.postponedTill,
            needAttention: this.needAttention
        });
    }
}

