<div class="card-body" [class.changes-hidden]="!pendingButtonState">
  <div class="officeholder-contact-container">
    <div class="form-group">
      <div class="title">Company name</div>
      @if(companyProfilePrevious.name !== companyProfile.name) {
        <span class="property previous" appCopy>{{companyProfilePrevious.name | defaultValue}}</span>
        <span class="property current" appCopy>{{companyProfile.name | defaultValue}}</span>
      } @else {
        <span class="property" appCopy>{{companyProfilePrevious.name | defaultValue}}</span>
      }
    </div>

    <div class="form-group">
      <div class="title">Company type</div>
      @if(companyProfileDifference?.companyType !== undefined || companyProfileDifference?.companySubtype !== undefined ) {
        <div class="property previous" appCopy>
          <span class="property previous d-inline">{{companyProfilePrevious.companyType | companyType}}</span>
          <span class="mr-3"> - </span>
          <span class="property previous d-inline">{{companyProfilePrevious.companySubtype | companySubtype}}</span>
        </div>
        <div class="property current" appCopy>
          <span class="property current d-inline">{{companyProfile.companyType | companyType}}</span>
          <span class="mr-3"> - </span>
          <span class="property current d-inline">{{companyProfile.companySubtype | companySubtype}}</span>
        </div>
      } @else {
        <div class="property" appCopy>
          <span class="property d-inline">{{companyProfilePrevious.companyType | companyType}}</span>
          <span class="mr-3"> - </span>
          <span class="property d-inline">{{companyProfilePrevious.companySubtype | companySubtype}}</span>
        </div>
      }
    </div>
  </div>

  <div class="officeholder-info-container">
    <div class="form-group">
      <div class="title">ACN</div>
      @if(companyProfilePrevious.acn !== companyProfile.acn) {
        <div class="property previous" appCopy>{{companyProfilePrevious.acn | acn}}</div>
        <div class="property current" appCopy>{{companyProfile.acn | acn}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.acn | acn}}</div>
      }
    </div>

    <div class="form-group">
      <div class="title">Company status</div>
      @if(companyProfilePrevious.status !== companyProfile.status) {
        <div class="property previous" appCopy>{{companyProfilePrevious.status | companyStatus | titlecase}}</div>
        <div class="property current" appCopy>{{companyProfile.status | companyStatus | titlecase}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.status | companyStatus | titlecase}}</div>
      }
    </div>

    <div class="form-group">
      <div class="title">Registration date</div>
      @if((companyProfilePrevious.dateOfEstablishment | dateFormat) !== (companyProfile.dateOfEstablishment | dateFormat)) {
        <div class="property previous" appCopy>{{companyProfilePrevious.dateOfEstablishment | dateFormat}}</div>
        <div class="property current" appCopy>{{companyProfile.dateOfEstablishment | dateFormat}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.dateOfEstablishment | dateFormat}}</div>
      }
    </div>

    <div class="form-group">
      <div class="title">New annual review</div>
      @if((companyProfilePrevious.nextAnnualReviewDate | dateFormat) !== (companyProfile.nextAnnualReviewDate | dateFormat)) {
        <div class="property previous" appCopy>{{ companyProfilePrevious.nextAnnualReviewDate | dateFormat}}</div>
        <div class="property current" appCopy>{{companyProfile.nextAnnualReviewDate | dateFormat}}</div>
      } @else {
        <div class="property" appCopy>{{ companyProfilePrevious.nextAnnualReviewDate | dateFormat}}</div>
      }
    </div>
  </div>

  <div class="officeholder-info-container">
    <div class="form-group">
      <div class="title">Ultimate holding company</div>
      @if(companyProfilePrevious.ultimateHoldingCompany?.name !== companyProfile.ultimateHoldingCompany?.name) {
        <div class="property previous" appCopy>{{companyProfilePrevious.ultimateHoldingCompany?.name | defaultValue}}</div>
        <div class="property current" appCopy>{{companyProfile.ultimateHoldingCompany?.name | defaultValue }}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.ultimateHoldingCompany?.name | defaultValue}}</div>
      }
    </div>

    <div></div>

    <div class="form-group">
      <div class="title">Corporate key</div>
      @if(companyProfilePrevious.corporateKey !== companyProfile.corporateKey) {
        <div class="property previous" appCopy>{{companyProfilePrevious.corporateKey | defaultValue}}</div>
        <div class="property current" appCopy>{{companyProfile.corporateKey | defaultValue}}</div>
      } @else {
        <div class="property" appCopy>{{ companyProfilePrevious.corporateKey | defaultValue}}</div>
      }
    </div>

    <div class="form-group">
      <div class="title">Jurisdiction</div>
      @if(companyProfilePrevious.jurisdiction !== companyProfile.jurisdiction) {
        <div class="property previous" appCopy>{{companyProfilePrevious.jurisdiction | jurisdiction}}</div>
        <div class="property current" appCopy>{{companyProfile.jurisdiction | jurisdiction}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.jurisdiction | jurisdiction}}</div>
      }
    </div>
  </div>

  <div class="horizontal-divider"></div>

  <div class="officeholder-contact-container">
    <div class="form-group">
      <div class="title">Registered address</div>
      @if(companyProfilePrevious.registeredAddress.normalizedFullAddress !== companyProfile.registeredAddress.normalizedFullAddress) {
        <span class="property previous" appCopy>{{companyProfilePrevious.registeredAddress.normalizedFullAddress | defaultValue}}</span>
        <span class="property current" appCopy>{{companyProfile.registeredAddress.normalizedFullAddress | defaultValue}}</span>
      } @else {
        <span class="property" appCopy>{{companyProfilePrevious.registeredAddress.normalizedFullAddress | defaultValue}}</span>
      }
    </div>

    <div class="form-group">
      <div class="title">Principal address</div>
      @if(companyProfilePrevious.principalAddress.normalizedFullAddress !== companyProfile.principalAddress.normalizedFullAddress) {
        <span class="property previous" appCopy>{{companyProfilePrevious.principalAddress.normalizedFullAddress | defaultValue}}</span>
        <span class="property current" appCopy>{{companyProfile.principalAddress.normalizedFullAddress | defaultValue}}</span>
      } @else {
        <span class="property" appCopy>{{companyProfilePrevious.principalAddress.normalizedFullAddress | defaultValue}}</span>
      }
    </div>
  </div>

  <div class="horizontal-divider"></div>

  <div class="officeholder-contact-container">
    <div class="form-group">
      <div class="title">Partner</div>
      @if(companyProfilePrevious.partnerManager?.fullName !== companyProfile.partnerManager?.fullName) {
        <div class="property previous" appCopy>{{companyProfilePrevious.partnerManager?.fullName | defaultValue}}</div>
        <div class="property current" appCopy>{{companyProfile.partnerManager?.fullName | defaultValue}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.partnerManager?.fullName | defaultValue}}</div>
      }
    </div>

    <div class="form-group">
      <div class="title">Account Manager</div>
      @if(companyProfilePrevious.accountManager?.fullName !== companyProfile.accountManager?.fullName) {
        <div class="property previous" appCopy>{{companyProfilePrevious.accountManager?.fullName | defaultValue}}</div>
        <div class="property current" appCopy>{{companyProfile.accountManager?.fullName | defaultValue}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.accountManager?.fullName | defaultValue}}</div>
      }
    </div>
  </div>

  <div class="horizontal-divider"></div>

  <div class="officeholder-contact-container">
    <div class="form-group">
      <div class="title">Reference Number</div>
      @if(companyProfilePrevious.referenceNumber !== companyProfile.referenceNumber) {
        <div class="property previous" appCopy>{{companyProfilePrevious.referenceNumber | defaultValue}}</div>
        <div class="property current" appCopy>{{companyProfile.referenceNumber | defaultValue}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.referenceNumber | defaultValue}}</div>
      }
    </div>

    <div class="form-group">
      <div class="title">Family group</div>
      @if(companyProfilePrevious.familyGroup !== companyProfile.familyGroup) {
        <div class="property previous" appCopy>{{companyProfilePrevious.familyGroup | defaultValue}}</div>
        <div class="property current" appCopy>{{companyProfile.familyGroup | defaultValue}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.familyGroup | defaultValue}}</div>
      }
    </div>
  </div>

  <div class="horizontal-divider"></div>

  <div class="officeholder-contact-container">
    <div class="form-group">
      <div class="title">Signing Contact</div>
      @if(companyProfileDifference?.signingContact?.firstName || companyProfileDifference?.signingContact?.lastName) {
        <div class="property previous" appCopy>{{companyProfilePrevious.signingContact?.firstName}} {{companyProfilePrevious.signingContact?.lastName | defaultValue}}</div>
        <div class="property current" appCopy>{{companyProfile.signingContact?.firstName}} {{companyProfile.signingContact?.lastName | defaultValue}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.signingContact?.firstName}} {{companyProfilePrevious.signingContact?.lastName | defaultValue}}</div>
      }

      @if(companyProfilePrevious.signingContact?.email !== companyProfile.signingContact?.email) {
        <div class="contact form-group mb-1">
          <span class="contact-icon mr-1">&#64;</span>
          <span class="property previous" appCopy>{{companyProfilePrevious.signingContact?.email | defaultValue}}</span>
        </div>
        <div class="contact form-group mb-1">
          <span class="contact-icon mr-1">&#64;</span>
          <span class="property current" appCopy>{{companyProfile.signingContact?.email | defaultValue}}</span>
        </div>
      } @else {
        <div class="contact form-group mb-1">
          <span class="contact-icon mr-1">&#64;</span>
          <span class="property" appCopy>{{companyProfile.signingContact?.email | defaultValue}}</span>
        </div>
      }

      @if(companyProfilePrevious.signingContact?.phone !== companyProfile.signingContact?.phone) {
        <div class="contact form-group align-items-center">
          <i class="icon ic-xs ic-phone mr-1"></i>
          <span class="property previous" appCopy>{{companyProfilePrevious.signingContact?.phone | phone | defaultValue}}</span>
        </div>
        <div class="contact form-group align-items-center">
          <i class="icon ic-xs ic-phone mr-1"></i>
          <span class="property current" appCopy>{{companyProfile.signingContact?.phone | phone | defaultValue}}</span>
        </div>
      } @else {
        <div class="contact form-group align-items-center">
          <i class="icon ic-xs ic-phone mr-1"></i>
          <span class="property" appCopy>{{companyProfile.signingContact?.phone | phone | defaultValue}}</span>
        </div>
      }
    </div>

    <div class="form-group">
      <div class="title">Billing Contact</div>
      @if(companyProfileDifference?.billingContact?.firstName || companyProfileDifference?.billingContact?.lastName) {
        <div class="property previous" appCopy>{{companyProfilePrevious.billingContact?.firstName}} {{companyProfilePrevious.billingContact?.lastName | defaultValue}}</div>
        <div class="property current" appCopy>{{companyProfile.billingContact?.firstName}} {{companyProfile.billingContact?.lastName | defaultValue}}</div>
      } @else {
        <div class="property" appCopy>{{companyProfilePrevious.billingContact?.firstName}} {{companyProfilePrevious.billingContact?.lastName | defaultValue}}</div>
      }

      @if(companyProfilePrevious.billingContact?.email !== companyProfile.billingContact?.email) {
        <div class="contact form-group mb-1">
          <span class="contact-icon mr-1">&#64;</span>
          <span class="property previous" appCopy>{{companyProfilePrevious.billingContact?.email | defaultValue}}</span>
        </div>
        <div class="contact form-group mb-1">
          <span class="contact-icon mr-1">&#64;</span>
          <span class="property current" appCopy>{{companyProfile.billingContact?.email | defaultValue}}</span>
        </div>
      } @else {
        <div class="contact form-group mb-1">
          <span class="contact-icon mr-1">&#64;</span>
          <span class="property" appCopy>{{companyProfile.billingContact?.email | defaultValue}}</span>
        </div>
      }

      @if(companyProfilePrevious.billingContact?.phone !== companyProfile.billingContact?.phone) {
        <div class="contact form-group align-items-center">
          <i class="icon ic-xs ic-phone mr-1"></i>
          <span class="property previous" appCopy>{{companyProfilePrevious.billingContact?.phone | phone | defaultValue}}</span>
        </div>
        <div class="contact form-group align-items-center">
          <i class="icon ic-xs ic-phone mr-1"></i>
          <span class="property current" appCopy>{{companyProfile.billingContact?.phone | phone | defaultValue}}</span>
        </div>
      } @else {
        <div class="contact form-group align-items-center">
          <i class="icon ic-xs ic-phone mr-1"></i>
          <span class="property" appCopy>{{companyProfile.billingContact?.phone | phone | defaultValue}}</span>
        </div>
      }
    </div>
  </div>
</div>
