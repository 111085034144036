import { EntityType } from "../../enums/entityType";
import { ExternalSourceType } from "../../enums/externalEntityEnums";
import { AlertDetailBase } from "../alertDetailBase";

export class EntityMissingAlertDetail extends AlertDetailBase {
    static override readonly $type = "EntityMissingAlertDetail";
    externalEntityAcnOrAbn: string;
    externalEntityName: string;
    providerType: ExternalSourceType | null;
    entityType: EntityType | null;
    externalEntityId: string;

    constructor(data?: Partial<EntityMissingAlertDetail>){
        super(data);
        this.externalEntityAcnOrAbn = data?.externalEntityAcnOrAbn ?? '';
        this.providerType = data?.providerType ?? null;
        this.entityType = data?.entityType ?? null;
        this.externalEntityId = data?.externalEntityId ?? '';
        this.externalEntityName = data?.externalEntityName ?? '';
    }

}