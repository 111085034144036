import { EntityType } from "../../enums/entityType";
import { ExternalSourceType } from "../../enums/externalEntityEnums";
import { AlertDetailBase } from "../alertDetailBase";

export class EntityNameDifferenceAlertDetail extends AlertDetailBase {
    static override readonly $type = "EntityNameDifferenceAlertDetail";
    entityName: string;
    externalEntityName: string;
    providerType: ExternalSourceType | null;
    entityType: EntityType | null;
    externalEntityId: string;
    entityId: string;

    constructor(data?: Partial<EntityNameDifferenceAlertDetail>){
        super(data);
        this.entityName = data?.entityName ?? '';
        this.entityId = data?.entityId ?? '';
        this.providerType = data?.providerType ?? null;
        this.entityType = data?.entityType ?? null;
        this.externalEntityId = data?.externalEntityId ?? '';
        this.externalEntityName = data?.externalEntityName ?? '';
    }

}