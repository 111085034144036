import { Component, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { startWith } from 'rxjs';
import {
  DatePickerFormValidators,
  DatepickerHelper
} from '../../../../../../custom-form-validators/date-picker-form-validators';
import { Document } from '../../../../../../models/document';
import { CompanySubtype } from '../../../../../../models/enums/companyEnums';
import { SelectOption } from '../../../../../../models/selectOptions';
import { BaseStepperFormComponent } from '../../../../stepper-form/base-stepper-component/base-stepper-form.component';
import { CompanyChangeSpecialPurpose } from './company-status-change-form.model';
import { StepperFormComponent } from "../../../../stepper-form/stepper-form.component";
import {
  StepperFormDescriptionComponent
} from "../../../../stepper-form/stepper-form-description/stepper-form-description.component";
import { DatePickerComponent } from "../../../../../components/common/date-picker/date-picker.component";
import { SelectComponent } from "../../../../../components/common/select/select.component";
import { setControlDisabled } from "../../../../../../functions/set-control-disabled";

export enum SpecialPurposeChangeStepsEnum {
  FormDescription = 0,
  CompanyStatusChange = 1
}

export function getAvailableChangeTypesOptions(isProp: boolean): SelectOption[] {
  return isProp
    ? [{ label: 'Commence', value: 'Commence' }]
    : [{ label: 'Cease', value: 'Cease' }];
}

export function getAvailableChangeTypesOptionsForEdit(isProp: boolean): SelectOption[] {
  return isProp
    ? [{ label: 'Cease', value: 'Cease' }]
    : [{ label: 'Commence', value: 'Commence' }];
}

export const companyStatusChangeOptions: SelectOption[] = [
  { label: 'Home unit company', value: CompanySubtype.HUNT },
  { label: 'Superannuation trustee company', value: CompanySubtype.PSTC },
  { label: 'Not-for-profit Company (charitable purposes only)', value: CompanySubtype.PNPC },
];

@Component({
  selector: 'app-b3-company-status-change',
  standalone: true,
  templateUrl: './b3-company-status-change.component.html',
  styleUrl: '../../../../stepper-form/base-stepper-component/base-stepper-form.component.scss',
  imports: [
    ReactiveFormsModule,
    StepperFormComponent,
    StepperFormDescriptionComponent,
    DatePickerComponent,
    SelectComponent,
  ]
})
export class B3CompanyStatusChangeComponent extends BaseStepperFormComponent<SpecialPurposeChangeStepsEnum, CompanyChangeSpecialPurpose> implements OnInit {
  override readonly StepsEnum: typeof SpecialPurposeChangeStepsEnum = SpecialPurposeChangeStepsEnum;

  availableChangeTypesOptions: SelectOption[] = [];
  statusOptions = companyStatusChangeOptions;
  minDate!: NgbDateStruct;
  maxDate!: NgbDateStruct;
  isSpecialPurposeHidden = signal<boolean>(false);

  form = new FormGroup({
    changeIs: new FormControl<'Cease' | 'Commence' | null>(null, [Validators.required]),
    newCompanySubtype: new FormControl<CompanySubtype | null>(null, [Validators.required]),
    changeDate: new FormControl<Date | null>(null, [Validators.required, DatePickerFormValidators.dateFormatValidator]),
  });

  override stepperForm = new FormGroup({
    [SpecialPurposeChangeStepsEnum.FormDescription]: new FormGroup({}),
    [SpecialPurposeChangeStepsEnum.CompanyStatusChange]: this.form
  });

  constructor() {
    super();
    this.form.controls.changeDate.setValue(DatepickerHelper.getToday());
    this.setupSteps(SpecialPurposeChangeStepsEnum);
  }

  ngOnInit(): void {
    this.minDate = DatepickerHelper.getDateOfEstablishmentMinDate(this.companyChangeData.dateOfEstablishment);
    this.maxDate = DatepickerHelper.getNextNYearsStruct(10);
    this.availableChangeTypesOptions = getAvailableChangeTypesOptions(this.formModel.newCompanySubtype === CompanySubtype.PROP);
    this.listenNewCompanySubtypeChanges();
    this.setupChange();
  }

  override setupChange(change: CompanyChangeSpecialPurpose = this.formModel): void {
    if (!this.isEdit)
      return;

    this.availableChangeTypesOptions = getAvailableChangeTypesOptionsForEdit(change.newCompanySubtype === CompanySubtype.PROP);
    this.form.patchValue({
      ...this.formModel,
      changeIs: change.newCompanySubtype == CompanySubtype.PROP ? 'Cease' : 'Commence',
      newCompanySubtype: change.newCompanySubtype != CompanySubtype.PROP ? change.newCompanySubtype : null
    });
  }

  override buildDocument(): Document | null {
    const formValue = this.form.value;
    const changes = new CompanyChangeSpecialPurpose({
      changeDate: formValue.changeDate!,
      newCompanySubtype: (formValue.changeIs == 'Cease'
        ? CompanySubtype.PROP
        : formValue.newCompanySubtype) as unknown as CompanySubtype
    });

    try {
      return new Document({
        changes: [changes],
        entityId: this.companyChangeData.entityId,
        type: 'c:484',
        documentId: this.companyChangeData?.documentId,
      });
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  private listenNewCompanySubtypeChanges(): void {
    this.form.controls.changeIs.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), startWith(null))
      .subscribe((changeType) => {
        const isCeaseChangeType = changeType == 'Cease';
        this.isSpecialPurposeHidden.set(isCeaseChangeType || changeType === null);
        setControlDisabled(this.form.controls.newCompanySubtype, isCeaseChangeType);
      });
  }
}
