import { inject, Injectable } from '@angular/core';
import { Observable, of } from "rxjs";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { ReminderRecord } from "../models/reminderRecord";
import { AdHocDebtReminderBody } from "../models/adHocDebtReminderBody";
import { ReminderBody } from "../models/reminderBody";
import { AdHocSigningReminderBody } from "../models/adHocSigningReminderBody";
import { AdHocPaymentReminderBody } from "../models/adHocPaymentReminderBody";

@Injectable({
  providedIn: 'root'
})
export class RemindersService {
  private api = inject(HttpClient);

  public sendSigningAdHocReminder(body: AdHocSigningReminderBody): Observable<ReminderRecord> {
    return this.api.post<ReminderRecord>(`${ environment.api_url }/reminders/ah-hoc/signing`, body);
  }

  public sendPaymentAdHocReminder(body: AdHocPaymentReminderBody): Observable<ReminderRecord> {
    return this.api.post<ReminderRecord>(`${ environment.api_url }/reminders/ah-hoc/payment`, body);
  }

  public sendDebtAdHocReminder(body: AdHocDebtReminderBody): Observable<ReminderRecord> {
    return this.api.post<ReminderRecord>(`${ environment.api_url }/reminders/ah-hoc/debt`, body);
  }

  public addReminder(body: ReminderBody): Observable<ReminderRecord> {
    return this.api.post<ReminderRecord>(`${ environment.api_url }/reminders`, body);
  }

  public deleteReminder(reminderId: string): Observable<void> {
    return this.api.delete<void>(`${ environment.api_url }/reminders/${reminderId}`);
  }
}
