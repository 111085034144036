@if (isAuthenticated) {
  <app-nav-menu></app-nav-menu>
}
@if(!(isExpandedWideRoute$ | async) && isAuthenticated) {
  <app-header></app-header>
}
@if(isAuthenticated || (isGuestUser$ | async)) {
  <div class="content-container " [class]="((isExpandedWideRoute$ | async) ? 'document-page' : 'main-page')"
    [ngClass]="collapsedMenu() ? 'collapsed' : 'expanded'">
    <div [class]="((isExpandedWideRoute$ | async) ? 'content-document-wrapper' : 'content-wrapper')">
      <router-outlet></router-outlet>
    </div>
  </div>
}
@if (!isAuthenticated && !(isGuestUser$ | async)) {
<div
  class="d-flex justify-content-center align-items-center mh-400">
  <i class="fad fa-spinner-third fa-spin"></i>
</div>
}