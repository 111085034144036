import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { PageTitleComponent } from "../../../components/common/page-title/page-title.component";
import { ListGridComponent } from "../../../components/common/grid/components/list-grid/list-grid.component";
import { ColDef, GridApi } from "ag-grid-community";
import {
  AgReminderSendingMethodLabelComponent
} from "./components/ag-reminder-sending-method-label/ag-reminder-sending-method-label.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RemindersService } from "../../../../services/reminders.service";
import { catchError, EMPTY, finalize, tap } from "rxjs";
import { EditReminderModalComponent } from "./components/edit-reminder-modal/edit-reminder-modal.component";
import { ReminderSettingModel, ReminderSettingsData, ReminderSettingsModelExtended } from '../../../../models/reminderSettingsData';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AgReminderSettingsActionComponentComponent } from './components/ag-reminder-settings-action-component/ag-reminder-settings-action-component.component';
import { ReminderSettingTypeEnum } from '../../../../models/enums/reminderEnums';
import { AgReminderSettingsNameComponentComponent } from './components/ag-reminder-settings-name-component/ag-reminder-settings-name-component.component';
import { SettingsService } from '../../../../services/settings.service';
import { LoaderComponent } from "../../../components/common/loader/loader.component";


@Component({
  selector: 'app-reminders-page',
  standalone: true,
  providers: [RemindersService],
  imports: [
    PageTitleComponent,
    ListGridComponent,
    LoaderComponent
],
  templateUrl: './reminders-page.component.html',
  styleUrls: ['./reminders-page.component.scss', '../../settings-styles.scss']
})
export class RemindersPageComponent implements OnInit {
  private modalService = inject(NgbModal);
  private destroyRef = inject(DestroyRef);
  private settingsService = inject(SettingsService);

  isLoading = false;
  editRemindersModalOpened = false;
  reminders: ReminderSettingsModelExtended[] = [];
  colDefs: ColDef[] = [];
  gridApi!: GridApi;

  ngOnInit(): void {
    this.loadReminders();
    this.setupColDefs();
  }

  edit(reminder: ReminderSettingsModelExtended): void {
    const modalRef = this.modalService.open(EditReminderModalComponent, { size: 'sm', centered: true });
    const instance = modalRef.componentInstance as EditReminderModalComponent;

    instance.reminder = structuredClone(reminder);

    instance.confirm.pipe(
      tap((data: ReminderSettingsModelExtended) => {
        this.reminders = this.reminders.map((r) => {
          if (r.type === data.type) {
            return new ReminderSettingsModelExtended(data, data.type);
          }
          return r;
        });
        this.gridApi.refreshCells({force: true});
      }),
      takeUntilDestroyed(this.destroyRef),
      catchError(() => EMPTY),
    ).subscribe();
  }

  private loadReminders(): void {
    this.isLoading = true;
    this.settingsService.getRemiderSettings().pipe(
      tap((reminders: ReminderSettingsData) => this.reminders = [
        new ReminderSettingsModelExtended(reminders.annualStatementPaymentSetting, ReminderSettingTypeEnum.AnnualStatementPayment),
        new ReminderSettingsModelExtended(reminders.companyDebtSetting, ReminderSettingTypeEnum.CompanyDebt),
        new ReminderSettingsModelExtended(reminders.annualStatementSigningSetting, ReminderSettingTypeEnum.AnnualStatementSigning),
        new ReminderSettingsModelExtended(reminders.asicFormSigningSetting, ReminderSettingTypeEnum.AsicFormSigning)
      ] as ReminderSettingsModelExtended[]),
      takeUntilDestroyed(this.destroyRef),
      finalize(() => this.isLoading = false)
    ).subscribe();

  }
  onGridReady(gridApi: GridApi): void {
    this.gridApi = gridApi;
  }

  private setupColDefs(): void {
    this.colDefs = [
      {
        headerName: 'Reminders',
        field: 'type',
        cellRenderer: AgReminderSettingsNameComponentComponent,
        sort: 'asc',
        flex: 4,
      },
      {
        headerName: 'Method of Sending',
        field: 'sendingType',
        width: 230,
        minWidth: 100,
        cellRenderer: AgReminderSendingMethodLabelComponent,
        flex: 2,
      },
      {
        headerName: 'Date of sending before the due date',
        field: 'dates',
        valueGetter: (params) => {
          const reminderSetting = (params.data as ReminderSettingModel);
          return (reminderSetting?.scheduleDaysBeforeDeadline ?? []).map((day) => `${ day } days`);
        },
        flex: 3,
      },
      {
        headerName: 'Time',
        field: 'reminderTime',
        width: 100,
        minWidth: 100,
        flex: 2,
      },
      {
        cellRenderer: AgReminderSettingsActionComponentComponent,
        cellRendererParams: {
          edit: this.edit.bind(this)
        },
        sortable: false,
        width: 60,
        minWidth: 60,
        suppressHeaderMenuButton: true,
      }
    ];
  }
}
