import { Component, DestroyRef, inject } from '@angular/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from "@ng-bootstrap/ng-bootstrap";
import { ICellRendererParams } from "ag-grid-community";
import { Alert } from "../../../../../../../models/alerts/alert";
import { AlertStatus, AlertType } from "../../../../../../../models/enums/alertEnums";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AlertsService } from "../../../../../../../services/alert.service";
import { observe, Observer } from "fast-json-patch";
import { of, switchMap, tap } from "rxjs";
import { ButtonComponent } from "../../../../button/button.component";
import { AlertRecord } from '../../../../../../../models/alerts/alertRecord';
import { HasRoleDirective } from '../../../../../../../directives/has-role.directive';

type ActionEvent = (data: unknown) => void;

type CellRendererParams = ICellRendererParams<AlertRecord> & {
  openAlert: ActionEvent,
  markRead: ActionEvent,
  markRequireAttention: ActionEvent,
  archive: ActionEvent,
  ignore: ActionEvent,
  postpone: ActionEvent,
  download: ActionEvent,
};

@Component({
  selector: 'app-ag-alerts-actions',
  standalone: true,
  imports: [
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    ButtonComponent,
    HasRoleDirective
  ],
  templateUrl: './ag-alerts-actions.component.html',
  styleUrl: './ag-alerts-actions.component.scss'
})
export class AgAlertsActionsComponent {
  protected alertService = inject(AlertsService);
  protected destroyRef = inject(DestroyRef);

  protected readonly AlertType = AlertType;
  protected readonly AlertStatus = AlertStatus;

  observer!: Observer<Alert>;
  alertRecord: AlertRecord | undefined;

  showDownloadBtn = false;
  alertTypeListForDownload = [
    AlertType.ValidationReport,
    AlertType.DebtReports,
    AlertType.AsicInvoice,
    AlertType.ChangeOfNameCertificate,
    AlertType.CompanyStatement,
    AlertType.Others
  ];

  //   Only alerts with the following types can be ignored or postponed:
  // - XPM Sync Issues
  // - Identified External Changes
  // - ASIC Data Issues
  // - ASIC Connection Issues -- need to add later
  // - Failed Emails -- need to add later
  alertTypesForIgnoredOrPostponed = [
    AlertType.XpmSyncIssue,
    AlertType.IdentifiedExternalChanges,
    AlertType.AsicDataIssue
  ];
  allowChangeToIgnoredOrPostponed = false;
  type: AlertType | undefined;
  status: AlertStatus | undefined;
  isRead: boolean | undefined;
  needAttention: boolean | undefined;

  postponeMenu = [
    { label: 'One day', value: 1 },
    { label: 'One week', value: 7 },
    { label: 'Two weeks', value: 14 },
    { label: 'One month', value: 30 }
  ];

  protected params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
    if(params.data !== undefined) {
      this.type = params.data.type;
      this.allowChangeToIgnoredOrPostponed = this.alertTypesForIgnoredOrPostponed.some(type => type === this.type);
      this.status = params.data.status;
      this.isRead = params.data.isReaded;
      this.needAttention = params.data.needAttention;
      this.alertRecord = params.data;
      this.observer = observe(this.alertRecord);
    }

    if (this.type !== undefined) {
      this.showDownloadBtn = this.alertTypeListForDownload.includes(this.type);
    }
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    return true;
  }

  openAlert(): void {
    const data = { alertRecord: this.params.data, index: this.params.node.rowIndex };
    this.params.openAlert(data);
  }

  markRead(): void {
    if(this.params.data) {
      this.params.data.isReaded = true;
      const alertId = this.params.data.id;

      this.alertService.updateAlert(alertId, this.observer).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(alert => {
        if(alert.id) {
          this.updateSingleRow(alert);
          this.alertService.notifyReadStatusUpdated();
        }
      });
    }
  }

  markRequireAttention(): void {
    if(this.params.data) {
      this.params.data.needAttention = true;
      const alertId = this.params.data.id;

      this.alertService.updateAlert(alertId, this.observer).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(alert => {
        if(alert.id) {
          this.updateSingleRow(alert);
        }
      });
    }
  }

  archive(): void {
    this.updateAlertStatus(AlertStatus.Archived);
  }

  ignore(): void {
    this.updateAlertStatus(AlertStatus.Ignored);
  }

  postpone(numberOfDays: number): void {
    if (this.params.data) {
      const currentDate = new Date();
      const postponedDate = new Date();
      postponedDate.setDate(currentDate.getDate() + numberOfDays);
      this.params.data.postponedTill = postponedDate;
    }
    this.updateAlertStatus(AlertStatus.Postponed);
  }

  private updateAlertStatus(status: AlertStatus): void {
    if (this.params.data) {
      this.params.data.status = status;
      const alertId = this.params.data.id;

      this.alertService.updateAlert(alertId, this.observer).pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(alert => {
        if (alert.id) {
          this.updateSingleRow(alert);
        }
      });
    }
  }

  download(): void {
    if(this.params.data) {
      const alertId = this.params.data.id;

      this.alertService.getDownload(alertId).pipe(
        switchMap((res) => {
          if (res && this.params.data) {
            this.params.download(res);
            this.params.data.isReaded = true;
            const alertId = this.params.data.id;
            return this.alertService.updateAlert(alertId, this.observer).pipe(
              tap(alert => {
                if (alert.id) {
                  this.updateSingleRow(alert);
                }
              })
            );
          }

          return of('');
        }),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe();
    }
  }

  private updateSingleRow(data: Alert) {
    const rowNodeId = this.params.node.id;
    if (rowNodeId) {
      this.params.node.setData(data.toAlertRecord());
      this.params.api.refreshServerSide({ route: [rowNodeId] });
    }
  }
}
