<div class="card settings-page">
  <app-page-title
    header="Integrations"
    iconClass="ic-link"
    backButtonPath="settings"
  ></app-page-title>

  <ul class="list-unstyled connection-group-list">
    @for (connectionGroup of connectionGroups; track connectionGroup) {
      @if (connectionGroup.connections.length) {
        <li class="connection-group">
          <div class="connection-group-title">{{ connectionGroup.title }}</div>

          <ul class="list-unstyled connections-list">
            @for (connection of connectionGroup.connections; track connection) {
              <li class="connection-item">
                <div class="left">
                  <img class="connection-item-logo" [src]="connection.image" [alt]="connection.title + ' logo'">
                </div>

                <div class="right">
                  <div class="connection-item-header">
                    <div class="connection-item-title">{{ connection.title }}</div>
                    <div class="connection-item-connection-status {{getStatusClass(connection.connectionStatus)}}" >
                      {{getStatusText(connection.connectionStatus)}}
                    </div>
                  </div>

                  <div class="connection-item-description">
                    {{ connection.description }}
                  </div>

                  @if(!connection.isloading){
                    <div class="connection-item-controls" *appHasRole="['Admin', 'Manager']">
                      @if(connection.connectionStatus == ConnectionStatusEnum.NotConnected) { 
                          <app-button text="Connect" (clickBtn)="connect(connection.connectionType)" btnColor="white"/>
                      } @else {
                          <app-button text="Disconnect" [loading]="loadingBtn" (clickBtn)="disconnect(connection.connectionType)" btnColor="white"/>
                      }
                      
                      @for (button of connection.buttons; track $index) {
                        @if (button.displayStatus == connection.connectionStatus) {
                          <app-button [text]="button.btnTitle" (clickBtn)="button.action()" btnColor="white"/>
                        }
                      }
                    </div>
                  } @else {
                    <app-loader-standalone customClass="mh-100" [loading]="connection.isloading"></app-loader-standalone>
                  }

                </div>
              </li>
            }
          </ul>
        </li>
      }
    }
  </ul>
</div>
