import { Component, EventEmitter, inject, Input, OnChanges, Output, signal, SimpleChanges } from '@angular/core';
import { ButtonComponent } from "../../../components/common/button/button.component";
import { DocumentsService } from "../../../../services/documents.service";
import { TooltipComponent } from "../../../components/common/tooltip/tooltip.component";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { DocumentStepEnum } from "../../../../models/enums/documentStepEnum";
import { DocumentStatusEnum } from "../../../../models/enums/documentStatusEnum";
import { SigningStatus } from "../../../../models/enums/annualStatementEnums";
import { Document } from "../../../../models/document";
import { HasRoleDirective } from '../../../../directives/has-role.directive';

@Component({
  selector: 'app-generate-documents-header',
  standalone: true,
  imports: [
    ButtonComponent,
    TooltipComponent,
    NgbTooltip,
    HasRoleDirective
  ],
  templateUrl: './generate-documents-header.component.html',
  styleUrl: './generate-documents-header.component.scss'
})
export class GenerateDocumentsHeaderComponent implements OnChanges {
  @Input() loading = false;
  @Input() currentStepIndex: number | undefined;
  @Input() document: Document | undefined;
  @Input() notBeLodgedFormTypeList: string[] | undefined;
  @Output() saveAndCompleteLater = new EventEmitter<boolean>();
  @Output() confirmChanges = new EventEmitter<boolean>();
  @Output() signDocuments = new EventEmitter<boolean>();
  @Output() paperSignDocuments = new EventEmitter<boolean>();
  @Output() sendEmail = new EventEmitter<boolean>();
  @Output() lodgeDocumentNow = new EventEmitter<boolean>();
  @Output() confirmNotLodgedForm = new EventEmitter<boolean>();

  documentsService = inject(DocumentsService);
  confirmBtnName = 'Confirm the Changes';
  protected readonly DocumentStepEnum = DocumentStepEnum;
  protected readonly DocumentStatusEnum = DocumentStatusEnum;
  protected readonly SigningStatus = SigningStatus;

  saveLoading = this.documentsService.saveLoading;
  patchLoading = this.documentsService.patchLoading;
  sendSignLoading= this.documentsService.sendSignLoading;
  lodgeNowLoading = this.documentsService.lodgeNowLoading;
  disabledHeaderBtn = this.documentsService.disabledHeaderBtn;
  showVoidEnvelope = this.documentsService.showVoidEnvelope;
  showSendEmailBtn = this.documentsService.showSendEmailBtn;
  showSendPaperBtn = this.documentsService.showSendPaperBtn;

  showNotLodgedConfirmBtn = signal<boolean>(false);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentStepIndex'] || changes['showVoidEnvelope']) {
      if (this.currentStepIndex === DocumentStepEnum.GenerateDocuments) {
        this.confirmBtnName = 'Generate Documents';
      } else if (this.currentStepIndex === DocumentStepEnum.SignDocuments) {
        this.confirmBtnName = 'Sign Documents';
      } else {
        this.confirmBtnName = 'Confirm the Changes';
      }
    }

    if(changes['document'] && this.document?.type) {
      this.showNotLodgedConfirmBtn.set(!!this.notBeLodgedFormTypeList?.includes(this.document?.type ?? ''));
    }
  }

  save(): void {
    this.saveAndCompleteLater.emit(true);
  }

  confirm(): void {
    this.confirmChanges.emit(true);
  }

  confirmNotLodged(): void {
    this.confirmNotLodgedForm.emit(true);
  }

  sign(): void {
    this.signDocuments.emit(true);
  }

  paperSign(): void {
    this.paperSignDocuments.emit(true);
  }

  onSendEmail(): void {
    this.sendEmail.emit(true);
  }

  onLodgeDocumentNow(): void {
    this.lodgeDocumentNow.emit(true);
  }

  get displayLodgeNowBtn(): boolean {
    const signaturePending = this.document?.documentStatus === DocumentStatusEnum.SignaturePending;
    const readyToSend = this.document?.documentSigning?.signingStatus === SigningStatus.ReadyToSend;
    const send = this.document?.documentSigning?.signingStatus === SigningStatus.Sent;

    return !this.showNotLodgedConfirmBtn() && signaturePending && (readyToSend || send);
  }

  getButtonText(): string {
    const notLodgedConfirmText = this.showNotLodgedConfirmBtn() ? 'Confirm Changes' : 'Lodge';

    if (this.showSendEmailBtn()) {
      return 'Send email';
    } else if (this.showSendPaperBtn()) {
      const isSigned = this.document?.documentSigning?.signingStatus === SigningStatus.Signed;
      return isSigned ? notLodgedConfirmText : 'Confirm Signing';
    } else {
      return this.showVoidEnvelope() ? notLodgedConfirmText : 'Send for eSign';
    }
  }

  getButtonAction(): void {
    const notLodgedConfirmAction = this.showNotLodgedConfirmBtn() ? () => this.confirmNotLodged() : () => this.confirm();

    if (this.showSendEmailBtn()) {
      this.confirm();
    } else if (this.showSendPaperBtn()) {
      const isSigned = this.document?.documentSigning?.signingStatus === SigningStatus.Signed;
      isSigned ? notLodgedConfirmAction() : this.paperSign();
    } else {
      this.showVoidEnvelope() ? notLodgedConfirmAction() : this.sign();
    }
  }

  get lodgeNowBtnLoading(): boolean {
    return this.loading || this.patchLoading() || this.saveLoading() || this.lodgeNowLoading();
  }

  get signDocumentsBtnLoading(): boolean {
    if (this.showSendEmailBtn()) {
      return this.loading || this.patchLoading() || this.sendSignLoading();
    } else {
      return this.loading || this.patchLoading() || this.sendSignLoading() || this.lodgeNowLoading();
    }
  }

  get signDocumentsBtnDisabled(): boolean {
    return this.signDocumentsBtnLoading || this.disabledHeaderBtn();
  }
}
