import { ReminderSendingType, ReminderSettingTypeEnum } from "./enums/reminderEnums";

export class ReminderSettingsData{

  annualStatementPaymentSetting: ReminderSettingModel;
  companyDebtSetting: ReminderSettingModel;
  asicFormSigningSetting: ReminderSettingModel;
  annualStatementSigningSetting: ReminderSettingModel;

  constructor(data: Partial<ReminderSettingsData> = {}){
    this.annualStatementPaymentSetting = data.annualStatementPaymentSetting ?? new ReminderSettingModel();
    this.companyDebtSetting = data.companyDebtSetting ?? new ReminderSettingModel(); 
    this.asicFormSigningSetting = data.asicFormSigningSetting ?? new ReminderSettingModel(); 
    this.annualStatementSigningSetting = data.annualStatementSigningSetting ?? new ReminderSettingModel(); 
  }
}

export class ReminderSettingModel {
  sendAutomatically: boolean;
  sendingType: ReminderSendingType;
  scheduleDaysBeforeDeadline: number[];
  reminderTime: string | null;

  constructor(data: Partial<ReminderSettingModel> = {}) {
    this.sendAutomatically = data.sendAutomatically ?? false;
    this.sendingType = data.sendingType ?? ReminderSendingType.Email;
    this.scheduleDaysBeforeDeadline = data.scheduleDaysBeforeDeadline ?? [];
    this.reminderTime = data.reminderTime ?? null;
  }
}

export class ReminderSettingsModelExtended extends ReminderSettingModel{
  type!: ReminderSettingTypeEnum;

  constructor(reminderSetting: ReminderSettingModel, type: ReminderSettingTypeEnum) {
    super(reminderSetting)
    this.type = type;
  }
}


