import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef, GetContextMenuItems, GridApi, ValueFormatterParams } from 'ag-grid-community';
import { DocumentRecord } from '../../../models/documentRecord';
import {
  AgCompanyDocumentActionsComponent
} from '../common/grid/components/ag-company-document-actions/ag-company-document-actions.component';
import { AgDocumentStatusComponent } from '../common/grid/components/ag-document-status/ag-document-status.component';
import { ListGridComponent } from '../common/grid/components/list-grid/list-grid.component';
import {
  AgAsicDocumentStatusComponent
} from "../common/grid/components/ag-asic-document-status/ag-asic-document-status.component";
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-company-profile-documents',
  templateUrl: './company-profile-documents.component.html',
  styleUrl: './company-profile-documents.component.scss',
  imports: [ListGridComponent],
  standalone: true
})
export class CompanyProfileDocumentsComponent {
  router = inject(Router);

  @Input() pendingDocuments: DocumentRecord[] = [];

  @Output() openForm492 = new EventEmitter<DocumentRecord>();
  @Output() openForm902 = new EventEmitter<DocumentRecord>();
  @Output() deleteDocument = new EventEmitter<string>();
  @Output() onSelectDocuments = new EventEmitter<DocumentRecord[]>();

  readonly rowSelection: 'single' | 'multiple' = 'multiple';
  readonly defaultPageSize = 20;
  readonly colDefs: ColDef[] = [
    {
      headerName: 'Description',
      field: 'description',
      width: 300,
      flex: 2,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 165,
      minWidth: 165,
      cellRenderer: AgDocumentStatusComponent,
    },
    {
      headerName: 'Asic Status',
      field: 'asicStatus',
      minWidth: 90,
      flex: 1,
      cellRenderer: AgAsicDocumentStatusComponent
    },
    {
      headerName: 'Created',
      field: 'createdOn',
      width: 110,
      valueFormatter: (params: ValueFormatterParams<DocumentRecord, Date>) => {
        return formatDate(params.value ?? '', 'dd MMM yyyy', 'en-US');
      },
    },
    {
      field: '',
      width: 90,
      sortable: false,
      cellClass: 'actions-button-cell',
      cellRenderer: AgCompanyDocumentActionsComponent,
      cellRendererParams: {
        openDocument: this.openDocument.bind(this),
        openForm492: this.onOpenForm492.bind(this),
        openForm902: this.onOpenForm902.bind(this),
        deleteDocument: this.onDeleteDocument.bind(this),
      },
      suppressHeaderMenuButton: true,
    }
  ];

  getContextMenuItems(gridApi: GridApi & {defaultItems: unknown[]; node: {data: {documentId: string}}}): GetContextMenuItems {
      const items = gridApi.defaultItems.slice();
  
      return ([
        {
          name: 'Open Document Detail in New Tab',
          action: () => {
            const documentId = gridApi.node.data.documentId;
            const url = `/document/${documentId}`;
            window.open(url, '_blank');
          }
        },
        'separator',
        ...items,
      ]) as unknown as GetContextMenuItems
    }

  openDocument(documentRecord: DocumentRecord): void {
    this.router.navigate([`/document/${ documentRecord.documentId }`]).catch(err => console.error('Navigation error:', err));
  }

  onOpenForm492(documentRecord: DocumentRecord): void {
    this.openForm492.emit(documentRecord);
  }

  onOpenForm902(documentRecord: DocumentRecord): void {
    this.openForm902.emit(documentRecord);
  }

  onDeleteDocument(documentRecord: DocumentRecord): void {
    this.deleteDocument.emit(documentRecord.documentId);
  }

  selectDocuments(documents: DocumentRecord[]): void {
    this.onSelectDocuments.emit(documents);
  }
}
