import { Component, EventEmitter, inject, Input, Output, signal } from '@angular/core';
import { EmailTemplatesService } from "../../../../services/email-templates.service";
import { ToastrService } from "ngx-toastr";
import { CommonOffcanvasComponent } from "../../../components/common/common-offcanvas/common-offcanvas.component";
import { DatePipe } from "@angular/common";
import {
  AgOutgoingEmailMessageStatusComponent
} from "../ag-outgoing-message-status/ag-outgoing-email-message-status.component";
import { EmailRecipientsComponent } from "../ag-email-recipients/email-recipients.component";
import { ShadowDomViewerComponent } from "../../../components/common/shadow-dom-viewer/shadow-dom-viewer.component";
import { DisclaimerComponent } from "../../../components/common/disclaimer/disclaimer.component";
import { finalize } from "rxjs";
import { LoaderStandaloneComponent } from "../../../components/common/loader-standalone/loader-standalone.component";
import { EmailRecord } from "../../../../models/email-templates/emailRecord";
import { EmailModel } from "../../../../models/email-templates/emailModel";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ButtonComponent } from "../../../components/common/button/button.component";
import {
  AgActionIconButtonComponent
} from "../../../components/common/grid/components/ag-action-icon-button/ag-action-icon-button.component";
import { EmailStatus } from '../../../../models/enums/emailStatus';
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { EmailTemplatesHelper } from "../../../../models/email-templates/emailTemplatesHelper";
import { HasRoleDirective } from '../../../../directives/has-role.directive';

@Component({
  selector: 'app-email-details',
  standalone: true,
  imports: [
    CommonOffcanvasComponent,
    AgActionIconButtonComponent,
    LoaderStandaloneComponent,
    DisclaimerComponent,
    DatePipe,
    AgOutgoingEmailMessageStatusComponent,
    EmailRecipientsComponent,
    ShadowDomViewerComponent,
    ButtonComponent,
    NgxSkeletonLoaderModule,
    HasRoleDirective
  ],
  templateUrl: 'outgoing-email-message-details.component.html',
  styleUrls: [
    'outgoing-email-message-details.component.scss',
    '/src/app/components/email-preview/email-preview.component.scss'
  ]
})
export class OutgoingEmailMessageDetailsComponent extends CommonOffcanvasComponent {
  private toastr = inject(ToastrService);
  private emailTemplatesService = inject(EmailTemplatesService);
  private sanitizer = inject(DomSanitizer);

  @Input({ alias: 'emailRecord', required: true }) set emailRecordSetter(record: EmailRecord | null) {
    this.emailRecord = record;
    this.emailModel = null;
    this.htmlContent.set(null);

    if (record) {
      this.loadEmailModel(record);
    }
  }

  @Input() totalRows = 0;
  @Input() currentEmailMessageIndex = 0;
  @Input() currentEmailMessageItemNumber = 0;
  @Input() isOuterLoading = false;

  @Output() getNextEmailMessage = new EventEmitter<void>();
  @Output() getPreviousEmailMessage = new EventEmitter<void>();
  @Output() refreshData = new EventEmitter<void>();

  readonly customStyle = `.container {margin: 0 !important;}`;
  readonly EmailStatus = EmailStatus;
  htmlContent = signal<SafeHtml | null>(null);
  emailRecord: EmailRecord | null = null;
  emailModel: EmailModel | null = null;
  isEmailModelLoading = false;
  isMessageActionLoading = false;
  resentEmailDate: Date | null = null;

  openNextEmail(): void {
    this.getNextEmailMessage.emit();
  }

  openPreviousEmail(): void {
    this.getPreviousEmailMessage.emit();
  }

  deleteEmail(): void {
    // available only for admins
    if (!this.emailRecord || !confirm('Deleting the email will not cancel pending eSigning envelopes or recall sent emails. To cancel an eSigning request, you must void the envelope. Do you want to continue?')) return;
    this.isMessageActionLoading = true;
    this.emailTemplatesService.deleteOutgoingEmailMessage(this.emailRecord.emailMessageId)
      .pipe(finalize(() => this.isMessageActionLoading = false))
      .subscribe({
        next: () => {
          this.refreshData.emit();
          this.closeOffcanvas();
        },
        error: () => this.toastr.error('Something happened while deleting email', 'Error')
      });
  }

  resendEmail(): void {
    if (this.emailRecord?.status !== EmailStatus.Failed || !this.emailModel) return;
    this.isMessageActionLoading = true;

    this.emailTemplatesService.resendOutgoingEmailMessage(this.emailRecord.emailMessageId)
      .pipe(finalize(() => this.isMessageActionLoading = false))
      .subscribe({
        next: () => {
          this.refreshData.emit();
          this.resentEmailDate = new Date();
        },
        error: () => this.toastr.error('Something happened while sending email', 'Error')
      });
  }

  private loadEmailModel(record: EmailRecord): void {
    this.isEmailModelLoading = true;

    this.emailTemplatesService.getEmailDetails(record.emailMessageId)
      .pipe(finalize(() => this.isEmailModelLoading = false))
      .subscribe({
        next: (emailMessageModel) => {
          if (emailMessageModel.emailMessageId !== this.emailRecord?.emailMessageId) return;
          this.emailModel = emailMessageModel;
          this.htmlContent.set(emailMessageModel.html ? this.sanitizer.bypassSecurityTrustHtml(EmailTemplatesHelper.replaceNewLine(emailMessageModel.html)) : '');
        },
        error: () => this.toastr.error('Something happened while loading email', 'Error')
      });
  }
}
