<div class="redirect-container">
    @if(logoUrl){
        <div class="logo-container">
            <img [src]="logoUrl" alt="Large Logo" class="large-logo" />
        </div>
    } @else {
        <div class="loading-placeholder">
            <p>Loading logo...</p>
        </div>
    }
      
    <h1>{{message}}</h1>
    <p>Thank you for signing</p>
  
    @if(isAuthorized)
    {
        <a [routerLink]="documentPath" class="btn btn-secondary">
            Go to document
        </a>
    }
</div>