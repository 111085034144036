<div #profileCard class="card card-profile" [ngClass]="{'highlight-border': !loading && highlightCurrentChanges}">
  <div class="pending-container">
    <div class="header">
      <div class="d-flex gap-12">
        <i class="icon ic-clock"></i>
        <h3>Display Changes</h3>
      </div>

      <div class="header-pending">
        <div class="toggle-container">
          <app-toggle-button
            [switched]="pendingButtonState"
            [disabled]="loading || disableControls"
            (switchBtn)="onSwitchButton($event)"
          ></app-toggle-button>

          <div class="toggle-text">Show Display Changes</div>
        </div>

        @if(!trustView) {
          <div class="vertical-divider"></div>

          <div class="header-generate-docs">
            <app-button
              [btnColor]="isOneMoreChecked ? 'orange' : 'white'"
              [iconClass]="'icon ic-generate-documents'"
              [text]="'Generate Documents'"
              [isDisabled]="isAllNotChecked || disableControls"
              (clickBtn)="generateDocuments()"
            ></app-button>
          </div>
        }
      </div>
    </div>

    @if(!trustView) {
      <app-divider></app-divider>

      <div class="changes-container">
        @if(!loading) {
          @for(document of pendingDocuments; let first = $first; track $index) {
            <div class="changes-row">
              <div class="changes-info-container">
                <div class="icon-container">
                  @if (document.status === DocumentStatusEnum.Draft) {
                    <i class="icon ic-form"></i>
                  }
                  @if (document.status === DocumentStatusEnum.AuthorisationPending && document.type.includes('484')) {
                    <app-checkbox
                      [size]="'small'"
                      [value]="document.checked"
                      [disabled]="disableControls"
                      (change)="document.checked = $event"
                    ></app-checkbox>
                  }
                </div>
                <div class="changes-info">
                  <div class="name-container">
                    <app-tooltip [customContent]="true">
                      <div
                        class="action-name"
                        container="body"
                        [ngbTooltip]="document.description"
                        [tooltipClass]="'tooltip-info'"
                        (click)="checkRolesAndOpen(document)"
                      >
                        {{ document.description }}
                      </div>
                    </app-tooltip>
                    <div class="form-name">{{ document.type | documentType }}</div>
                  </div>
                  <div class="btn-trash" *appHasRole="['Admin', 'Manager', 'Preparer']">
                    <app-button
                      [iconClass]="'icon ic-trash'"
                      [btnColor]="'white'"
                      (clickBtn)="deleteChanges(document.documentId)"
                      [isDisabled]="disableControls || isAllowToDeleteDocument(document)"
                    ></app-button>
                  </div>
                </div>
              </div>

              <div class="status-container">
                <div class="icon-container"></div>
                <div class="status-wrapper">
                  <div
                    class="status"
                    [ngClass]="DocumentStatusEnum[document.status!] | lowercase"
                  >
                    {{ document.status | documentStatus }}
                  </div>
                </div>
                <div class="btn-trash">
                  <app-button
                    [iconClass]="'icon ic-trash'"
                    [btnColor]="'white'"
                    (clickBtn)="deleteChanges(document.documentId)"
                    [isDisabled]="disableControls"
                  ></app-button>
                </div>
              </div>
            </div>
          } @empty {
            <div class="no-data">No data</div>
          }
        }

        <app-loader-standalone [loading]="loading"></app-loader-standalone>
      </div>

      @if (!loading && pendingDocuments.length) {
        <div class="generate-docs" *appHasRole="['Admin', 'Manager', 'Preparer']">
          <app-button
            [btnColor]="isOneMoreChecked || isFirst484FormSelected ? 'orange' : 'white'"
            [iconClass]="'icon ic-generate-documents'"
            [text]="'Generate Documents'"
            [isDisabled]="isAllNotChecked || disableControls"
            (clickBtn)="generateDocuments()"
          ></app-button>
        </div>
      }
    }
  </div>
</div>

