import { AlertDetailBase } from "../alertDetailBase";

export class AnnualStatementAlertDetail extends AlertDetailBase {
    static override readonly $type = "EntityMissingAlertDetail";
    externalName: string;
    acn!: string;

    constructor(data?: Partial<AnnualStatementAlertDetail>){
        super(data);
        this.externalName = data?.externalName ?? '';
        this.acn = data?.acn ?? '';
    }

}